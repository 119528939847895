import { SnPSaleCreateRequest, SnPSaleEditRequest } from '../server-types';
import { CreateSaleForm } from '../state/createSale';
import { EditSnPSaleForm } from '../state/openSale';
import { mapToDropdown, mapToDate, mapToVesselFinder } from './form-elements';

export const convertDbSaleToEditSaleForm = (input: any): EditSnPSaleForm => {
  return {
    id: input.id,
    vesselDetails: mapToVesselFinder(input.vessel_name, input.imo),
    imo: input.imo,
    effectiveDate: mapToDate(input.effective_date),
    deliveryDate: mapToDate(input.delivery_date),
    comments: input.comments,
    source: input.source,
    salesType: mapToDropdown(input.sales_type),
    priceUSD: input.price_usd,
    priceLocal: input.price_local / 1000000,
    currency: mapToDropdown(input.currency),
    askingPrice: input.asking_price,
    askingPriceCurrency: mapToDropdown(input.asking_price_curr),
    buyer: mapToDropdown(input.buyer),
    seller: mapToDropdown(input.seller),
    owner: mapToDropdown(input.owner),
    privateAndConfidential: input.p_and_c,
    FOSASSales: input.fosas_sales,
    updated: input.updated,
    updatedBy: input.updated_by,
  };
};

export const convertToSaleEditPayload = (
  input: EditSnPSaleForm
): SnPSaleEditRequest => {
  const responseObj: SnPSaleEditRequest = {
    id: '',
    imo: '',
    vessel_name: '',
    sales_type: '',
    delivery_date: undefined,
    effective_date: undefined,
    price_usd: '',
    price_local: '',
    asking_price: '',
    asking_price_curr: '',
    currency: '',
    p_and_c: '',
    fosas_sales: '',
    source: '',
    buyer: '',
    seller: '',
    owner: '',
    comments: '',
  };

  // Map inputs to the create request
  if (input.vesselDetails) {
    responseObj.imo = input.vesselDetails.value.imo;
    responseObj.vessel_name = input.vesselDetails.value.vessel_name;
  }

  if (input.id) responseObj.id = input.id;
  if (input.salesType) responseObj.sales_type = input.salesType.value;
  if (input.deliveryDate) responseObj.delivery_date = input.deliveryDate;
  if (input.effectiveDate) responseObj.effective_date = input.effectiveDate;
  if (input.priceUSD) responseObj.price_usd = input.priceUSD;
  if (input.priceLocal)
    responseObj.price_local = Number(input.priceLocal) * 1000000;
  if (input.askingPrice) responseObj.asking_price = input.askingPrice;
  if (input.askingPriceCurrency)
    responseObj.asking_price_curr = input.askingPriceCurrency.value;
  if (input.currency) responseObj.currency = input.currency.value;
  if (input.privateAndConfidential)
    responseObj.p_and_c = input.privateAndConfidential;
  if (input.FOSASSales) responseObj.fosas_sales = input.FOSASSales;
  if (input.source) responseObj.source = input.source;
  if (input.buyer) responseObj.buyer = input.buyer.value;
  if (input.seller) responseObj.seller = input.seller.value;
  if (input.comments) responseObj.comments = input.comments;
  if (input.owner) responseObj.owner = input.owner.value;

  return responseObj;
};

export const convertToSaleCreatePayload = (
  input: CreateSaleForm
): SnPSaleCreateRequest => {
  const responseObj: SnPSaleCreateRequest = {
    imo: '',
    vessel_name: '',
    sales_type: '',
    delivery_date: undefined,
    effective_date: undefined,
    price_usd: '',
    price_local: '',
    currency: '',
    asking_price: '',
    asking_price_curr: '',
    p_and_c: '',
    fosas_sales: '',
    source: '',
    buyer: '',
    seller: '',
    owner: '',
    comments: '',
    new_vessel_name: '',
    new_vessel_owner: '',
  };

  // Map inputs to the create request
  if (input.vesselDetails) {
    responseObj.imo = input.vesselDetails.value.imo;
    responseObj.vessel_name = input.vesselDetails.value.vessel_name;
  }

  if (input.salesType) responseObj.sales_type = input.salesType.value;
  if (input.deliveryDate) responseObj.delivery_date = input.deliveryDate;
  if (input.effectiveDate) responseObj.effective_date = input.effectiveDate;
  if (input.priceUSD) responseObj.price_usd = input.priceUSD;
  if (input.priceLocal)
    responseObj.price_local = Number(input.priceLocal) * 1000000;
  if (input.currency) responseObj.currency = input.currency.value;
  if (input.askingPrice) responseObj.asking_price = input.askingPrice;
  if (input.askingPriceCurrency)
    responseObj.asking_price_curr = input.askingPriceCurrency.value;
  if (input.privateAndConfidential)
    responseObj.p_and_c = input.privateAndConfidential;
  if (input.FOSASSales) responseObj.fosas_sales = input.FOSASSales;
  if (input.source) responseObj.source = input.source;
  if (input.buyer) responseObj.buyer = input.buyer.value;
  if (input.seller) responseObj.seller = input.seller.value;
  if (input.owner) responseObj.owner = input.owner.value;
  if (input.comments) responseObj.comments = input.comments;
  if (input.newVesselName) responseObj.new_vessel_name = input.newVesselName;
  if (input.newVesselOwner) responseObj.new_vessel_owner = input.newVesselOwner;

  return responseObj;
};
