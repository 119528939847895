import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NumericFormat } from 'react-number-format';
import { activeModalType, ModalType } from '../../state/modals';
import { saleGlobalId } from '../../state/openSale';
import { getLatestSalesByImo } from '../../services/fosService';
import { FOSTable } from '../../common/table/FOSTable';
import { FOSTableDateCell } from '../../common/table/FOSTableDateCell';
import { FOSTableTooltipCell } from '../../common/table/FOSTableTooltipCell';
import { FOSTableYesNoCell } from '../../common/table/FOSTableYesNoCell';
import { vesselLookupState, EditVesselForm } from '../../state/openVessel';

interface Props {
  setFormStateElement(field: string, value: any): void;
  form: EditVesselForm | undefined;
}

export const VesselLatestSales = (props: Props) => {
  const { form, setFormStateElement } = props;

  const [sales, setSales] = useState<any>([]);

  const dbVessel = useRecoilValue(vesselLookupState);
  const setSaleGlobalId = useSetRecoilState(saleGlobalId);
  const setModalType = useSetRecoilState(activeModalType);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'effective_date', desc: true });

  const openSale = (saleGlobalId: string) => {
    setSaleGlobalId(saleGlobalId);
    setModalType(ModalType.OpenRecentSale);
  };

  useEffect(() => {
    if (form === undefined || dbVessel === undefined) return;

    getLatestSalesByImo(dbVessel.imo, page, perPage, sortBy).then(
      (res) => {
        setSales(res.data);
        setTotalPages(res.pagination.lastPage);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, [form, page, perPage, sortBy]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Effective Date',
        accessor: 'effective_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
      },
      {
        Header: 'Seller',
        accessor: 'seller',
      },
      {
        Header: 'Buyer',
        accessor: 'buyer',
      },
      {
        Header: 'Price USD',
        accessor: 'price_usd',
        disableSortBy: true,
        Cell: ({ value }: any) => (
          <span>
            <NumericFormat
              value={value}
              displayType={'text'}
              prefix={'$'}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Type',
        accessor: 'sales_type',
      },
      {
        Header: 'PnC',
        accessor: 'p_and_c',
        Cell: (cell: any) => (
          <FOSTableYesNoCell value={cell.value}></FOSTableYesNoCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openSale(original.row.original.id);
                console.log('opening', original.row.original.id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <FOSTable
      columns={columns}
      data={sales}
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={page}
      perPage={perPage}
      totalPage={totalPages}
      initialSortBy={sortBy}
      onChangeSort={setSortBy}
    ></FOSTable>
  );
};
