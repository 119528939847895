import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { SetterOrUpdater, useRecoilState, useResetRecoilState } from 'recoil';
import FOSBSDatePicker from '../../common/FOSBSDatePicker';
import {
  FormPart,
  GeneralForm,
  valuationSearchGeneralForm,
  MultiSelectOption,
  MultiSelectOptions,
  valuationSearchResults,
} from '../../state/searchValuation';
import { MainThemeBootstrap } from '../../Theme/MainThemeBootstrap';
import { useEffect, useRef } from 'react';
import FOSBSTextInput from '../../common/FOSBSTextInput';
import FOSBSMultiSelect from '../../common/FOSBSMultiSelect';
import FOSBSMultiSelectSort from '../../common/FOSBSMultiSelectSort';
import { getAPIPath } from '../../core';
import FOSBSTriggerSelect from '../../common/FOSBSTriggerSelect';
import { convertToValuationSearchPayload } from '../../mapper/valuation-search';
import { Collapse } from 'react-collapse';
import FOSBSRange from '../../common/FOSBSRange';
import { SearchColumns } from '../search-columns';
import { SearchResults } from '../SearchResults';
import {
  searchValuations,
  updateSearchColumns,
  getSearchColumns,
} from '../../services/fosService';
import { userState } from '../../state/state';
import BarLoader from 'react-spinners/BarLoader';
import { union } from '../../util';

const allColumns = SearchColumns().Valuation.map((el) => el.accessor);
const defaultColumns = SearchColumns()
  .Valuation.filter((el) => el.default)
  .map((el) => el.accessor);

const mapToMultiSelect = (rawArr: string[]): MultiSelectOptions => {
  return rawArr.map((el) => {
    return { label: el, value: el };
  });
};

const NewSearch = (props: any) => {
  const { cookieKey } = props;
  const [cookies, setCookie] = useCookies([cookieKey]);

  // user params
  const [userStateVal, setUserStateVal] = useRecoilState(userState);

  // paging params
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'vessel_name', desc: false });

  // search params
  const [combined, setCombined] = useState(false);
  const [searched, setSearched] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  // data lists
  const [valuationPossibleColumns, setValuationPossibleColumns] =
    useState<MultiSelectOptions>([]);
  const [definedColumns, setDefinedColumns] = useState<MultiSelectOptions>([]);
  const [vesselSubTypes, setVesselSubTypes] = useState<MultiSelectOptions>([]);
  const [design, setDesign] = useState<MultiSelectOptions>([]);

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [removedRows, setRemovedRows] = useState<string[]>([]);
  const [searchRequests, setSearchRequests] = useState<any[]>([{}]);

  // form states
  const [generalFormState, setGeneralFormState] = useRecoilState(
    valuationSearchGeneralForm
  );

  // search result states
  const [searchResults, setSearchResults] = useRecoilState(
    valuationSearchResults
  );

  // form resets
  const resetGeneral = useResetRecoilState(valuationSearchGeneralForm);

  // form state refs
  const latestGeneralFormState = useRef(generalFormState);

  // subscribe for state updates (needed due to use of memoized components)
  useEffect(() => {
    latestGeneralFormState.current = generalFormState;
  }, [generalFormState]);

  useEffect(() => {
    const loadData = async () => {
      const res = await fetch(getAPIPath() + `/api/meta/valuation/general`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {},
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (res.status !== 200) {
        // TODO: Handle load error
      }

      const generalMetaInfo = await res.json();

      setValuationPossibleColumns(mapToMultiSelect(allColumns));
      setVesselSubTypes(mapToMultiSelect(generalMetaInfo.vesselTypes));
      setDesign(mapToMultiSelect(generalMetaInfo.design));

      const searchColumnsFromDB = await getSearchColumns(
        userStateVal.userId,
        cookieKey
      );
      const searchColumnsFromCookies = cookies[cookieKey];

      if (!searchColumnsFromDB && !searchColumnsFromCookies) {
        setDefinedColumns(mapToMultiSelect(defaultColumns));
        setCookie(cookieKey, defaultColumns);
      } else if (
        Array.isArray(searchColumnsFromDB) &&
        searchColumnsFromDB.length > 0
      ) {
        setDefinedColumns(mapToMultiSelect(searchColumnsFromDB));
      } else {
        setDefinedColumns(mapToMultiSelect(searchColumnsFromCookies));
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (searched) {
      doSearch();
    }
  }, [page, perPage, sortBy]);

  // refresh table each time user removes row
  useEffect(() => {
    if (removedRows?.length) {
      doSearch();
    }
  }, [removedRows]);

  const filteredColumns = definedColumns.map((accessor: MultiSelectOption) =>
    SearchColumns().Valuation.find(
      (column) => column.accessor === accessor.value
    )
  );

  const getPayload = () => {
    const displayFields = definedColumns.map((columns) => columns.value);

    return {
      requests: searchRequests,
      displayFields,
      removedRows,
    };
  };

  const getCurrentPayload = () => {
    // Convert to payolad
    const payload = convertToValuationSearchPayload({
      general: generalFormState,
    });

    return payload;
  };

  const removeRows = (rows: string[]) => {
    if (rows) {
      setRemovedRows(union(removedRows || [], rows));
    }
  };

  const doSearch = async () => {
    setLoading(true);
    const finalRequestBody = getCurrentPayload();

    searchRequests[searchRequests.length - 1] = finalRequestBody;
    setSearchRequests(searchRequests);

    const displayFields = definedColumns.map((columns) => columns.value);
    const searchResponse = await searchValuations(
      searchRequests,
      displayFields,
      removedRows,
      page,
      perPage,
      sortBy
    );

    const valuationsMapped = searchResponse.valuations.map((el: any) => {
      return {
        id: `${el.imo}-${el.effective_date}`,
        ...el,
      };
    });

    setSearchResults(valuationsMapped);

    setLoading(false);
    setSearched(true);
    setTotalRecords(searchResponse.pagination.total);
    setTotalPages(searchResponse.pagination.lastPage);

    await updateSearchColumns(
      userStateVal.userId,
      cookieKey,
      definedColumns.map((columns) => columns.value)
    );
  };

  const doReset = () => {
    resetGeneral();
    setRemovedRows([]);
    setSearchRequests([{}]);
  };

  const setFormStateElement = (
    formPart: FormPart,
    field: string,
    value: any
  ) => {
    // fetch setter method and current (latest) version of the data.
    const getPrevValueAndSetter = (
      fp: FormPart
    ): {
      existingValue: GeneralForm; // | PositionsForm | ExtrasForm | UnderDeckForm | OtherForm,
      setter: SetterOrUpdater<any>;
    } => {
      switch (fp) {
        default:
        case FormPart.General:
          return {
            existingValue: latestGeneralFormState.current,
            setter: setGeneralFormState,
          };
      }
    };

    // fetch prev val and setter based on existing values
    const { existingValue, setter } = getPrevValueAndSetter(formPart);

    // call setter func and update its vals
    setter({
      ...existingValue,
      [field]: value,
    });
  };

  return (
    <MainThemeBootstrap>
      <div className='container-fluid'>
        <p className='pt-3'>
          <a
            className='btn btn-danger btn-sm'
            href='#collapseExample'
            role='button'
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            Toggle search
          </a>
          <a
            style={{ marginLeft: '20px' }}
            className='btn btn-outline-primary btn-sm'
            href='#collapseExample'
            role='button'
            onClick={() => {
              setSearchRequests([...searchRequests, {}]);
              setCollapsed(false);
              setCombined(true);
            }}
          >
            Add new search
          </a>
          {combined && (
            <a
              style={{ marginLeft: '20px' }}
              className='btn btn-outline-danger btn-sm'
              href='#'
              role='button'
              onClick={() => {
                setCombined(false);
                doReset();
              }}
            >
              Reset
            </a>
          )}
        </p>

        <Collapse isOpened={!collapsed}>
          <div className='row'>
            <div className='col-md-6'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Valuation Info
                </h5>
              </div>

              <FOSBSTextInput
                label={'Client'}
                value={generalFormState.client}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'client',
                    e.target.value
                  );
                }}
              />

              <div className='row align-items-center'>
                <label className='col-sm-4 col-form-label'>
                  Effective Date
                </label>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'From'}
                    value={generalFormState.effectiveDateFrom}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    name={'effectiveDateFrom'}
                  />
                </div>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'To'}
                    value={generalFormState.effectiveDateTo}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    name={'effectiveDateTo'}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  General Info
                </h5>
              </div>

              <FOSBSTextInput
                label={'Imo Number'}
                value={generalFormState.imoNumber}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'imoNumber',
                    e.target.value
                  );
                }}
              />
              <FOSBSTextInput
                label={'Vessel Name'}
                value={generalFormState.vesselName}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'vesselName',
                    e.target.value
                  );
                }}
              />
              <FOSBSTextInput
                label={'Owner'}
                value={generalFormState.groupOwner}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'groupOwner',
                    e.target.value
                  );
                }}
              />

              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Vessel Type
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='vesselSubType'
                    value={generalFormState.vesselSubType}
                    options={vesselSubTypes}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>

              <div className='row align-items-center'>
                <label className='col-sm-4 col-form-label'>Built</label>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'From'}
                    value={generalFormState.builtFrom}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    name={'builtFrom'}
                  />
                </div>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'To'}
                    value={generalFormState.builtTo}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    name={'builtTo'}
                  />
                </div>
              </div>

              <FOSBSRange
                label='DWT'
                name='DWT'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.DWT}
              />
              <FOSBSRange
                label='BHP'
                name='BHP'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.BHP}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Other
                </h5>
              </div>

              <div className='row'>
                <label
                  htmlFor='valuationSearchColumns'
                  className='col-sm-4 col-form-label'
                >
                  Displayed Columns
                </label>
                <div className='col'>
                  <FOSBSMultiSelectSort
                    name='valuationSearchColumns'
                    value={definedColumns}
                    options={valuationPossibleColumns}
                    stateSetter={(name: string, value: MultiSelectOptions) => {
                      setDefinedColumns(value);
                      setCookie(
                        cookieKey,
                        value.map((v) => v.value)
                      );
                    }}
                  />
                </div>
              </div>

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'Dive'}
                options={[
                  { label: 'Sat. Dive', value: 'Sat. Dive' },
                  { label: 'No', value: 'No' },
                  { label: 'Yes', value: 'Yes' },
                  { label: 'Air Dive', value: 'Air Dive' },
                ]}
                name={'dive'}
                value={generalFormState.dive}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
              />
            </div>
          </div>

          <div className='row'>
            <div
              className='col-md-12'
              style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
            >
              <button
                className='btn btn-success'
                style={{ marginRight: 5 }}
                onClick={() => {
                  doSearch();
                  setCollapsed(true);
                }}
              >
                Search
              </button>
              <button
                className='btn btn-secondary'
                style={{ marginRight: 5 }}
                onClick={doReset}
              >
                Reset
              </button>
            </div>
          </div>
        </Collapse>
      </div>

      <div className='container-fluid'>
        <div className='row pt-3 ' style={{ minHeight: '400px' }}>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <BarLoader
              loading={loading}
              height={4}
              width={200}
              cssOverride={{ display: 'block', margin: 'auto' }}
            />

            {searched && !loading && (
              <span className='btn btn-light mb-3'>
                Matches{' '}
                <span className='badge' style={{ backgroundColor: '#2B5B5F' }}>
                  {totalRecords}
                </span>
              </span>
            )}

            {searched && !loading && selectedRowIds.length !== 0 && (
              <a
                style={{ marginBottom: '15px', marginLeft: '10px' }}
                className='btn btn-outline-danger btn-sm'
                href='#'
                role='button'
                onClick={() => {
                  removeRows(selectedRowIds);
                }}
              >
                Remove rows
              </a>
            )}

            {searched && !loading && (
              <SearchResults
                data={searchResults}
                columns={filteredColumns}
                onRowSelectStateChange={setSelectedRowIds}
                setPage={setPage}
                setPerPage={setPerPage}
                page={page}
                perPage={perPage}
                totalPages={totalPages}
                sortBy={sortBy}
                setSortBy={setSortBy}
                onChangeSort={setSortBy}
                fileName={'search-valuations'}
                exportTable={'valuation'}
                exportRowKey={'id'}
                getPayload={getPayload}
              />
            )}
          </div>
        </div>
      </div>
    </MainThemeBootstrap>
  );
};

export default NewSearch;
