import { atom, RecoilState } from 'recoil';
import { MultiSelectOption, MultiSelectOptions } from './searchVessel';

export interface CreateFixtureForm {
  globalId: string | undefined;
  vesselDetails: MultiSelectOption | undefined;
  requirementType: MultiSelectOption | undefined;
  requirementSubType: MultiSelectOption | undefined;
  fixtureDate: Date | undefined;
  deliveryDate: Date | undefined;
  deliveryPort: MultiSelectOption | undefined;
  region: MultiSelectOption | undefined;
  periodAmountValue: string;
  periodAmountUnit: MultiSelectOption | undefined;
  optionDurationValue: string;
  optionDurationUnit: MultiSelectOption | undefined;
  workScope: string;
  comments: string;
  reDeliveryDate: Date | undefined;
  reDeliveryPort: MultiSelectOption | undefined;
  optionsUntil: Date | undefined;
  charterer: MultiSelectOptions | undefined;
  source: string;
  rate: string;
  rateCurrency: MultiSelectOption | undefined;
  rateType: MultiSelectOption | undefined; // lumpsum/per day
  showRateRNR: string; // boolean
  optionRate: string;
  optionRateCurrency: MultiSelectOption | undefined;
  optionRateType: MultiSelectOption | undefined; // lumpsum/per day,week,month,quarter,year
  mobilisationFeeAmount: string;
  mobilisationFeeAmountCurrency: MultiSelectOption | undefined;
  demobFeeAmount: string;
  demobFeeAmountCurrency: MultiSelectOption | undefined;
  privateAndConfidential: string; // boolean
  own: string; // boolean
  broker: MultiSelectOption | undefined;
}

export const defaultCreateFixtureForm: CreateFixtureForm = {
  globalId: undefined,
  vesselDetails: undefined,
  requirementType: undefined,
  requirementSubType: undefined,
  fixtureDate: new Date(),
  deliveryDate: undefined,
  deliveryPort: undefined,
  region: undefined,
  periodAmountValue: '',
  periodAmountUnit: undefined,
  optionDurationValue: '',
  optionDurationUnit: undefined,
  workScope: '',
  comments: '',
  reDeliveryDate: undefined,
  reDeliveryPort: undefined,
  optionsUntil: undefined,
  charterer: undefined,
  source: '',
  rate: '',
  rateCurrency: { label: 'USD', value: 'USD' },
  rateType: { label: 'Per Day', value: 'perday' },
  showRateRNR: '',
  optionRate: '',
  optionRateCurrency: { label: 'USD', value: 'USD' },
  optionRateType: undefined,
  mobilisationFeeAmount: '',
  mobilisationFeeAmountCurrency: { label: 'USD', value: 'USD' },
  demobFeeAmount: '',
  demobFeeAmountCurrency: { label: 'USD', value: 'USD' },
  privateAndConfidential: '',
  own: '',
  broker: undefined,
};

export const createFixtureForm: RecoilState<CreateFixtureForm> = atom({
  key: 'createFixtureFormState',
  default: defaultCreateFixtureForm,
});

export const isNewFixture = atom({
  key: 'isNewFixture',
  default: false,
});
