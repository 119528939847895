import React, { useEffect } from 'react';
import 'react-fine-uploader/gallery/gallery.css';
import Gallery from './FineUploader/Gallery';
import { getAPIPath } from '../core';
import FineUploaderTraditional from './FineUploader/traditional';
import { getRecentUploads } from '../services/fosService';
import { Buffer } from 'buffer';

const initFileUploader = () => {
  const uploader = new FineUploaderTraditional({
    options: {
      chunking: {
        enabled: true,
      },
      deleteFile: {
        enabled: true,
        endpoint: `${getAPIPath()}/api/fileuploads`,
      },
      request: {
        endpoint: `${getAPIPath()}/api/fileuploads`,
      },
      callbacks: {
        onComplete: (id, name, response) => {},
      },
      cors: {
        //all requests are expected to be cross-domain requests
        expected: true,

        //if you want cookies to be sent along with the request
        sendCredentials: true,
      },
    },
  });

  return uploader;
};

let uploader = initFileUploader();

export const FileUploader = (props) => {
  const { entityId, entityTag } = props;

  useEffect(() => {
    const loadData = async () => {
      getRecentUploads(entityId, entityTag)
        .then(
          async (recentUploads) => {
            uploader.methods.reset();

            const uploads = recentUploads.map((u) => ({
              ...u,
              thumbnailUrl: u.fileBuffer
                ? `data:${u.contentType};base64, ${Buffer.from(
                    u.fileBuffer
                  ).toString('base64')}`
                : null,
            }));

            uploader.methods.addInitialFiles(uploads);

            uploader.methods.setParams({ id: entityId, entityTag: entityTag });
            uploader.methods.setDeleteFileParams({ id: entityId });

            setTimeout(() => {
              const uploads = uploader.methods.getUploads();
              uploads.forEach((file) => {
                uploader.triggerStatusChanged(file.id, null, file.status);
              });
            }, 1000);
          },
          (reason) => {
            console.log('ERROR');
          }
        )
        .catch((error) => {
          console.log('ERROR');
        });
    };

    loadData();
  }, [entityId, entityTag]);

  return (
    <>
      <br />
      {uploader && <Gallery uploader={uploader} />}
    </>
  );
};
