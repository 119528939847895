const PreviewIcon = ({ ...props }) => {
  return (
    <svg
      viewBox='0 0 24 24'
      height='24'
      width='24'
      enable-background='new 0 0 24 24'
      {...props}
    >
      <path d='M 5 2 C 3.9 2 3 2.9 3 4 L 3 19 C 3 20.1 3.9 21 5 21 L 9.90625 21 C 9.40625 20.4 9.1125 19.7 8.8125 19 L 5 19 L 5 4 L 17 4 L 17 10.8125 C 17.7 11.0125 18.4 11.40625 19 11.90625 L 19 4 C 19 2.9 18.1 2 17 2 L 5 2 z M 15 12 C 12.254545 12 10 14.254545 10 17 C 10 19.745455 12.254545 22 15 22 C 16.014334 22 16.958627 21.71405 17.75 21.1875 L 20.59375 24 L 22 22.59375 L 19.1875 19.75 C 19.71405 18.958627 20 18.014334 20 17 C 20 14.254545 17.745455 12 15 12 z M 15 14 C 16.654545 14 18 15.345455 18 17 C 18 18.654545 16.654545 20 15 20 C 13.345455 20 12 18.654545 12 17 C 12 15.345455 13.345455 14 15 14 z' />
    </svg>
  );
};

export default PreviewIcon;
