import { atom, RecoilState } from 'recoil';
import { CreateSaleForm as CreateSnPSaleForm } from './createSale';

export const saleGlobalId: RecoilState<string> = atom({
  key: 'saleGlobalId',
  default: '',
});

export const snpSaleState: RecoilState<EditSnPSaleForm | undefined> = atom({
  key: 'snpSaleState',
  default: undefined as EditSnPSaleForm | undefined,
});

export const snpSaleEditFormState: RecoilState<EditSnPSaleForm | undefined> =
  atom({
    key: 'snpSaleEditFormState',
    default: undefined as EditSnPSaleForm | undefined,
  });

export interface EditSnPSaleForm extends CreateSnPSaleForm {
  id: string;
  imo: string | null;
  updated: Date | undefined;
  updatedBy: string;
}
