import { getAPIPath } from '../core';

export interface CurrencyResponse {
  currency: string;
  id: string;
  rate: number;
  rate_date: string;
  updated: string;
}

export const getRatesAll = async (
  date: Date | undefined
): Promise<CurrencyResponse[]> => {
  if (date === undefined) {
    return Promise.reject();
  }

  const finalRequestBody = {
    rateDate: date,
  };

  const res = await fetch(getAPIPath() + `/api/currency/rates-all`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    credentials: 'include', // include, *same-origin, omit
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(finalRequestBody),
  });

  if (res.status !== 200) {
    // TODO: Handle load error
  }

  const ratesAll = await res.json();
  return ratesAll as CurrencyResponse[];
};
