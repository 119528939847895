/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-sequences */
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { SetterOrUpdater, useRecoilState, useResetRecoilState } from 'recoil';
import FOSBSMultiSelectAsync from '../../common/FOSBSMultiSelectAsync';
import {
  FormPart,
  ExtrasForm,
  GeneralForm,
  OtherForm,
  PositionsForm,
  UnderDeckForm,
  vesselSearchExtrasForm,
  vesselSearchGeneralForm,
  vesselSearchOtherForm,
  vesselSearchPositionsForm,
  vesselSearchUnderDeckForm,
  MultiSelectOption,
  MultiSelectOptions,
  keptVesselSearchResults,
  vesselSearchResults,
} from '../../state/searchVessel';
import { MainThemeBootstrap } from '../../Theme/MainThemeBootstrap';
import { useEffect, useRef } from 'react';
import FOSBSTextInput from '../../common/FOSBSTextInput';
import FOSBSMultiSelect from '../../common/FOSBSMultiSelect';
import FOSBSMultiSelectSort from '../../common/FOSBSMultiSelectSort';
import { getAPIPath } from '../../core';
import FOSBSBoolean from '../../common/FOSBSBoolean';
import { flagsOther, regions } from '../../datas';
import FOSBSTriggerSelect from '../../common/FOSBSTriggerSelect';
import { convertToVesselSearchPayload } from '../../mapper/vessel-search';
import { SearchResults } from '../SearchResults';
import { Collapse } from 'react-collapse';
import FOSBSRange from '../../common/FOSBSRange';
import FOSBSDatePicker from '../../common/FOSBSDatePicker';
import { SearchColumns } from '../search-columns';
import { searchVessels } from '../../services/fosService';
import {
  getShipyardInfo,
  updateSearchColumns,
  getSearchColumns,
} from '../../services/fosService';
import { userState } from '../../state/state';
import BarLoader from 'react-spinners/BarLoader';
import { SelectRegionMap } from '../../Maps/SelectRegionMap';
import { VesselsPositionMap } from '../../Maps/VesselsPositionMap';
import { union } from '../../util';

export const FOSSelectedVessel = 'fos-selected-vessel';

const allColumns = SearchColumns().Vessel.map((el) => el.accessor);
const defaultColumns = SearchColumns()
  .Vessel.filter((el) => el.default)
  .map((el) => el.accessor);

const mapToMultiSelect = (rawArr: string[]): MultiSelectOptions => {
  return rawArr.map((el) => {
    return { label: el, value: el };
  });
};

type SortByType = {
  readonly id: string;
  readonly desc: boolean;
};

const NewSearch = (props: any) => {
  const { cookieKey } = props;
  const [cookies, setCookie] = useCookies([cookieKey]);

  // user params
  const [userStateVal] = useRecoilState(userState);

  // paging params
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState<SortByType>({
    id: 'vessel_name',
    desc: false,
  });

  // search params
  const [combined, setCombined] = useState(false);
  const [searched, setSearched] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [posConstraintCollapsed, setPosConstraintCollapsed] = useState(true);

  // data lists
  const [vesselPossibleColumns, setVesselPossibleColumns] =
    useState<MultiSelectOptions>([]);
  const [definedColumns, setDefinedColumns] = useState<MultiSelectOptions>([]);
  const [vesselTypes, setVesselTypes] = useState<MultiSelectOptions>([]);
  const [vesselSubTypes, setVesselSubTypes] = useState<MultiSelectOptions>([]);
  const [shipStatuses, setShipStatuses] = useState<MultiSelectOptions>([]);
  const [shipyardRegions, setShipyardRegions] = useState<MultiSelectOptions>(
    []
  );
  const [iceClasses, setIceClasses] = useState<MultiSelectOptions>([]);
  const [shipClasses, setShipClasses] = useState<MultiSelectOptions>([]);
  const [flags, setFlags] = useState<MultiSelectOptions>([]);
  const [positionRegions, setPositionRegions] = useState<MultiSelectOptions>(
    []
  );

  const [selectedRowIds, setSelectedRowIds] = useState<readonly string[]>([]);

  const [removedRows, setRemovedRows] = useState<readonly string[]>([]);
  const [searchRequests, setSearchRequests] = useState<any[]>([{}]);

  // form states
  const [generalFormState, setGeneralFormState] = useRecoilState(
    vesselSearchGeneralForm
  );
  const [positionsFormState, setPositionsFormState] = useRecoilState(
    vesselSearchPositionsForm
  );

  const [extrasFormState, setExtrasFormState] = useRecoilState(
    vesselSearchExtrasForm
  );
  const [underDeckFormState, setUnderDeckFormState] = useRecoilState(
    vesselSearchUnderDeckForm
  );
  const [otherFormState, setOtherFormState] = useRecoilState(
    vesselSearchOtherForm
  );

  // search result states
  const [searchResults, setSearchResults] = useRecoilState(vesselSearchResults);
  const [keptSearchResults, setKeptSearchResults] = useRecoilState(
    keptVesselSearchResults
  );

  // form resets
  const resetGeneral = useResetRecoilState(vesselSearchGeneralForm);
  const resetPositions = useResetRecoilState(vesselSearchPositionsForm);
  const resetExtras = useResetRecoilState(vesselSearchExtrasForm);
  const resetUnderDeck = useResetRecoilState(vesselSearchUnderDeckForm);
  const resetOther = useResetRecoilState(vesselSearchOtherForm);

  // form state refs
  const latestGeneralFormState = useRef(generalFormState);
  const latestPositionsFormState = useRef(positionsFormState);
  const latestExtrasFormState = useRef(extrasFormState);
  const latestUnderDeckFormState = useRef(underDeckFormState);
  const latestOtherFormState = useRef(otherFormState);

  const setDefaultShipStatus = () => {
    setGeneralFormState((state) => ({
      ...state,
      shipStatuses: [
        {
          label: 'In Service',
          value: 'In Service',
        },
        {
          label: 'Laid-Up',
          value: 'Laid-Up',
        },
        {
          label: 'Under Construction',
          value: 'Under Construction',
        },
      ],
    }));
  };

  // subscribe for state updates (needed due to use of memoized components)
  useEffect(
    () => {
      latestGeneralFormState.current = generalFormState;
      latestPositionsFormState.current = positionsFormState;
      latestExtrasFormState.current = extrasFormState;
      latestUnderDeckFormState.current = underDeckFormState;
      latestOtherFormState.current = otherFormState;
    },
    // deps to watch for changes in
    [
      generalFormState,
      positionsFormState,
      extrasFormState,
      underDeckFormState,
      otherFormState,
    ]
  );

  // On mount!
  useEffect(() => {
    const loadData = async () => {
      const res = await fetch(getAPIPath() + `/api/meta/vessels/general`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {},
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (res.status !== 200) {
        // TODO: Handle load error
      }

      const generalMetaInfo = await res.json();

      setVesselPossibleColumns(mapToMultiSelect(allColumns));
      setVesselTypes(mapToMultiSelect(generalMetaInfo.vesselTypes));
      setVesselSubTypes(mapToMultiSelect(generalMetaInfo.vesselSubTypes));
      setShipStatuses(mapToMultiSelect(generalMetaInfo.shipStatuses));
      setShipyardRegions(mapToMultiSelect(generalMetaInfo.shipyardRegions));
      setIceClasses(mapToMultiSelect(generalMetaInfo.iceClasses));
      setShipClasses(mapToMultiSelect(generalMetaInfo.shipClasses));

      // map flags to MultiSelectOption format
      setFlags(
        flagsOther.map((el) => {
          return {
            label: `${el.code} - ${el.title}`,
            value: el.code,
          };
        })
      );

      // map regions
      setPositionRegions(regions);

      const searchColumnsFromDB = await getSearchColumns(
        userStateVal.userId,
        cookieKey
      );
      const searchColumnsFromCookies = cookies[cookieKey];

      if (!searchColumnsFromDB && !searchColumnsFromCookies) {
        setDefinedColumns(mapToMultiSelect(defaultColumns));
        setCookie(cookieKey, defaultColumns);
      } else if (
        Array.isArray(searchColumnsFromDB) &&
        searchColumnsFromDB.length > 0
      ) {
        setDefinedColumns(mapToMultiSelect(searchColumnsFromDB));
      } else {
        setDefinedColumns(mapToMultiSelect(searchColumnsFromCookies));
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    setDefaultShipStatus();
  }, []);

  useEffect(() => {
    if (searched) {
      doSearch();
    }
  }, [page, perPage, sortBy]);

  // refresh table each time user removes row
  useEffect(() => {
    if (removedRows?.length) {
      doSearch();
    }
  }, [removedRows]);

  useEffect(() => {
    const loadShipyardInfo = async (shipyard: MultiSelectOptions) => {
      let countries = [] as string[];
      let regions = [] as string[];

      await Promise.all(
        shipyard.map(async (el) => {
          try {
            const shipyardInfo = await getShipyardInfo(el.value);

            if (!countries.includes(shipyardInfo.country)) {
              countries.push(shipyardInfo.country);
            }

            if (!regions.includes(shipyardInfo.continent)) {
              regions.push(shipyardInfo.continent);
            }
          } catch (error) {
            console.log(error);
          }
        })
      );

      setGeneralFormState((state) => ({
        ...state,
        shipyardCountry: mapToMultiSelect(countries),
        shipyardRegion: mapToMultiSelect(regions),
      }));
    };

    if (generalFormState.shipyard.length > 0)
      loadShipyardInfo(generalFormState.shipyard);
  }, [generalFormState.shipyard]);

  const filteredColumns = definedColumns.map((accessor: MultiSelectOption) =>
    SearchColumns().Vessel.find((column) => column.accessor === accessor.value)
  );

  const getPayload = () => {
    const displayFields = definedColumns.map((columns) => columns.value);

    return {
      requests: searchRequests,
      displayFields,
      removedRows,
    };
  };

  const getCurrentPayload = () => {
    const payload = convertToVesselSearchPayload({
      general: generalFormState,
      positions: positionsFormState,
      extras: extrasFormState,
      underDeck: underDeckFormState,
      others: otherFormState,
    });

    return payload;
  };

  const removeRows = (rows: readonly string[]) => {
    if (rows) {
      const updatedRemovedRows = union(removedRows ? [...removedRows] : [], [
        ...rows,
      ]);
      setRemovedRows(updatedRemovedRows);
    }
  };

  const doSearch = async () => {
    setLoading(true);
    const finalRequestBody = getCurrentPayload();

    const updatedSearchRequests = [...searchRequests];
    updatedSearchRequests[updatedSearchRequests.length - 1] = finalRequestBody;
    setSearchRequests(updatedSearchRequests);

    const displayFields = definedColumns.map((columns) => columns.value);
    const searchResponse = await searchVessels(
      updatedSearchRequests,
      displayFields,
      removedRows,
      page,
      perPage,
      sortBy
    );

    const vesselsMapped = searchResponse.vessels.map((el: any) => {
      return {
        id: `${el.imo}-${el.vessel_name}`,
        ...el,
      };
    });

    // Filter out duplicates (in case imos are doubled)
    const newSearchResultsFiltered = vesselsMapped.filter(
      (el: any, index: any) => {
        return (
          vesselsMapped.findIndex((matchEl: any) => {
            return (
              matchEl.imo === el.imo && matchEl.vessel_name === el.vessel_name
            ); // match on both imo and name, because imo can sometimes be 'wrong'
          }) === index
        );
      }
    );

    setSearchResults(newSearchResultsFiltered);
    setKeptSearchResults([...keptSearchResults, ...newSearchResultsFiltered]);

    setLoading(false);
    setSearched(true);
    setTotalRecords(searchResponse.pagination.total);
    setTotalPages(searchResponse.pagination.lastPage);

    await updateSearchColumns(
      userStateVal.userId,
      cookieKey,
      definedColumns.map((columns) => columns.value)
    );
  };

  const doReset = () => {
    resetGeneral();
    resetPositions();
    resetExtras();
    resetUnderDeck();
    resetOther();

    setDefaultShipStatus();
    setRemovedRows([]);
    setSearchRequests([{}]);
  };

  const setFormStateElement = (
    formPart: FormPart,
    field: string,
    value: any
  ) => {
    // fetch setter method and current (latest) version of the data.
    const getPrevValueAndSetter = (
      fp: FormPart
    ): {
      existingValue:
        | GeneralForm
        | PositionsForm
        | ExtrasForm
        | UnderDeckForm
        | OtherForm;
      setter: SetterOrUpdater<any>;
    } => {
      switch (fp) {
        case FormPart.Positions:
          return {
            existingValue: latestPositionsFormState.current,
            setter: setPositionsFormState,
          };
        case FormPart.Extras:
          return {
            existingValue: latestExtrasFormState.current,
            setter: setExtrasFormState,
          };
        case FormPart.UnderDeck:
          return {
            existingValue: latestUnderDeckFormState.current,
            setter: setUnderDeckFormState,
          };
        case FormPart.Other:
          return {
            existingValue: latestOtherFormState.current,
            setter: setOtherFormState,
          };
        default:
        case FormPart.General:
          return {
            existingValue: latestGeneralFormState.current,
            setter: setGeneralFormState,
          };
      }
    };

    // fetch prev val and setter based on existing values
    const { existingValue, setter } = getPrevValueAndSetter(formPart);

    // call setter func and update its vals
    setter({
      ...existingValue,
      [field]: value,
    });
  };

  const handleMapSelectArea = (polygon: [number, number][]) => {
    setFormStateElement(FormPart.Positions, 'positionConstraint', {
      polygon,
    });
  };

  return (
    <MainThemeBootstrap>
      <div className='container-fluid'>
        <p className='pt-3'>
          <a
            className='btn btn-danger btn-sm'
            href='#collapseExample'
            role='button'
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            Toggle search
          </a>
          <a
            style={{ marginLeft: '20px' }}
            className='btn btn-outline-primary btn-sm'
            href='#collapseExample'
            role='button'
            onClick={() => {
              setSearchRequests([...searchRequests, {}]);
              setCollapsed(false);
              setCombined(true);
            }}
          >
            Add new search
          </a>
          {combined && (
            <a
              style={{ marginLeft: '20px' }}
              className='btn btn-outline-danger btn-sm'
              href='#'
              role='button'
              onClick={() => {
                setCombined(false);
                doReset();
              }}
            >
              Reset
            </a>
          )}
        </p>

        <Collapse isOpened={!collapsed}>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-3 bg-light pt-2'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  General Info
                </h5>
              </div>

              <FOSBSTextInput
                label={'Imo Number'}
                value={generalFormState.imoNumber}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'imoNumber',
                    e.target.value
                  );
                }}
              />
              <FOSBSTextInput
                label={'Vessel Name'}
                value={generalFormState.vesselName}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'vesselName',
                    e.target.value
                  );
                }}
              />
              <FOSBSTextInput
                label={'Ex names'}
                value={generalFormState.vesselExNames}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'vesselExNames',
                    e.target.value
                  );
                }}
              />
              <FOSBSTextInput
                label={'Owner'}
                value={generalFormState.owner}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'owner',
                    e.target.value
                  );
                }}
              />

              <FOSBSTextInput
                label={'Technical Manager'}
                value={generalFormState.technical_manager}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.General,
                    'technical_manager',
                    e.target.value
                  );
                }}
              />

              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Design
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='design'
                    name='design'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    value={generalFormState.design}
                    isCreatable={true}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Vessel Type
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='vesselType'
                    value={generalFormState.vesselType}
                    options={vesselTypes}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>

              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Vessel Subtype
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='vesselSubType'
                    value={generalFormState.vesselSubType}
                    options={vesselSubTypes}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>

              <FOSBSBoolean
                label={'Vessel Subsea'}
                name={'subSea'}
                value={generalFormState.subSea}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
              />

              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Controlling Contractor
                </label>
                <div className='col'>
                  {/* <input type="text" className="form-control form-select-sm" /> */}
                  <FOSBSMultiSelectAsync
                    dbFieldName='controlling_contractor'
                    name='controllingContractor'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    value={generalFormState.controllingContractor}
                  />
                </div>
              </div>

              <FOSBSBoolean
                label={'North sea spot'}
                name={'northSeaSpot'}
                value={generalFormState.northSeaSpot}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
              />

              <FOSBSTriggerSelect
                displayVertical={true}
                label={'Ship Status'}
                options={shipStatuses}
                name={'shipStatuses'}
                value={generalFormState.shipStatuses}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
              />

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Shipyard
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='shipyard'
                    name='shipyard'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    value={generalFormState.shipyard}
                    isCreatable={false}
                  />
                </div>
              </div>
              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Shipyard Country
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='shipyard_country'
                    name='shipyardCountry'
                    value={generalFormState.shipyardCountry}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Shipyard Region
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='shipyardRegion'
                    value={generalFormState.shipyardRegion}
                    options={shipyardRegions}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Position info
                </h5>
              </div>
              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Region
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='region'
                    value={generalFormState.region}
                    options={positionRegions}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>

              <div className='row align-items-center'>
                <label className='col-sm-4 col-form-label'>
                  Date Available
                </label>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'From'}
                    value={positionsFormState.dateAvailableFrom}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.Positions, name, value);
                    }}
                    name={'dateAvailableFrom'}
                  />
                </div>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'To'}
                    value={positionsFormState.dateAvailableTo}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.Positions, name, value);
                    }}
                    name={'dateAvailableTo'}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Current Charterer
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    isMulti={true}
                    dbFieldName='current_charterer'
                    name='currentCharterer'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.Positions, name, value);
                    }}
                    value={positionsFormState.currentCharterer}
                  />
                </div>
              </div>
              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Next Charterer
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    isMulti={true}
                    dbFieldName='next_charterer'
                    name='nextCharterer'
                    stateSetter={(name: string, value: string) => {
                      console.log('setting value', name, value);
                      setFormStateElement(FormPart.Positions, name, value);
                    }}
                    value={positionsFormState.nextCharterer}
                  />
                </div>
              </div>

              <button
                className='btn btn-dark'
                onClick={() => {
                  setPosConstraintCollapsed(!posConstraintCollapsed);
                }}
              >
                {posConstraintCollapsed && (
                  <>&darr; Show map constraint &darr;</>
                )}
                {!posConstraintCollapsed && (
                  <>&uarr; Hide map constraint &uarr;</>
                )}
              </button>
              <Collapse isOpened={!posConstraintCollapsed}>
                <div className='row pb-1'>
                  <div className='col mt-2'>
                    <SelectRegionMap
                      height={400}
                      width={'100%'}
                      onSelect={handleMapSelectArea}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 pt-2 bg-light'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Dimensions
                </h5>
              </div>

              <div className='row align-items-center'>
                <label className='col-sm-4 col-form-label'>Built</label>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'From'}
                    value={generalFormState.builtFrom}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    name={'builtFrom'}
                  />
                </div>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'To'}
                    value={generalFormState.builtTo}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                    name={'builtTo'}
                  />
                </div>
              </div>
              <FOSBSRange
                label='DWT'
                name='DWT'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.DWT}
              />

              <FOSBSRange
                label='BHP'
                name='BHP'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.BHP}
              />

              <FOSBSRange
                label='BP'
                name='BP'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.BP}
              />

              <FOSBSRange
                label='Deck'
                name='deck'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.deck}
              />

              <FOSBSRange
                label='Crane 1'
                name='craneSize1'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.craneSize1}
              />

              <FOSBSRange
                label='Crane 2'
                name='craneSize2'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.craneSize2}
              />

              <FOSBSRange
                label='Crane 3'
                name='craneSize3'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.craneSize3}
              />

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'DP'}
                options={[
                  { label: 'NO', value: 'NO' },
                  { label: 'N', value: 'N' },
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                ]}
                name={'DP'}
                value={generalFormState.DP}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
              />

              <FOSBSRange
                label='Beds'
                name='beds'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.beds}
              />

              <FOSBSRange
                label='Beam'
                name='beam'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.beam}
              />

              <FOSBSRange
                label='Max Draft'
                name='maxDraft'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.maxDraft}
              />

              <FOSBSRange
                label='LOA'
                name='LOA'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.General, name, value);
                }}
                value={generalFormState.LOA}
              />

              <div className='row pt-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Mooring
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='mooring'
                    value={generalFormState.mooring}
                    options={[
                      { label: '1', value: '1' },
                      { label: '2', value: '2' },
                      { label: '4', value: '4' },
                      { label: '6', value: '6' },
                      { label: '8', value: '8' },
                      { label: '10', value: '10' },
                      { label: '12', value: '12' },
                      { label: 'No', value: 'No' },
                    ]}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.General, name, value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 pt-2 bg-light'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Extra
                </h5>
              </div>

              <div className='row'>
                <label
                  htmlFor='vesselSearchColumns'
                  className='col-sm-4 col-form-label'
                >
                  Displayed Columns
                </label>
                <div className='col'>
                  <FOSBSMultiSelectSort
                    name='vesselSearchColumns'
                    value={definedColumns}
                    options={vesselPossibleColumns}
                    stateSetter={(name: string, value: MultiSelectOptions) => {
                      setDefinedColumns(value);
                      setCookie(
                        cookieKey,
                        value.map((v) => v.value)
                      );
                    }}
                  />
                </div>
              </div>

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'ROV'}
                options={[
                  { label: 'No', value: 'No' },
                  { label: 'Yes', value: 'Yes' },
                  { label: 'Mezzdeck', value: 'Mezzdeck' },
                  { label: 'Hangar', value: 'Hangar' },
                ]}
                name={'ROV'}
                value={extrasFormState.ROV}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSBoolean
                label={'Moonpool'}
                name={'moonpool'}
                value={extrasFormState.moonpool}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSBoolean
                label={'Helideck'}
                name={'helideck'}
                value={extrasFormState.helideck}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSRange
                label='Wire Capacity'
                name='wireCapacity'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
                value={extrasFormState.wireCapacity}
              />

              <FOSBSRange
                label='AFrame'
                name='AFrame'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
                value={extrasFormState.AFrame}
              />

              <FOSBSRange
                label='Deck Strength (t/m2)'
                name='deckStrength'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
                value={extrasFormState.deckStrength}
              />

              <FOSBSRange
                label='Winch Pull'
                name='winchPull'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
                value={extrasFormState.winchPull}
              />

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'Fifi'}
                options={[
                  { label: 'No', value: 'No' },
                  { label: 'Prepared', value: 'Prepared' },
                  { label: '1', value: '1' },
                  { label: '2', value: '2' },
                  { label: '3', value: '3' },
                  { label: '1+2', value: '1+2' },
                ]}
                name={'fifi'}
                value={extrasFormState.fifi}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'Oil Rec'}
                options={[
                  { label: 'N', value: 'N' },
                  { label: 'Y', value: 'Y' },
                  { label: 'Unknown', value: 'Unknown' },
                  { label: 'NOFO 2009', value: 'NOFO 2009' },
                  { label: 'NOFO 2005', value: 'NOFO 2005' },
                ]}
                name={'oilRec'}
                value={extrasFormState.oilRec}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Ice Class
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='iceClass'
                    value={extrasFormState.iceClass}
                    options={iceClasses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.Extras, name, value);
                    }}
                  />
                </div>
              </div>

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'FOS Ice Class'}
                options={[
                  { label: 'Ice C', value: 'Ice C' },
                  { label: 'Icebreaker', value: 'Icebreaker' },
                  { label: 'Ice 1B', value: 'Ice 1B' },
                  { label: 'No', value: 'No' },
                  { label: 'Ice 1A', value: 'Ice 1A' },
                  { label: 'Ice IA Super', value: 'Ice IA Super' },
                  { label: 'Ice 1C', value: 'Ice 1C' },
                ]}
                name={'FOSIceClass'}
                value={extrasFormState.FOSIceClass}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSBoolean
                label={'SPS'}
                name={'SPS'}
                value={extrasFormState.SPS}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSRange
                label='Max Speed (Knots)'
                name='maxSpeed'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
                value={extrasFormState.maxSpeed}
              />

              <FOSBSBoolean
                label={'Standby'}
                name={'standby'}
                value={extrasFormState.standby}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSBoolean
                label={'Walk2work'}
                name={'walkToWork'}
                value={extrasFormState.walkToWork}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSRange
                label='Survivors'
                name='survivors'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
                value={extrasFormState.survivors}
              />

              <FOSBSRange
                label='Passengers'
                name='passengers'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
                value={extrasFormState.passengers}
              />

              <FOSBSBoolean
                label={'Battery'}
                name={'battery'}
                value={extrasFormState.battery}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSBoolean
                label={'Dual Fuel'}
                name={'dualFuel'}
                value={extrasFormState.dualFuel}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />

              <FOSBSBoolean
                label={'Shore Power'}
                name={'shorePower'}
                value={extrasFormState.shorePower}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Extras, name, value);
                }}
              />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 pt-2 bg-light'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Under deck
                </h5>
              </div>

              <FOSBSRange
                label={'Fuel (m3)'}
                name={'fuel'}
                value={underDeckFormState.fuel}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />

              <FOSBSRange
                label={'Methanol (m3)'}
                name={'methanol'}
                value={underDeckFormState.methanol}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />

              <FOSBSRange
                label={'Bulk (m3)'}
                name={'bulk'}
                value={underDeckFormState.bulk}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />

              <FOSBSRange
                label={'Oil Rec (m3)'}
                name={'oilRec'}
                value={underDeckFormState.oilRec}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />

              <FOSBSRange
                label={'Brine (m3)'}
                name={'brine'}
                value={underDeckFormState.brine}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />
              <FOSBSRange
                label={'Mud (m3)'}
                name={'mud'}
                value={underDeckFormState.mud}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />
              <FOSBSRange
                label={'Base Oil (m3)'}
                name={'baseOil'}
                value={underDeckFormState.baseOil}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />

              <FOSBSRange
                label={'Special Products (m3)'}
                name={'specialProducts'}
                value={underDeckFormState.specialProducts}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />
              <FOSBSRange
                label={'Ballast Water (m3)'}
                name={'ballastWater'}
                value={underDeckFormState.ballastWater}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />
              <FOSBSRange
                label={'MEG (m3)'}
                name={'MEG'}
                value={underDeckFormState.MEG}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />
              <FOSBSRange
                label={'Pot Water (m3)'}
                name={'potWater'}
                value={underDeckFormState.potWater}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.UnderDeck, name, value);
                }}
              />

              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Other
                </h5>
              </div>

              <FOSBSTextInput
                label={'Hull Number'}
                value={otherFormState.hullNumber}
                onChange={(e) => {
                  setFormStateElement(
                    FormPart.Other,
                    'hullNumber',
                    e.target.value
                  );
                }}
              />

              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Class
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='class'
                    value={otherFormState.class}
                    options={shipClasses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.Other, name, value);
                    }}
                  />
                </div>
              </div>
              <div className='row pt-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Flag
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='flag'
                    value={otherFormState.flag}
                    options={flags}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(FormPart.Other, name, value);
                    }}
                  />
                </div>
              </div>

              <div className='row align-items-center'>
                <label className='col-sm-4 col-form-label'>NB/Order Date</label>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'From'}
                    value={otherFormState.nbOrderDateFrom}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.Other, name, value);
                    }}
                    name={'nbOrderDateFrom'}
                  />
                </div>
                <div className='col'>
                  <FOSBSDatePicker
                    placeholder={'To'}
                    value={otherFormState.nbOrderDateTo}
                    stateSetter={(name, value) => {
                      setFormStateElement(FormPart.Other, name, value);
                    }}
                    name={'nbOrderDateTo'}
                  />
                </div>
              </div>

              <FOSBSRange
                label={'Contract Price'}
                name={'contractPrice'}
                value={otherFormState.contractPrice}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Other, name, value);
                }}
              />

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'Dive'}
                options={[
                  { label: 'Sat. Dive', value: 'Sat. Dive' },
                  { label: 'No', value: 'No' },
                  { label: 'Yes', value: 'Yes' },
                  { label: 'Air Dive', value: 'Air Dive' },
                ]}
                name={'dive'}
                value={otherFormState.dive}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Other, name, value);
                }}
              />

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'Tower'}
                options={[
                  { label: 'Well Int.', value: 'Well Int.' },
                  { label: 'No', value: 'No' },
                  { label: 'Other', value: 'Other' },
                  { label: 'Module', value: 'Module' },
                ]}
                name={'tower'}
                value={otherFormState.tower}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Other, name, value);
                }}
              />

              <FOSBSTriggerSelect
                displayVertical={false}
                label={'Underdeck Carousel'}
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'Prepared', value: 'Prepared' },
                ]}
                name={'underdeckCarousel'}
                value={otherFormState.underdeckCarousel}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Other, name, value);
                }}
              />

              <FOSBSBoolean
                label={'Lay Spread'}
                name={'laySpread'}
                value={otherFormState.laySpread}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Other, name, value);
                }}
              />

              <FOSBSTextInput
                label={'ERN'}
                value={otherFormState.ERN}
                onChange={(e) => {
                  setFormStateElement(FormPart.Other, 'ERN', e.target.value);
                }}
              />

              <FOSBSRange
                label={'Streamer Capacity'}
                name={'streamerCapacity'}
                value={otherFormState.streamerCapacity}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(FormPart.Other, name, value);
                }}
              />
            </div>
          </div>

          <div className='row'>
            <div
              className='col-md-12'
              style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
            >
              <button
                className='btn btn-success'
                style={{ marginRight: 5 }}
                onClick={() => {
                  doSearch();
                  setCollapsed(true);
                }}
              >
                Search
              </button>
              <button
                className='btn btn-secondary'
                style={{ marginRight: 5 }}
                onClick={doReset}
              >
                Reset
              </button>
            </div>
          </div>
        </Collapse>
      </div>

      <div className='container-fluid'>
        <div className='row pt-3 ' style={{ minHeight: '400px' }}>
          <div
            className='col-sm-12 col-md-12 col-lg-12'
            style={{ marginBottom: '10px' }}
          >
            <BarLoader
              loading={loading}
              height={4}
              width={200}
              cssOverride={{ display: 'block', margin: 'auto' }}
            />

            {searched && !loading && (
              <span className='btn btn-light mb-3'>
                Matches{' '}
                <span className='badge' style={{ backgroundColor: '#2B5B5F' }}>
                  {totalRecords}
                </span>
              </span>
            )}

            {searched && !loading && selectedRowIds.length !== 0 && (
              <a
                style={{ marginBottom: '15px', marginLeft: '10px' }}
                className='btn btn-outline-danger btn-sm'
                href='#'
                role='button'
                onClick={() => {
                  removeRows(selectedRowIds);
                }}
              >
                Remove rows
              </a>
            )}

            {searched && !loading && selectedRowIds.length !== 0 && (
              <a
                style={{ marginBottom: '15px', marginLeft: '10px' }}
                className='btn btn-outline-primary btn-sm'
                href='/search/vessel/valuation'
                target='_blank'
                role='button'
              >
                Show valuations
              </a>
            )}

            {searched && !loading && selectedRowIds.length !== 0 && (
              <a
                style={{ marginBottom: '15px', marginLeft: '10px' }}
                className='btn btn-outline-primary btn-sm'
                href='/search/vessel/budget-indication'
                target='_blank'
                role='button'
              >
                Show Budget Indications
              </a>
            )}

            {searched && (
              <div style={{ display: loading ? 'none' : undefined }}>
                <SearchResults
                  data={searchResults}
                  columns={filteredColumns}
                  onRowSelectStateChange={async (selectedRowIds: string[]) => {
                    const selectedImos = searchResults
                      .filter((vessel: any) =>
                        selectedRowIds.includes(vessel.global_id)
                      )
                      .map((vessel: any) => vessel.imo);

                    setSelectedRowIds(selectedRowIds);
                    localStorage.setItem(
                      FOSSelectedVessel,
                      JSON.stringify(selectedImos)
                    );
                  }}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  page={page}
                  perPage={perPage}
                  totalPages={totalPages}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  onChangeSort={setSortBy}
                  fileName={'search-vessels'}
                  exportTable={'vessel'}
                  exportRowKey={'global_id'}
                  getPayload={getPayload}
                />
              </div>
            )}
          </div>

          {searched && !loading && searchResults && (
            <VesselsPositionMap
              height={400}
              width={'100%'}
              zoom={[2]}
              vessels={searchResults.map((vessel: any) => ({
                ...vessel,
                coordinates: [Number(vessel.lon), Number(vessel.lat)],
              }))}
              center={[
                Number(searchResults[0]?.lon),
                Number(searchResults[0]?.lat),
              ]}
            />
          )}
        </div>
      </div>
    </MainThemeBootstrap>
  );
};

export default NewSearch;
