import React from 'react';
import { useEffect, useState } from 'react';
import { getLayupList } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';

enum Tab {
  All = 'all',
  AHTS = 'ahts',
  PSV = 'psv',
}

export const LayUpList = (props: any) => {
  const { region } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tab, setTab] = useState<Tab>(Tab.All);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'updated', desc: true });

  useEffect(() => {
    getLayupList(region, page, perPage, sortBy, tab).then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [region, page, perPage, sortBy, tab]);

  useEffect(() => {
    resetPage();
  }, [tab]);

  const resetPage = () => {
    setPage(1);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Region',
        accessor: 'region',
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>
        LAY-UP LIST - INCLUDING NORTH SEA
      </div>

      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <a
            className={tab === Tab.All ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.All);
            }}
            href='#1'
          >
            All
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={tab === Tab.AHTS ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.AHTS);
            }}
            aria-current='page'
            href='#2'
          >
            AHTS
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={tab === Tab.PSV ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.PSV);
            }}
            href='#3'
          >
            PSV
          </a>
        </li>
      </ul>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={data}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
