import { atom, RecoilState } from 'recoil';
import { MultiSelectOption } from './searchVessel';

export interface CreateAvailability {
  global_id?: string;
  vesselDetails?: MultiSelectOption;

  from_date?: Date;
  to_date?: Date;

  region?: MultiSelectOption;

  port?: MultiSelectOption;

  workScope?: string;

  comment?: string;
}

export const createAvailabilityForm: RecoilState<CreateAvailability> = atom({
  key: 'createAvailabilityFormState',
  default: {},
});

export const availabilityGlobalIdLookupState: RecoilState<string> = atom({
  key: 'availabilityGlobalIdLookupState',
  default: '',
});
