import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeModalType, modalEditorOpen, ModalType } from '../state/modals';
import { imoLookupState } from '../state/openVessel';

import { useParams } from 'react-router-dom';

type VesselParams = {
  imo: string;
};

export const OpenVessel = () => {
  // open modal and set lookup mode
  const [modalOpen, setModalOpen] = useRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);
  const setImo = useSetRecoilState(imoLookupState);

  const openVesselModal = (imo: string) => {
    setImo(imo);
    setModalType(ModalType.OpenVessel);
    setModalOpen(true);
    console.log('opening as', imo);
  };

  const { imo } = useParams<VesselParams>();

  useEffect(() => {
    if (modalOpen === false) openVesselModal(imo);
  }, [modalOpen]);

  return (
    <div className='container-fluid'>
      <div>
        <a
          href='#'
          className='link-dark'
          onClick={() => {
            openVesselModal(imo);
          }}
        >
          Open vessel: {imo}
        </a>
      </div>
    </div>
  );
};
