import React from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { activeModalType, ModalType } from '../state/modals';
import { fixtureGlobalIdLookupState } from '../state/openFixture';
import { modalEditorOpen } from '../state/modals';
import { getAllRequirements } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { FOSTableYesNoCell } from '../common/table/FOSTableYesNoCell';

export const OutstandingRequirements = (props: any) => {
  const { tab } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'updated', desc: true });

  const setFixtureGlobalId = useSetRecoilState(fixtureGlobalIdLookupState);
  const [moodalOpen, setModalOpen] = useRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);

  const openRequirement = (fixtureGlobalId: string) => {
    setFixtureGlobalId(fixtureGlobalId);
    setModalOpen(true);
    setModalType(ModalType.OpenRequirement);
  };

  useEffect(() => {
    getAllRequirements(tab, page, perPage, sortBy, undefined, 'subsea').then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [tab, page, perPage, sortBy, moodalOpen]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Charterer',
        accessor: 'charterer',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Period',
        accessor: 'period',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {row.original.period} {row.original.period_type}
          </div>
        ),
      },
      {
        Header: 'Delivery Port',
        accessor: 'delivery_port',
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'PnC',
        accessor: 'p_and_c',
        Cell: (cell: any) => (
          <FOSTableYesNoCell value={cell.value}></FOSTableYesNoCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openRequirement(original.row.original.global_id);
                console.log('opening', original.row.original.global_id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>Outstanding Requirements</div>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={data}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
