import { Link } from 'react-router-dom';
import { Switch, Route, useRouteMatch, useParams } from 'react-router';
import { useEffect, useState } from 'react';

import { ValuesMerger } from './ValuesMerger';
import { getAllFields } from '../services/fosService';

export const FieldSelector = () => {
  let { path, url } = useRouteMatch();
  let { table } = useParams<{ table: string }>();

  const [fields, setFields] = useState<[string?]>([]);

  useEffect(() => {
    getAllFields(table).then(
      (fieldsRes) => {
        setFields(fieldsRes);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, [table]);

  const RouteComponents = fields.map((field) => (
    <Route path={`${path}/:field`} key={field}>
      <ValuesMerger />
    </Route>
  ));

  return (
    <Switch>
      <Route exact path={path}>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <div className='north-sea-spot-caption'>
                Choose field to clean
              </div>

              <ul>
                {fields &&
                  fields.map((field) => (
                    <li key={field}>
                      <Link
                        to={`${url}/${field}`}
                        className='link-primary'
                        aria-current='page'
                      >
                        {field}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </Route>

      {RouteComponents}
    </Switch>
  );
};
