import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Checkbox,
  TextField,
  TablePagination,
  Button,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import {
  getUsers,
  createUser,
  deleteUser,
  updateUser,
} from '../services/fosService';
import { FOSUser } from '../server-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: '20px',
    },
    table: {
      minWidth: 750,
    },
    search: {
      margin: '10px 0 20px 0',
      width: '100%',
    },
  })
);

export const UserAdministration = () => {
  const classes = useStyles();

  const [users, setUsers] = useState<FOSUser[]>([]);
  const [newUser, setNewUser] = useState('');

  const [deleteSelection, setDeleteSelection] = useState<string[]>([]);
  const [resetPasswordSelection, setResetPasswordSelection] = useState<
    string[]
  >([]);
  const [newRoleUsers, setNewRoleUsers] = useState<string[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getUsers().then(
      (usersRes) => {
        setUsers(usersRes);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, []);

  const handleSearchChange = (event: any) => {
    if (page > 0) setPage(0);
    setSearchTerm(event.target.value);
  };

  const handleNewUserChange = (event: any) => {
    setNewUser(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowClick = (
    name: string,
    selection: any,
    setSelection: Function
  ) => {
    const selectedIndex = selection.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selection, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelected = newSelected.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1)
      );
    }

    setSelection(newSelected);
  };

  const handleRoleRowClick = (userId: string, setSelection: Function) => {
    setSelection((users: Array<FOSUser>) =>
      users.map((user: FOSUser) =>
        user.userId === userId ? { ...user, isAdmin: +!user.isAdmin } : user
      )
    );

    handleRowClick(userId, newRoleUsers, setNewRoleUsers);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string, selection: any) =>
    selection.indexOf(name) !== -1;

  const isRoleSelected = (userId: string) =>
    Boolean(users.find((user: FOSUser) => user.userId === userId)?.isAdmin);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const filterUsers = (users: Array<FOSUser>) =>
    users.filter((user: FOSUser) => {
      const emptyString = '';

      if (user.userId === null)
        return emptyString.includes(searchTerm.toLowerCase());

      return JSON.stringify(user.userId)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

  const reset = () => {
    setDeleteSelection([]);
    setResetPasswordSelection([]);
    setSearchTerm('');
    setNewUser('');
    setNewRoleUsers([]);

    getUsers().then(
      (usersRes) => {
        setUsers(usersRes);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  };

  const handleAddNewUserClick = async () => {
    await createUser(newUser).then(
      (userRes) => {
        reset();
      },
      (reason) => {
        console.log('ERROR');
      }
    );
    console.log('save');
  };

  const handleUpdateClick = async () => {
    if (
      deleteSelection.length === 0 &&
      resetPasswordSelection.length === 0 &&
      newRoleUsers.length === 0
    )
      alert('Please choose an option');
    else {
      const deleteTxt = deleteSelection.join(', ');
      const resetPasswordTxt = resetPasswordSelection.join(', ');
      const updateRoleTxt = newRoleUsers.join(', ');

      const deleteMsg =
        deleteSelection.length > 0
          ? `Are you sure you want to delete ${deleteTxt}? `
          : '';

      const resetMsg =
        resetPasswordSelection.length > 0
          ? `Are you sure you want to reset password for ${resetPasswordTxt}? `
          : '';

      const updateRoleMsg =
        newRoleUsers.length > 0
          ? `Are you sure you want to update role for ${updateRoleTxt}? `
          : '';

      const msg = `${deleteMsg}${resetMsg}${updateRoleMsg}`;

      if (window.confirm(msg)) {
        try {
          await handleDelete();
          await handleResetPassword();
          await handleRoleChange();
          reset();

          toast.success('Users has been updated');
        } catch (err) {
          toast.error('Unable to update');
        }
      }
    }
  };

  const handleDelete = async () => {
    for (const userId of deleteSelection) {
      await deleteUser(userId);
    }
  };

  const handleResetPassword = async () => {
    for (const userId of resetPasswordSelection) {
      await updateUser({ userId, passwordHash: '' });
    }
  };

  const handleRoleChange = async () => {
    for (const user of users) {
      if (newRoleUsers.includes(user.userId)) updateUser(user);
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row pt-3'>
        <div className='col-12'>
          <div className='north-sea-spot-caption'>add new user</div>
          <Paper className={classes.paper}>
            <TextField
              label='Add new user'
              placeholder='Set new User ID'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              value={newUser}
              onChange={handleNewUserChange}
              className={classes.search}
            />

            <Button
              variant='contained'
              color='primary'
              onClick={handleAddNewUserClick}
            >
              ADD
            </Button>
          </Paper>
          <div className='north-sea-spot-caption'>users</div>
          <Paper className={classes.paper}>
            <TextField
              label='Search'
              placeholder='Filter users'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              value={searchTerm}
              onChange={handleSearchChange}
              className={classes.search}
            />

            <TableContainer>
              <Table className={classes.table} size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>User ID</TableCell>
                    <TableCell width='170px'>Delete</TableCell>
                    <TableCell width='170px'>Reset password</TableCell>
                    <TableCell width='170px'>Administrator</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filterUsers(users)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user: any, index: number) => {
                      const labelId = `checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell component='th' id={labelId} scope='row'>
                            {user.userId}
                          </TableCell>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={isSelected(user.userId, deleteSelection)}
                              onClick={(event) =>
                                handleRowClick(
                                  user.userId,
                                  deleteSelection,
                                  setDeleteSelection
                                )
                              }
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={isSelected(
                                user.userId,
                                resetPasswordSelection
                              )}
                              onClick={(event) =>
                                handleRowClick(
                                  user.userId,
                                  resetPasswordSelection,
                                  setResetPasswordSelection
                                )
                              }
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={isRoleSelected(user.userId)}
                              onClick={(event) =>
                                handleRoleRowClick(user.userId, setUsers)
                              }
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 30, 50, 100]}
              component='div'
              count={filterUsers(users).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={handleUpdateClick}
            >
              UPDATE
            </Button>
          </Paper>
        </div>
      </div>
    </div>
  );
};
