import { useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { createFixtureForm } from '../../state/createFixture';
import { fixtureGlobalIdLookupState } from '../../state/openFixture';
import { modalEditorOpen } from '../../state/modals';
import { ModalHeader } from '../ModalHeader';
import { toast } from 'react-toastify';
import { convertToFixtureCreatePayload } from '../../mapper/fixture-create';
import { convertDbFixtureToEditFixtureForm } from '../../mapper/fixture-edit';
import {
  getFixtureLookup,
  createFixture,
  deleteFixture,
} from '../../services/fosService';
import { BudgetIndiationFormUI } from './BudgetIndiationFormUI';

interface Props {
  onRequestClose(): void;
}

export const UpdateBudgetIndicationModal = (props: Props) => {
  const [lookupGlobalId] = useRecoilState(fixtureGlobalIdLookupState);
  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [form, setForm] = useRecoilState(createFixtureForm);
  const resetForm = useResetRecoilState(createFixtureForm);

  // No idea why we need this. But if this is not used then form does not update properly
  const latestFormState = useRef(form);

  // On var update (ref updating)
  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  // On mount!
  useEffect(() => {
    const loadData = async () => {
      console.log('loading data', lookupGlobalId);
      const lookupResp = await getFixtureLookup(lookupGlobalId);
      const fixture = convertDbFixtureToEditFixtureForm(lookupResp[0]);

      setForm(fixture);
    };

    loadData();
  }, []);

  const closeModal = () => {
    props.onRequestClose();
  };

  const doCreate = async () => {
    const finalRequestBody = convertToFixtureCreatePayload(form, true);

    try {
      await createFixture(finalRequestBody);

      toast.success('Budget Indication has been updated');
      resetForm();
      setModalOpen(false);
    } catch (e) {
      toast.error('Error updating Budget Indication');
    }
  };

  const doDelete = async () => {
    const r = window.confirm(
      'Are you sure you want to delete Budget Indication?'
    );
    if (!r) return;

    const response = await deleteFixture(lookupGlobalId);

    if (response.status) {
      toast.success('Fixture has been deleted');
      resetForm();
      setModalOpen(false);
    } else {
      toast.error(response.message);
    }
  };

  return (
    <div>
      <ModalHeader
        onRequestClose={closeModal}
        header={'Update Budget Indication'}
      />
      <BudgetIndiationFormUI
        form={form}
        setForm={setForm}
        latestFormState={latestFormState}
      />
      <div className='row'>
        <div className='col-sm-12' style={{ textAlign: 'center' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-success btn'
            onClick={() => {
              doCreate();
            }}
          >
            Update
          </button>
          <button
            type='button'
            className='btn btn-danger btn'
            style={{ marginLeft: 10 }}
            onClick={() => {
              doDelete();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
