import moment from 'moment';

export const FOSTableNextChartererCell = ({
  currenCharterer,
  nextCharterer,
  nextChartererDate,
  currentChartererDate,
}: {
  currenCharterer?: string;
  nextCharterer?: string;
  nextChartererDate?: string;
  currentChartererDate?: string;
}) => {
  if (
    !nextCharterer ||
    (currenCharterer === nextCharterer &&
      new Date(currentChartererDate ?? '').toDateString() ===
        new Date(nextChartererDate ?? '').toDateString())
  )
    return <div></div>;

  const value = [
    nextCharterer,
    nextChartererDate != null
      ? moment(new Date(nextChartererDate)).format('DD.MM.YYYY')
      : '',
  ]
    .filter((x) => x)
    .join(' - ');

  return <div>{value}</div>;
};
