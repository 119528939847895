import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Checkbox,
  Chip,
  TextField,
  TablePagination,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import {
  getAllUniqeFieldValues,
  updateFieldValues,
} from '../services/fosService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: '20px',
    },
    table: {
      minWidth: 750,
    },
    chipContainer: {
      display: 'flex',
      justifyContent: 'start',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    search: {
      margin: '10px 0 20px 0',
      width: 'calc(100%)',
    },
  })
);

export const ValuesMerger = () => {
  const classes = useStyles();

  let { table, field } = useParams<{ table: string; field: string }>();

  const [values, setValues] = useState([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [searchTerm, setSearchTerm] = useState('');
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    getAllUniqeFieldValues(table, field).then(
      (valuesRes) => {
        setValues(valuesRes);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, [table, field]);

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleNewValueChange = (event: any) => {
    setNewValue(event.target.value);
  };

  const handleChipDelete = (chipToDelete: any) => () => {
    setSelected((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleRowClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, values.length - page * rowsPerPage);

  const filterValues = (values: string[]) =>
    values.filter((value: string) => {
      const emptyString = '';

      if (value === null) return emptyString.includes(searchTerm.toLowerCase());

      return JSON.stringify(value)
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });

  const resetValues = () => {
    getAllUniqeFieldValues(table, field).then(
      (valuesRes) => {
        setValues(valuesRes);
      },
      (reason) => {
        console.log('ERROR');
      }
    );

    setSelected([]);
    setSearchTerm('');
    setNewValue('');
  };

  const handleMergeClick = async (event: React.MouseEvent<unknown>) => {
    if (newValue.length === 0) alert('Please set a new value');
    else {
      const existingTxt = selected.join(', ');
      const msg =
        'Are you sure you want to replace ' + existingTxt + ' with ' + newValue;

      if (window.confirm(msg)) {
        try {
          await updateFieldValues(table, field, newValue, selected);

          resetValues();
          toast.success('Fields has been merged');
        } catch (err) {
          toast.error('Unable to rename');
        }
      }
    }
  };

  return (
    <div className='container-fluid'>
      <div className='row pt-3'>
        <div className='col-12'>
          <div className='north-sea-spot-caption'>
            Search or select values to merge
          </div>
          <Paper className={classes.paper}>
            <span>
              Table: {table}, field: {field}
            </span>

            <ul className={classes.chipContainer}>
              {selected.map((data, index) => {
                return (
                  <li key={index}>
                    <Chip
                      color='primary'
                      className={classes.chip}
                      label={data}
                      onDelete={handleChipDelete(data)}
                    />
                  </li>
                );
              })}
            </ul>

            {selected.length > 0 && (
              <>
                <TextField
                  label='New value'
                  placeholder='Set new value'
                  size='small'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={newValue}
                  onChange={handleNewValueChange}
                  className={classes.search}
                />

                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => {
                    handleMergeClick(e);
                  }}
                >
                  Merge values
                </Button>
              </>
            )}
          </Paper>

          <Paper className={classes.paper}>
            <TextField
              label='Search'
              placeholder='Filter values'
              size='small'
              InputLabelProps={{
                shrink: true,
              }}
              value={searchTerm}
              onChange={handleSearchChange}
              className={classes.search}
            />

            <TableContainer>
              <Table className={classes.table} size='small'>
                <TableBody>
                  {filterValues(values)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((value, index) => {
                      const isItemSelected = isSelected(value);
                      const labelId = `checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role='checkbox'
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                        >
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              checked={isItemSelected}
                              onClick={(event) => handleRowClick(event, value)}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component='th'
                            id={labelId}
                            scope='row'
                            padding='none'
                          >
                            {value}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 30, 50, 100]}
              component='div'
              count={filterValues(values).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};
