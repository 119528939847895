import React from 'react';

interface Props {
  label: string;
  value: string | undefined;
  onChange(e: any): void;
}

const FOSBSTextInput = (props: Props) => {
  return (
    <div className='row pb-1'>
      <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
        {props.label}
      </label>
      <div className='col'>
        <input
          type='text'
          className='form-control form-select-sm'
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = prevProps.value === nextProps.value;
  return equal;
}

export default React.memo(FOSBSTextInput, areEqual);
