import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  EditFixtureForm,
  fixtureEditFormState,
  fixtureGlobalIdLookupState,
  fixtureLookupState,
} from '../state/openFixture';
import { ModalHeader } from './ModalHeader';
import FOSBSBoolean from '../common/FOSBSBoolean';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import FOSBSVesselFinder from '../common/FOSBSVesselFinder';
import { MultiSelectOptions } from '../common/form-elements';
import { regions } from '../datas';
import {
  convertDbFixtureToEditFixtureForm,
  convertToFixtureEditPayload,
} from '../mapper/fixture-edit';
import { convertToFixtureCreatePayload } from '../mapper/fixture-create';
import { toast } from 'react-toastify';
import { modalEditorOpen } from '../state/modals';
import { createOptionsFromEnum, shallowFormDiff } from '../util';
import {
  getFixtureLookup,
  updateFixture,
  deleteFixture,
  createFixture,
} from '../services/fosService';
import { CreateFixtureForm } from '../state/createFixture';
import { RequirementTypes } from './CreateFixtureModal';
import { brokerOptions } from './BudgetIndicationModal/brokerList';

interface Props {
  onRequestClose(): void;
}

enum optionsUnitToMomentDuration {
  days = 'days',
  daily = 'days',
  weeks = 'weeks',
  months = 'months',
  monthly = 'months',
  years = 'years',
  yearly = 'years',
}

type optionsUnitStrings = keyof typeof optionsUnitToMomentDuration;

const momentDuration = (key: optionsUnitStrings) => {
  return optionsUnitToMomentDuration[key];
};

const generateFixtureEditState = (oldFixture: any, newFixture: any) => {
  if (oldFixture === undefined || newFixture === undefined) {
    return null;
  }

  const oldPayload = convertToFixtureEditPayload(oldFixture);
  const newPayload = convertToFixtureEditPayload(newFixture);

  const stateChange = shallowFormDiff(oldPayload, newPayload);

  return stateChange;
};

const isTerminateEnabled = (
  delDate: Date | undefined,
  reDelDate: Date | undefined
) => {
  if (!delDate || !reDelDate) {
    return false;
  }

  const deliveryDate = moment(delDate);
  const reDeliveryDate = moment(reDelDate);
  const yesterday = moment().subtract(1, 'days');
  const today = moment();
  return (
    today.isAfter(deliveryDate) &&
    !reDeliveryDate.isSameOrBefore(yesterday, 'day')
  );
};

export const OpenFixtureModal = (props: Props) => {
  const [positionRegions, setPositionRegions] = useState<MultiSelectOptions>(
    []
  );
  const [lookupGlobalId, setLookupGlobalId] = useRecoilState(
    fixtureGlobalIdLookupState
  );
  const [dataLoaded, setDataLoaded] = useState(false);
  const [extendContractMode, setExtendContractMode] = useState(0);

  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [fixture, setFixture] = useRecoilState(fixtureLookupState);
  const [form, setForm] = useRecoilState(fixtureEditFormState);
  const latestFormState = useRef(form);

  const resetFixture = useResetRecoilState(fixtureLookupState);
  const resetForm = useResetRecoilState(fixtureEditFormState);

  let stateChange = generateFixtureEditState(fixture, form);
  const untouched = stateChange === undefined || stateChange === null;

  const terminateEnabled = isTerminateEnabled(
    fixture?.deliveryDate,
    fixture?.reDeliveryDate
  );

  const updateReDeliveryDate = async () => {
    if (
      !!dataLoaded &&
      !!form &&
      form.deliveryDate !== undefined &&
      form.periodAmountUnit !== undefined &&
      form.periodAmountUnit?.value !== undefined &&
      form.periodAmountValue !== undefined
    ) {
      const newValue = moment(form.deliveryDate)
        .add(form.periodAmountValue, form.periodAmountUnit?.value)
        .toDate();

      if (
        form.reDeliveryDate === undefined ||
        !moment(form.reDeliveryDate).isSame(newValue)
      ) {
        await setForm((form: any) => ({ ...form, reDeliveryDate: newValue }));
      }
    }
  };

  const updateOptionsUntil = async () => {
    if (
      !!dataLoaded &&
      !!form &&
      form.optionDurationUnit !== undefined &&
      form.optionDurationUnit?.value !== undefined &&
      form.optionDurationValue !== undefined &&
      form.reDeliveryDate !== undefined
    ) {
      const unit = form.optionDurationUnit?.value as optionsUnitStrings;
      const duration = momentDuration(unit) as any;

      const newValue = moment(form.reDeliveryDate)
        .add(form.optionDurationValue, duration)
        .toDate();

      if (
        form.optionsUntil === undefined ||
        !moment(form.optionsUntil).isSame(newValue)
      ) {
        await setForm((form: any) => ({
          ...form,
          optionsUntil: newValue,
        }));
      }
    }
  };

  const updatePeriodAmount = async () => {
    if (
      !!dataLoaded &&
      !!form &&
      form.deliveryDate !== undefined &&
      form.reDeliveryDate !== undefined
    ) {
      if (form.periodAmountUnit && form.periodAmountValue) {
        const newValueByUnit = moment(form.reDeliveryDate)
          .diff(form.deliveryDate, form.periodAmountUnit.value)
          .toString();

        if (newValueByUnit === form.periodAmountValue) return;
      }

      const newValueByDays = moment(form.reDeliveryDate)
        .diff(form.deliveryDate, 'days')
        .toString();

      await setForm((form: any) => ({
        ...form,
        periodAmountUnit: { label: 'Days', value: 'days' },
        periodAmountValue: newValueByDays,
      }));
    }
  };

  const updateOptionDuration = () => {
    if (
      !!dataLoaded &&
      !!form &&
      form.optionsUntil !== undefined &&
      form.reDeliveryDate !== undefined
    ) {
      if (form.optionDurationUnit && form.optionDurationValue) {
        const unit = form.optionDurationUnit?.value as optionsUnitStrings;
        const duration = momentDuration(unit) as any;

        const newValueByUnit = moment(form.optionsUntil)
          .diff(form.reDeliveryDate, duration)
          .toString();

        if (newValueByUnit === form.optionDurationValue) return;
      }

      const newValueByDays = moment(form.optionsUntil)
        .diff(form.reDeliveryDate, 'days')
        .toString();

      setForm({
        ...form,
        optionDurationUnit: { label: 'Days', value: 'days' },
        optionDurationValue: newValueByDays,
      });
    }
  };

  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  useEffect(() => {
    const loadData = async () => {
      const lookupResp = await getFixtureLookup(lookupGlobalId);
      const fixture = convertDbFixtureToEditFixtureForm(lookupResp[0]);

      setForm(fixture);
      setFixture(fixture);

      // map regions
      setPositionRegions(regions);

      setDataLoaded(true);
    };

    loadData();
  }, [lookupGlobalId]);

  useEffect(() => {
    updateReDeliveryDate();
  }, [form?.deliveryDate]);

  useEffect(() => {
    updateReDeliveryDate();
  }, [form?.periodAmountUnit, form?.periodAmountValue]);

  useEffect(() => {
    updateOptionsUntil();
    updatePeriodAmount();
  }, [form?.reDeliveryDate]);

  useEffect(() => {
    updateOptionsUntil();
  }, [form?.optionDurationUnit, form?.optionDurationValue]);

  useEffect(() => {
    updateOptionDuration();
  }, [form?.optionsUntil]);

  const setFormStateElement = (field: string, value: any) => {
    const latest = latestFormState.current as EditFixtureForm;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
    latestFormState.current = newForm;
  };

  const doUpdate = async () => {
    stateChange = generateFixtureEditState(fixture, latestFormState.current);
    if (stateChange === undefined) return;

    await updateFixture(lookupGlobalId, stateChange);

    toast.success('Fixture has been updated');
    resetForm();
    resetFixture();
    setModalOpen(false);
  };

  const doTerminate = async () => {
    const yesterday = moment().subtract(1, 'days').toDate();
    setFormStateElement('reDeliveryDate', yesterday);
    await doUpdate();
  };

  const doDelete = async () => {
    const r = window.confirm('Are you sure you want to delete fixture?');
    if (!r) return;

    const response = await deleteFixture(lookupGlobalId);

    if (response.status) {
      toast.success('Fixture has been deleted');
      resetForm();
      resetFixture();
      setModalOpen(false);
    } else {
      toast.error(response.message);
    }
  };

  // New extended contract fixture should be private
  const enterExtendContractMode = async () => {
    setExtendContractMode(1);
    setFormStateElement('privateAndConfidential', '1');
  };

  const doCreate = async () => {
    try {
      const finalRequestBody = convertToFixtureCreatePayload(
        latestFormState.current as CreateFixtureForm
      );

      // global_id should be empty as we create new fixture from existing
      finalRequestBody.global_id = '';
      const createResponse = await createFixture(finalRequestBody);

      toast.success('Fixture has been created');
      resetForm();
      resetFixture();
      setModalOpen(false);
    } catch (e) {
      toast.error('Error creating fixture');
    }
  };

  if (form === undefined) return <div></div>;

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={extendContractMode ? `Extend Contract` : `Edit Fixture`}
      />
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Vessel Name
            </label>
            <div className='col'>
              <FOSBSVesselFinder
                key={extendContractMode}
                name={'vessel_name'}
                isDisabled={extendContractMode}
                stateSetter={(name, value) => {
                  setFormStateElement('vesselDetails', value);
                }}
                value={form.vesselDetails}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Connected Imo
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {form.imo}
              </label>
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Requirement Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='requirementType'
                value={form.requirementType}
                options={createOptionsFromEnum(RequirementTypes)}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Subtype
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='requirementSubType'
                value={form.requirementSubType}
                options={[{ label: 'SUBSEA', value: 'SUBSEA' }]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSDatePicker
            label={'Fixture Date'}
            value={form.fixtureDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'fixtureDate'}
          />

          <FOSBSDatePicker
            label={'Delivery Date'}
            value={form.deliveryDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'deliveryDate'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Delivery Port
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='delivery_port'
                dbTableName='fixtures'
                name='deliveryPort'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.deliveryPort}
                isCreatable={false}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Region
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='region'
                value={form.region}
                options={positionRegions}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Period Amount'}
            value={form.periodAmountValue}
            onChange={(e) => {
              setFormStateElement('periodAmountValue', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Period Amount Unit
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='periodAmountUnit'
                value={form.periodAmountUnit}
                options={[
                  { label: 'Days', value: 'days' },
                  { label: 'Weeks', value: 'weeks' },
                  { label: 'Months', value: 'months' },
                  { label: 'Years', value: 'years' },
                  { label: 'Wells', value: 'wells' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Option Duration'}
            value={form.optionDurationValue}
            onChange={(e) => {
              setFormStateElement('optionDurationValue', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Option Duration Unit
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='optionDurationUnit'
                value={form.optionDurationUnit}
                options={[
                  { label: 'Days', value: 'days' },
                  { label: 'Daily', value: 'daily' },
                  { label: 'Weeks', value: 'weeks' },
                  { label: 'Months', value: 'months' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Years', value: 'years' },
                  { label: 'Yearly', value: 'yearly' },
                  { label: 'Wells', value: 'wells' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextAreaInput
            label={'Workscope'}
            value={form.workScope}
            onChange={(e) => {
              setFormStateElement('workScope', e.target.value);
            }}
          />

          <div style={{ paddingTop: '1rem' }}>
            <FOSBSTextAreaInput
              label={'Comments'}
              value={form.comments}
              onChange={(e) => {
                setFormStateElement('comments', e.target.value);
              }}
            />
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Details
            </h5>
          </div>

          <FOSBSDatePicker
            label={'Redelivery Date'}
            value={form.reDeliveryDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'reDeliveryDate'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Redelivery Port
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='delivery_port'
                dbTableName='fixtures'
                name='reDeliveryPort'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.reDeliveryPort}
                isCreatable={false}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSDatePicker
            label={'Options Until'}
            value={form.optionsUntil}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'optionsUntil'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Charterer
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='charterer'
                dbTableName='fixtures'
                name='charterer'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.charterer}
                isCreatable={true}
                key={extendContractMode}
                isDisabled={extendContractMode}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Source'}
            value={form.source}
            onChange={(e) => {
              setFormStateElement('source', e.target.value);
            }}
          />

          <FOSBSTextInput
            label={'Rate'}
            value={form.rate}
            onChange={(e) => {
              setFormStateElement('rate', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Rate Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='rateCurrency'
                value={form.rateCurrency}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'DKK', value: 'DKK' },
                  { label: 'NOK', value: 'NOK' },
                  { label: 'GBP', value: 'GBP' },
                  { label: 'EUR', value: 'EUR' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Rate Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='rateType'
                value={form.rateType}
                options={[
                  { label: 'Per Day', value: 'perday' },
                  { label: 'Lumpsum', value: 'lumpsum' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSBoolean
            label={'Show rate as RNR'}
            name={'showRateRNR'}
            value={form.showRateRNR}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />

          <FOSBSTextInput
            label={'Option Rate'}
            value={form.optionRate}
            onChange={(e) => {
              setFormStateElement('optionRate', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Option Rate Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='optionRateCurrency'
                value={form.optionRateCurrency}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'DKK', value: 'DKK' },
                  { label: 'NOK', value: 'NOK' },
                  { label: 'GBP', value: 'GBP' },
                  { label: 'EUR', value: 'EUR' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Option Rate Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='optionRateType'
                value={form.optionRateType}
                options={[
                  { label: 'Per Day', value: 'day' },
                  { label: 'Lumpsum', value: 'lumpsum' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Mobilisation Fee Amount'}
            value={form.mobilisationFeeAmount}
            onChange={(e) => {
              setFormStateElement('mobilisationFeeAmount', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Mobilisation Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='mobilisationFeeAmountCurrency'
                value={form.mobilisationFeeAmountCurrency}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'DKK', value: 'DKK' },
                  { label: 'NOK', value: 'NOK' },
                  { label: 'GBP', value: 'GBP' },
                  { label: 'EUR', value: 'EUR' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Demob fee'}
            value={form.demobFeeAmount}
            onChange={(e) => {
              setFormStateElement('demobFeeAmount', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Demob fee currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='demobFeeAmountCurrency'
                value={form.demobFeeAmountCurrency}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'DKK', value: 'DKK' },
                  { label: 'NOK', value: 'NOK' },
                  { label: 'GBP', value: 'GBP' },
                  { label: 'EUR', value: 'EUR' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSBoolean
            key={extendContractMode}
            label={'P&C'}
            name={'privateAndConfidential'}
            value={form.privateAndConfidential}
            isDisabled={extendContractMode}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />

          <FOSBSBoolean
            label={'Own'}
            name={'own'}
            value={form.own}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Broker
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='broker'
                value={form.broker}
                options={brokerOptions}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Updated
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {moment(form.updated).format('DD.MM.yyyy HH:mm:ss')}
              </label>
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Updated by
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {form.updatedBy}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='btn btn-success btn'
            disabled={untouched}
            onClick={() => {
              extendContractMode ? doCreate() : doUpdate();
            }}
          >
            {extendContractMode ? 'Save' : 'Update'}
          </button>
        </div>
        {!extendContractMode && (
          <div className='col-sm-6' style={{ textAlign: 'right' }}>
            <hr />
            <button
              type='button'
              className='btn btn-primary btn'
              style={{ marginRight: 10 }}
              onClick={() => {
                enterExtendContractMode();
              }}
            >
              Extend Contract
            </button>

            <button
              type='button'
              className='btn btn-danger btn'
              style={{ marginRight: 10 }}
              onClick={() => {
                doDelete();
              }}
            >
              Delete fixture
            </button>

            <button
              type='button'
              className='btn btn-danger btn'
              disabled={!terminateEnabled}
              onClick={() => {
                doTerminate();
              }}
            >
              Terminate fixture
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
