import React, { useState } from 'react';
import { MainThemeBootstrap } from '../Theme/MainThemeBootstrap';
import { MainThemeSideBar } from '../Theme/MainThemeSideBar';
import { Fixtures } from '../Home/Fixtures';
import { OutstandingRequirements } from '../Home/OutstandingRequirements';
import { AvailabilityNorway } from '../Home/AvailabilityNorway';
import { AvailabilityUK } from '../Home/AvailabilityUK';
import { LayUpList } from '../Home/LayUpList';
import { FOSBSVesselSearchUnauthenticated } from '../common/FOSBSVesselSearchUnauthenticated';

export const Home = () => {
  return (
    <div>
      <MainThemeBootstrap isHome={true}>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <FOSBSVesselSearchUnauthenticated></FOSBSVesselSearchUnauthenticated>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-10 main-column'>
              <Fixtures></Fixtures>
              <br></br>

              <OutstandingRequirements />
              <br></br>

              <AvailabilityNorway />
              <br></br>

              <AvailabilityUK />
              <br></br>

              <LayUpList />
            </div>
            <div className='col-2 sidebar-column'>
              <MainThemeSideBar></MainThemeSideBar>
            </div>
          </div>
        </div>
      </MainThemeBootstrap>
    </div>
  );
};
