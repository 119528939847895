import React from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { activeModalType, ModalType } from '../state/modals';
import { fixtureGlobalIdLookupState } from '../state/openFixture';
import { modalEditorOpen } from '../state/modals';
import { getAllUnauthenticatedRequirements } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { Regions } from '../common/types';

enum Tab {
  Spot = 'spot',
  Term = 'term',
}

export const OutstandingRequirements = (props: any) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tab, setTab] = useState<Tab>(Tab.Spot);
  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'updated', desc: true });

  const setFixtureGlobalId = useSetRecoilState(fixtureGlobalIdLookupState);
  const [moodalOpen, setModalOpen] = useRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);

  const openRequirement = (fixtureGlobalId: string) => {
    setFixtureGlobalId(fixtureGlobalId);
    setModalOpen(true);
    setModalType(ModalType.OpenRequirement);
  };

  useEffect(() => {
    getAllUnauthenticatedRequirements(page, perPage, sortBy, tab).then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [tab, page, perPage, sortBy, moodalOpen]);

  const resetPage = () => {
    setPage(1);
  };

  useEffect(() => {
    resetPage();
  }, [tab]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Created',
        accessor: 'fixture_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Charterer',
        accessor: 'charterer',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>Outstanding Requirements</div>

      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <a
            className={tab === Tab.Spot ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.Spot);
            }}
            aria-current='page'
            href='#1'
          >
            Spot
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={tab === Tab.Term ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.Term);
            }}
            href='#2'
          >
            Term
          </a>
        </li>
      </ul>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={data}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
