import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  getSpotUtilization,
  getOilPrices,
  getExchangeRates,
} from '../services/fosService';

const SpotUtilization = () => {
  const location = useLocation();
  const [spotUtil, setSpotUtil] = useState({
    ahts: 0,
    psv: 0,
  });

  const active =
    location.pathname === '/north-sea-spot' || location.pathname === '/';

  useEffect(() => {
    if (active) {
      getSpotUtilization().then(
        (res) => {
          setSpotUtil(res);
        },
        (reason) => {}
      );
    }
  }, [active]);

  return (
    <>
      {' '}
      {active && (
        <div>
          <div className='card'>
            <div className='card-body'>
              <p className='card-title'>
                <b>Spot Utilization</b>
              </p>

              <table className='table table-striped'>
                <tbody>
                  <tr>
                    <td>AHTS {spotUtil.ahts}%</td>
                    <td>PSV {spotUtil.psv}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br></br>
        </div>
      )}
    </>
  );
};

const DutyBroker = () => {
  return (
    <div className='card'>
      <div className='card-body'>
        <p className='card-title'>
          <b>Duty Broker</b>
        </p>

        <p>
          Direct: <strong>+47 22 93 64 00</strong>
          <br></br>
          <a
            style={{ color: '#0d6efd' }}
            href='mailto: supply.offshore@fearnleys.no'
          >
            supply.offshore@fearnleys.no
          </a>
        </p>
      </div>
    </div>
  );
};

const KeyFigures = () => {
  const [currencies, setCurrencies] = useState<{
    date: string | null;
    usd: any;
    gbp: any;
    eur: any;
  }>({
    date: null,
    usd: {
      value: 0,
      change: '',
    },
    gbp: {
      value: 0,
      change: '',
    },
    eur: {
      value: 0,
      change: '',
    },
  });

  const [oilPrice, setOilPrice] = useState({
    value: 0,
    change: '-',
  });

  const getCurrenciesEchangeRates = async () => {
    const USDToNOK = await getExchangeRates(
      'USD',
      'NOK',
      new Date().toISOString(),
      1
    );
    const GBPToNOK = await getExchangeRates(
      'GBP',
      'NOK',
      new Date().toISOString(),
      1
    );
    const EURToNOK = await getExchangeRates(
      'EUR',
      'NOK',
      new Date().toISOString(),
      1
    );

    const updatedCurrencies = {
      date: new Date(USDToNOK.date).toISOString(),
      usd: {
        value: USDToNOK.rate,
        change: USDToNOK.change,
      },
      gbp: {
        value: GBPToNOK.rate,
        change: GBPToNOK.change,
      },
      eur: {
        value: EURToNOK.rate,
        change: EURToNOK.change,
      },
    };
    setCurrencies(updatedCurrencies);
  };

  useEffect(() => {
    getCurrenciesEchangeRates();
  }, []);

  useEffect(() => {
    getOilPrices().then(
      (res) => {
        setOilPrice(res);
      },
      (reason) => {}
    );
  }, []);

  return (
    <div className='card'>
      <div className='card-body'>
        <p className='card-title'>
          <b>Key Figures</b>
        </p>

        <table className='table table-striped'>
          <thead>
            <tr>
              <th scope='col'>Currency</th>
              <th scope='col'>Value</th>
              <th scope='col'>Change</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>USD/NOK</td>
              <td>{currencies.usd.value}</td>
              <td>{currencies.usd.change}</td>
            </tr>
            <tr>
              <td>GBP/NOK</td>
              <td>{currencies.gbp.value}</td>
              <td>{currencies.gbp.change}</td>
            </tr>
            <tr>
              <td>EUR/NOK</td>
              <td>{currencies.eur.value}</td>
              <td>{currencies.eur.change}</td>
            </tr>
          </tbody>
        </table>
        <p className='text-small'>
          Source:
          <a
            style={{ color: '#0d6efd', margin: '0 5px 0 5px' }}
            href='https://app.norges-bank.no/query/#/en/'
          >
            Norges Bank
          </a>
          <div style={{ marginTop: '5px' }}>
            {moment(currencies.date).format('DD.MM.yyyy')}
          </div>
        </p>
        <br></br>
        <table className='table table-striped'>
          <thead>
            <tr>
              <th scope='col'>Oil Type</th>
              <th scope='col'>Value</th>
              <th scope='col'>Change</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Brent Crude Oil</td>
              <td>{oilPrice.value}</td>
              <td>{oilPrice.change}</td>
            </tr>
          </tbody>
        </table>
        <p className='text-small'>
          Source:
          <a
            style={{ color: '#0d6efd', margin: '0 5px 0 5px' }}
            href='http://oil-price.net/'
          >
            Oil-price.net
          </a>
        </p>
      </div>
    </div>
  );
};

export const MainThemeSideBar = () => {
  return (
    <>
      <DutyBroker />
      <br></br>

      <SpotUtilization />

      <KeyFigures />
    </>
  );
};
