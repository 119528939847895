import { useState, useRef, useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { activeModalType, ModalType } from '../state/modals';
import { ModalHeader } from './ModalHeader';
import { MultiSelectOptions } from '../common/form-elements';
import { regions } from '../datas';
import { createFixtureForm } from '../state/createFixture';
import { toast } from 'react-toastify';
import { convertToFixtureCreatePayload } from '../mapper/fixture-create';
import { createRequirement } from '../services/fosService';
import { modalEditorOpen } from '../state/modals';

import FOSBSBoolean from '../common/FOSBSBoolean';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import { RequirementTypes } from './CreateFixtureModal';

interface Props {
  onRequestClose(): void;
}

export const CreateRequirementModal = (props: Props) => {
  const [positionRegions, setPositionRegions] = useState<MultiSelectOptions>(
    []
  );
  const [form, setForm] = useRecoilState(createFixtureForm);

  const latestFormState = useRef(form);

  const setModalType = useSetRecoilState(activeModalType);
  const setModalOpen = useSetRecoilState(modalEditorOpen);
  const resetForm = useResetRecoilState(createFixtureForm);

  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  useEffect(() => {
    const loadData = async () => {
      // map regions
      setPositionRegions(regions);
    };

    loadData();
  }, []);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
  };

  const doCreate = async () => {
    const finalRequestBody = convertToFixtureCreatePayload(
      latestFormState.current
    );
    try {
      await createRequirement(finalRequestBody);
      toast.success('Fixture has been created');
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    }

    resetForm();
  };

  const doCreateFixture = async () => {
    setModalType(ModalType.CreateFixture);
  };

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={`Create new requirement`}
      />
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Requirement Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='requirementType'
                value={form.requirementType}
                options={[
                  { label: 'Term', value: RequirementTypes.TERM },
                  { label: 'Spot', value: RequirementTypes.SPOT },
                  { label: 'Bareboat', value: RequirementTypes.BAREBOAT },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Subtype
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='requirementSubType'
                value={form.requirementSubType}
                options={[{ label: 'SUBSEA', value: 'SUBSEA' }]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSDatePicker
            label={'Delivery Date'}
            value={form.deliveryDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'deliveryDate'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Delivery Port
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='delivery_port'
                dbTableName='fixtures'
                name='deliveryPort'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.deliveryPort}
                isCreatable={false}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Region
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='region'
                value={form.region}
                options={positionRegions}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Period Amount'}
            value={form.periodAmountValue}
            onChange={(e) => {
              setFormStateElement('periodAmountValue', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Period Amount Unit
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='periodAmountUnit'
                value={form.periodAmountUnit}
                options={[
                  { label: 'Days', value: 'days' },
                  { label: 'Weeks', value: 'weeks' },
                  { label: 'Months', value: 'months' },
                  { label: 'Years', value: 'years' },
                  { label: 'Wells', value: 'wells' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Option Duration'}
            value={form.optionDurationValue}
            onChange={(e) => {
              setFormStateElement('optionDurationValue', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Option Duration Unit
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='optionDurationUnit'
                value={form.optionDurationUnit}
                options={[
                  { label: 'Days', value: 'days' },
                  { label: 'Daily', value: 'daily' },
                  { label: 'Weeks', value: 'weeks' },
                  { label: 'Months', value: 'months' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'Years', value: 'years' },
                  { label: 'Yearly', value: 'yearly' },
                  { label: 'Wells', value: 'wells' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextAreaInput
            label={'Workscope'}
            value={form.workScope}
            onChange={(e) => {
              setFormStateElement('workScope', e.target.value);
            }}
          />

          <FOSBSTextAreaInput
            label={'Comments'}
            value={form.comments}
            onChange={(e) => {
              setFormStateElement('comments', e.target.value);
            }}
          />
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Details
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Charterer
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='charterer'
                dbTableName='fixtures'
                name='charterer'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.charterer}
                isCreatable={true}
              />
            </div>
          </div>

          <FOSBSBoolean
            label={'P&C'}
            name={'privateAndConfidential'}
            value={form.privateAndConfidential}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12' style={{ textAlign: 'center' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='btn btn-success btn'
            onClick={() => {
              doCreate();
            }}
          >
            Create
          </button>

          <button
            type='button'
            style={{ marginLeft: 10 }}
            className='btn btn-warning btn'
            onClick={() => {
              doCreateFixture();
            }}
          >
            Create fixture
          </button>
        </div>
      </div>
    </div>
  );
};
