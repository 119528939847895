import { Link } from 'react-router-dom';
import { Switch, Route, useRouteMatch } from 'react-router';
import { FieldSelector } from './FieldSelector';

const tables = ['vessels', 'fixtures', 'sales', 'valuations'];

export const TableSelector = () => {
  let { path, url } = useRouteMatch();

  const RouteComponents = tables.map((table) => (
    <Route path={`${path}/:table`} key={table}>
      <FieldSelector />
    </Route>
  ));

  return (
    <Switch>
      <Route exact path={path}>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <div className='north-sea-spot-caption'>
                Choose table to clean
              </div>

              <ul>
                {tables.map((table) => (
                  <li key={table}>
                    <Link
                      to={`${url}/${table}`}
                      className='link-primary'
                      aria-current='page'
                    >
                      {table}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Route>

      {RouteComponents}
    </Switch>
  );
};
