import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { saleGlobalId } from '../state/openSale';
import { ModalHeader } from './ModalHeader';
import { EditRecentSaleForm } from '../Forms/EditRecentSaleForm';
import { FileUploader } from '../common/FileUploader';

enum Tab {
  Specification,
  Attachments,
}

export const OpenRecentSaleModal = (props: any) => {
  const [tab, setTab] = useState<Tab>(Tab.Specification);
  const [saleId, setSaleGlobalId] = useRecoilState(saleGlobalId);

  if (!saleId) return <div></div>;

  return (
    <div>
      <ModalHeader onRequestClose={props.onRequestClose} header={`Edit Sale`} />

      <div className='row'>
        <ul className='nav nav-tabs'>
          <li className='nav-item'>
            <a
              className={
                tab === Tab.Specification ? 'nav-link active' : 'nav-link'
              }
              onClick={(e) => {
                setTab(Tab.Specification);
              }}
              href='#'
            >
              Specification
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={
                tab === Tab.Attachments ? 'nav-link active' : 'nav-link'
              }
              onClick={(e) => {
                setTab(Tab.Attachments);
              }}
              href='#'
            >
              Attachments
            </a>
          </li>
        </ul>
      </div>

      {tab === Tab.Specification && (
        <EditRecentSaleForm onRequestClose={props.onRequestClose} />
      )}
      {tab === Tab.Attachments && (
        <FileUploader
          entityId={saleId}
          entityTag={'snp'}
          onRequestClose={props.onRequestClose}
        />
      )}
    </div>
  );
};
