import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CurrencyResponse, getRatesAll } from '../api/currency';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSRange from '../common/FOSBSRange';
import FOSBSTextInput from '../common/FOSBSTextInput';
import FOSBSVesselFinder from '../common/FOSBSVesselFinder';
import { getAPIPath } from '../core';
import { currencies } from '../datas';
import {
  createValuationForm,
  CreateValuationForm,
} from '../state/createValuation';
import { MultiSelectOption, MultiSelectOptions } from '../state/searchVessel';
import { convertToValuationCreatePayload } from '../mapper/valuation-create';
import { toast } from 'react-toastify';
import { modalEditorOpen } from '../state/modals';
import { ModalHeader } from './ModalHeader';

interface Props {
  onRequestClose(): void;
}

export const CreateValuationModal = (props: Props) => {
  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [currencyRates, setCurrencyRates] = useState<CurrencyResponse[]>([]);
  const [usdPriceCalculatedInfo, setUsdPriceCalculatedInfo] = useState({
    calculated: false,
    interestRateDate: '',
    reason: '',
  });

  const [form, setForm] = useRecoilState(createValuationForm);
  const resetForm = useResetRecoilState(createValuationForm);

  const latestFormState = useRef(form);
  const latestCurrencyRates = useRef(currencyRates);

  // On var update (ref updating)
  useEffect(() => {
    latestFormState.current = form;
    latestCurrencyRates.current = currencyRates;
  }, [form, currencyRates]);

  const [currenciesMapped, setCurrenciesMapped] = useState<MultiSelectOptions>(
    []
  );

  // On mount!
  useEffect(() => {
    const loadData = async () => {
      // fetch interest rates
      const currencyRatesResp = await getRatesAll(form.effectiveDate);
      // console.log(currencyRates);
      setCurrencyRates(currencyRatesResp);
    };

    loadData();

    // map regions
    setCurrenciesMapped(
      currencies.map((el) => {
        return {
          label: el,
          value: el,
        };
      })
    );
  }, []);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current;

    const newForm = {
      ...latest,
      [field]: value,
    };

    // call setter func and update its vals
    setForm(newForm);
    // We need to update the ref/current because the updater might be called directly in updatePriceUSD method (staling the data)
    latestFormState.current = newForm;

    // if changed field is effectiveDate and priceLocal is set, recalculate priceUSD
    if (newForm.effectiveDate !== undefined && field === 'effectiveDate') {
      // currency must be set for this to make sense

      // fetch interest rates
      const currencyRatesResp = await getRatesAll(newForm.effectiveDate);
      // console.log(currencyRates);
      setCurrencyRates(currencyRatesResp);
      updatePriceUSD(newForm, currencyRatesResp);
    }

    if (['priceLocal', 'currency'].includes(field)) {
      console.log('included', field);
      updatePriceUSD(newForm, latestCurrencyRates.current);
    }
  };

  const updatePriceUSD = (
    newForm: CreateValuationForm,
    currencyRates: CurrencyResponse[]
  ) => {
    if (newForm.currency === undefined) return;
    const userInputCurrency = newForm.currency.value as string;

    // If USD, skip calculation
    if (userInputCurrency.toLowerCase() === 'usd') {
      setFormStateElement('priceUSD', {
        from: Number(newForm.priceLocal.from) * 1000000,
        to: Number(newForm.priceLocal.to) * 1000000,
      }); // reset it
      setUsdPriceCalculatedInfo({
        calculated: true,
        interestRateDate: '',
        reason: 'USD->USD',
      });
      return;
    }

    // Calculation part
    const resetWithMsg = (reason: string) => {
      setFormStateElement('priceUSD', { from: '', to: '' }); // reset it
      setUsdPriceCalculatedInfo({
        calculated: false,
        interestRateDate: '',
        reason,
      });
    };

    // reset if anything fails or is unavailable
    if (currencyRates.length == 0) {
      resetWithMsg('');
      return;
    }

    const usdNOK = currencyRates.find((el) => {
      return el.currency.toLowerCase() === 'usd';
    });
    if (usdNOK === undefined) {
      resetWithMsg('Missing USD');
      return;
    }

    // If NOK is used, do simple direct calculation
    if (userInputCurrency.toLowerCase() === 'nok') {
      // Currency is found!
      const newUSDPrice = {
        from: '',
        to: '',
      };

      // Check from:
      if (
        newForm.priceLocal.from !== '' &&
        !isNaN(Number(newForm.priceLocal.from))
      ) {
        newUSDPrice.from = (
          (Number(newForm.priceLocal.from) / usdNOK.rate) *
          1000000
        ).toFixed(2);
      }

      // Check to:
      if (
        newForm.priceLocal.to !== '' &&
        !isNaN(Number(newForm.priceLocal.to))
      ) {
        newUSDPrice.to = (
          (Number(newForm.priceLocal.to) / usdNOK.rate) *
          1000000
        ).toFixed(2);
      }

      // Finally update vals!
      setFormStateElement('priceUSD', newUSDPrice);
      setUsdPriceCalculatedInfo({
        calculated: true,
        interestRateDate: usdNOK.rate_date,
        reason: '',
      });
      return;
    }

    // Not NOK -- Do INDIRECT calculation via USDNOK

    const findCurrencyRate = currencyRates.find((el) => {
      return el.currency.toLowerCase() === userInputCurrency.toLowerCase(); // TODO: NOK->USD only supported. //form.currency?.value.toLowerCase();
    });

    if (findCurrencyRate === undefined) {
      setFormStateElement('priceUSD', { from: '', to: '' }); // reset it
      setUsdPriceCalculatedInfo({
        calculated: false,
        interestRateDate: '',
        reason: `Found no currency rate for ${userInputCurrency}`,
      });
      return;
    }

    // do indirect conversion to usd!
    const indirectRate = Number(usdNOK.rate) / Number(findCurrencyRate.rate);

    // Currency is found!
    const newUSDPrice = {
      from: '',
      to: '',
    };

    // Check from:
    if (
      newForm.priceLocal.from !== '' &&
      !isNaN(Number(newForm.priceLocal.from))
    ) {
      newUSDPrice.from = (
        (Number(newForm.priceLocal.from) / indirectRate) *
        1000000
      ).toFixed(2);
    }

    // Check to:
    if (newForm.priceLocal.to !== '' && !isNaN(Number(newForm.priceLocal.to))) {
      newUSDPrice.to = (
        (Number(newForm.priceLocal.to) / indirectRate) *
        1000000
      ).toFixed(2);
    }

    // Finally update vals!
    setFormStateElement('priceUSD', newUSDPrice);
    setUsdPriceCalculatedInfo({
      calculated: true,
      interestRateDate: findCurrencyRate.rate_date,
      reason: `Calculated ${userInputCurrency}/USD rate (${(1 / indirectRate).toFixed(2)}) indirectly via ${userInputCurrency}/NOK -> NOK/USD`,
    });
    return;
  };

  const doCreate = async () => {
    const finalRequestBody = convertToValuationCreatePayload(
      latestFormState.current
    );
    const res = await fetch(getAPIPath() + `/api/valuation`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'content-type': 'Application/JSON',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(finalRequestBody),
    });

    if (res.status !== 200) {
      const createResponse = await res.json();
      toast.error(createResponse.message);
    } else {
      toast.success('Valuation has been created');
      resetForm();
      setModalOpen(false);
    }
  };

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={'Create Valuation'}
      />
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          {/* <FOSBSTextInput label={'Vessel Name'} value={generalFormState.vesselName} onChange={(e) => { setFormStateElement(FormPart.General, 'vesselName', e.target.value) }} /> */}

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Vessel Name
            </label>
            <div className='col'>
              <FOSBSVesselFinder
                name={'vessel_name'}
                stateSetter={(name, value) => {
                  setFormStateElement('vesselDetails', value);
                }}
                value={form.vesselDetails}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Connected Imo
            </label>
            <div className='col'>
              {form.vesselDetails !== undefined && (
                <>
                  {form.vesselDetails.value.imo === '' && (
                    <p>No vessel selected</p>
                  )}
                  {form.vesselDetails.value.imo !== '' && (
                    <p>{form.vesselDetails.value.imo}</p>
                  )}
                </>
              )}
            </div>
          </div>

          <FOSBSDatePicker
            label={'Effective Date'}
            value={form.effectiveDate}
            stateSetter={(name, value) => {
              setFormStateElement('effectiveDate', value);
            }}
            name={'effectiveDate'}
          />

          <FOSBSTextInput
            label={'Source'}
            value={form.source}
            onChange={(e) => {
              setFormStateElement('source', e.target.value);
              // setFormStateElement(FormPart.General, 'imoNumber', e.target.value)
            }}
          />

          <FOSBSRange
            label='Price Local (lo-hi)'
            name='priceLocal'
            stateSetter={(name: string, value: string) => {
              setFormStateElement('priceLocal', value);
            }}
            value={form.priceLocal}
          />

          <p>Local price showed in millions</p>

          <div className='row pb-1' style={{ marginTop: 5 }}>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='currency'
                value={form.currency}
                options={currenciesMapped}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement('currency', value);
                  // setFormStateElement(FormPart.Positions, name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSRange
            label='Price USD (lo-hi)'
            name='priceUSD'
            stateSetter={(name: string, value: any) => {
              setFormStateElement('priceUSD', value);
            }}
            value={form.priceUSD}
          />

          {usdPriceCalculatedInfo.calculated && (
            <>
              <strong>Info:</strong> {usdPriceCalculatedInfo.reason}
              <br />
              {usdPriceCalculatedInfo.interestRateDate !== '' && (
                <>
                  <strong>Using rates from:</strong>{' '}
                  {new Date(
                    usdPriceCalculatedInfo.interestRateDate
                  ).toLocaleDateString()}
                </>
              )}
            </>
          )}
          {!usdPriceCalculatedInfo.calculated &&
            usdPriceCalculatedInfo.reason !== '' && (
              <>
                <strong>INFO:</strong> {usdPriceCalculatedInfo.reason}
              </>
            )}

          <FOSBSTextInput
            label={'Client'}
            value={form.client}
            onChange={(e) => {
              setFormStateElement('client', e.target.value);
            }}
          />

          <FOSBSTextInput
            label={'Issued to'}
            value={form.issuedTo}
            onChange={(e) => {
              setFormStateElement('issuedTo', e.target.value);
            }}
          />
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Comments
            </h5>
          </div>

          <div className='row pb-1'>
            <div className='mb-3'>
              <label
                htmlFor='exampleFormControlTextarea1'
                className='form-label'
              >
                Comments
              </label>
              <textarea
                className='form-control'
                id='exampleFormControlTextarea1'
                rows={3}
                value={form.comments}
                onChange={(e) => {
                  setFormStateElement('comments', e.target.value);
                }}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12' style={{ textAlign: 'center' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-success btn'
            onClick={() => {
              doCreate();
            }}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};
