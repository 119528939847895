export const FOSTablePeriodCell = (props: any) => {
  const { value, periodUnits } = props;

  if (!value || !periodUnits) return <div></div>;

  return (
    <div>
      {value} {periodUnits}
    </div>
  );
};
