import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NumericFormat } from 'react-number-format';
import { activeModalType, ModalType } from '../../state/modals';
import { valuationGlobalId } from '../../state/openValuation';
import { vesselLookupState, EditVesselForm } from '../../state/openVessel';
import { FOSTable } from '../../common/table/FOSTable';
import { FOSTableDateCell } from '../../common/table/FOSTableDateCell';
import { FOSTableTooltipCell } from '../../common/table/FOSTableTooltipCell';
import {
  getExchangeRates,
  getLatestValuationsByImo,
} from '../../services/fosService';
import { ValuationCellInUSD } from '../../Search/Valuation/ValutionCellInUSD';

interface Props {
  setFormStateElement(field: string, value: any): void;
  form: EditVesselForm | undefined;
}

export const VesselLatestValuations = (props: Props) => {
  const { form, setFormStateElement } = props;

  const dbVessel = useRecoilValue(vesselLookupState);
  const setModalType = useSetRecoilState(activeModalType);
  const setValuationGlobalId = useSetRecoilState(valuationGlobalId);

  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'effective_date', desc: true });

  const openValuation = (valuationGlobalId: string) => {
    setValuationGlobalId(valuationGlobalId);
    setModalType(ModalType.OpenValuation);
  };

  useEffect(() => {
    if (form === undefined || dbVessel === undefined) return;

    getLatestValuationsByImo(dbVessel.imo, page, perPage, sortBy).then(
      (res) => {
        setData(res.data);
        setTotalPages(res.pagination.lastPage);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, [form, page, perPage, sortBy]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Effective Date',
        accessor: 'effective_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
      },
      {
        Header: 'Vessel Design',
        accessor: 'design',
      },
      {
        Header: 'Price Range Local',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {row.original.currency}
            <span style={{ paddingLeft: '5px' }}>
              <NumericFormat
                value={row.original.price_local_from}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
              -
              <NumericFormat
                value={row.original.price_local_to}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
            </span>
          </div>
        ),
      },
      {
        Header: 'Price Range USD',
        disableSortBy: true,
        Cell: ValuationCellInUSD,
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Client',
        accessor: 'client',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openValuation(original.row.original.id);
                console.log('opening', original.row.original.id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <FOSTable
      columns={columns}
      data={data}
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={page}
      perPage={perPage}
      totalPage={totalPages}
      initialSortBy={sortBy}
      onChangeSort={setSortBy}
    ></FOSTable>
  );
};
