import React, { useState } from 'react';
import { MainThemeBootstrap } from '../Theme/MainThemeBootstrap';
import { MainThemeSideBar } from '../Theme/MainThemeSideBar';
import { VesselsUnderConstruction } from '../NewBuilding/VesselsUnderConstruction';
import { RecentlyDeliveredUnits } from '../NewBuilding/RecentlyDeliveredUnits';
import { FOSBSVesselSearch } from '../common/FOSBSVesselSearch';
import { NavLink } from 'react-router-dom';

enum Tab {
  All = 'All',
  China = 'China',
  Japan = 'Japan',
  Korea = 'Korea',
  Vietnam = 'Vietnam',
  Malaysia = 'Malaysia',
  Singapore = 'Singapore',
  Indonesia = 'Indonesia',
  Turkey = 'Turkey',
  Poland = 'Poland',
  Spain = 'Spain',
  Norway = 'Norway',
  USA = 'USA',
  Brazil = 'Brazil',
}

export const NewBuilding = () => {
  const [tab, setTab] = useState<Tab>(Tab.All);

  return (
    <div>
      <MainThemeBootstrap>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <FOSBSVesselSearch></FOSBSVesselSearch>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-10 main-column'>
              <ul className='nav nav-tabs' style={{ marginBottom: '20px' }}>
                {Object.keys(Tab).map((key, idx) => {
                  return (
                    <li className='nav-item'>
                      <NavLink
                        to={{
                          pathname: '/new-building',
                          search: `country=${key}`,
                        }}
                        className='nav-link'
                        isActive={(_, location) => {
                          if (location.search === `?country=${key}`) {
                            setTab(key as Tab);
                            return true;
                          }

                          return false;
                        }}
                      >
                        {(Tab as any)[key]}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>

              <VesselsUnderConstruction tab={tab}></VesselsUnderConstruction>
              <br></br>

              <RecentlyDeliveredUnits tab={tab}></RecentlyDeliveredUnits>
            </div>

            <div className='col-2 sidebar-column'>
              <MainThemeSideBar></MainThemeSideBar>
            </div>
          </div>
        </div>
      </MainThemeBootstrap>
    </div>
  );
};
