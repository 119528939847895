import React, { useEffect, useState } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import logo from './logo.svg';
import './App.css';

import { Button } from '@material-ui/core';
import Login from './Login';
import { getAPIPath } from './core';

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { loginState, userState } from './state/state';
import RouterRoot from './Router';
import { ModalMother } from './Modals/ModalMother';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // return <Button color="primary">Hello Worldz</Button>;
  // return <Login />;

  const [loading, setIsLoading] = useState(true);
  const [loginStateVal, setLoginStateVal] = useRecoilState(loginState);
  const [userStateVal, setUserStateVal] = useRecoilState(userState);

  useEffect(() => {
    const checkAuth = async () => {
      const response = await fetch(getAPIPath() + '/authstatus', {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      const bod = await response.json();
      const { isAuth } = bod;

      // If not authenticated --- set status
      if (!isAuth) {
        setLoginStateVal(false);
        setIsLoading(false);
        return;
      }

      // If authenticated --- fetch the user profile
      const userResponse = await fetch(getAPIPath() + '/api/me', {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      const userRespBod = await userResponse.json();
      console.log('already logged in', userRespBod.userId);

      // Login OK -- update user state
      setUserStateVal(userRespBod);
      setLoginStateVal(true);
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <>
      <ModalMother />
      <ToastContainer />
      <RouterRoot
        loginStateVal={loginStateVal}
        isAdmin={userStateVal.isAdmin}
      />
    </>
  );
}

export default App;
