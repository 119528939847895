import { FixtureCreateRequest } from '../server-types';
import { CreateFixtureForm } from '../state/createFixture';
import { getMultiSelectValues } from '../mapper/form-elements';

export const convertToFixtureCreatePayload = (
  input: CreateFixtureForm,
  isBudgetIndication: boolean = false
): FixtureCreateRequest => {
  const responseObj: FixtureCreateRequest = {
    global_id: '',
    imo: '',
    type: '',
    requirement_subtype: null,
    fixture_date: undefined,
    delivery_date: undefined,
    delivery_port: '',
    redelivery_ports: '',
    period_type: null,
    period: '',
    opt_type: null,
    opt: '',
    workscope: '',
    comments: '',
    redelivery_date: undefined,
    charterer: '',
    source: '',
    broker: null,
    currency: null,
    rate_type: null,
    rate: '',
    option_curr: null,
    option_rate_type: null,
    opt_rate: '',
    mob_fee_curr: null,
    mob_fee: '',
    demob_fee_curr: null,
    demob_fee: '',
    p_and_c: '',
    own: '',
    region: '',
    vessel_name: '',
    options_until_date: undefined,
    rate_type_rnr: '',
    is_budget_indication: isBudgetIndication ? '1' : '0',
  };

  // Map inputs to the create request
  if (input.vesselDetails !== undefined) {
    responseObj.imo = input.vesselDetails.value.imo;
    responseObj.vessel_name = input.vesselDetails.value.vessel_name;
  }

  if (input.requirementType !== undefined)
    responseObj.type = input.requirementType.value;
  if (
    input.requirementSubType !== undefined &&
    input.requirementSubType !== null
  )
    responseObj.requirement_subtype = input.requirementSubType.value;
  if (input.globalId !== undefined) responseObj.global_id = input.globalId;
  if (input.fixtureDate !== undefined)
    responseObj.fixture_date = input.fixtureDate;
  if (input.deliveryDate !== undefined)
    responseObj.delivery_date = input.deliveryDate;
  if (input.optionsUntil !== undefined)
    responseObj.options_until_date = input.optionsUntil;
  if (input.deliveryPort !== undefined)
    responseObj.delivery_port = input.deliveryPort.value;
  if (input.reDeliveryPort !== undefined)
    responseObj.redelivery_ports = input.reDeliveryPort.value;
  if (input.periodAmountUnit !== undefined)
    responseObj.period_type = input.periodAmountUnit.value;
  if (input.periodAmountValue !== undefined)
    responseObj.period = input.periodAmountValue;
  if (input.optionDurationUnit !== undefined)
    responseObj.opt_type = input.optionDurationUnit.value;
  if (input.optionDurationValue !== undefined)
    responseObj.opt = input.optionDurationValue;
  if (input.workScope !== undefined) responseObj.workscope = input.workScope;
  if (input.comments !== undefined) responseObj.comments = input.comments;
  if (input.reDeliveryDate !== undefined)
    responseObj.redelivery_date = input.reDeliveryDate;
  if (input.charterer !== undefined)
    responseObj.charterer = getMultiSelectValues(input.charterer);
  if (input.source !== undefined) responseObj.source = input.source;
  if (input.broker !== undefined) responseObj.broker = input.broker.value;
  if (input.rateCurrency) responseObj.currency = input.rateCurrency.value;
  if (input.rateType !== undefined)
    responseObj.rate_type = input.rateType.value;
  if (input.rate !== undefined) responseObj.rate = input.rate;
  if (input.optionRateCurrency !== undefined)
    responseObj.option_curr = input.optionRateCurrency.value;
  if (input.optionRateType !== undefined)
    responseObj.option_rate_type = input.optionRateType.value;
  if (input.optionRate !== undefined) responseObj.opt_rate = input.optionRate;
  if (input.mobilisationFeeAmountCurrency !== undefined)
    responseObj.mob_fee_curr = input.mobilisationFeeAmountCurrency.value;
  if (input.mobilisationFeeAmount !== undefined)
    responseObj.mob_fee = input.mobilisationFeeAmount;
  if (input.demobFeeAmountCurrency !== undefined)
    responseObj.demob_fee_curr = input.demobFeeAmountCurrency.value;
  if (input.demobFeeAmount !== undefined)
    responseObj.demob_fee = input.demobFeeAmount;
  if (input.privateAndConfidential !== undefined)
    responseObj.p_and_c = input.privateAndConfidential;
  if (input.own !== undefined) responseObj.own = input.own;
  if (input.region !== undefined) responseObj.region = input.region.value;
  if (input.showRateRNR !== undefined)
    responseObj.rate_type_rnr = input.showRateRNR;

  return responseObj;
};
