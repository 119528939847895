import React, { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { NumericFormat } from 'react-number-format';
import { activeModalType, ModalType } from '../state/modals';
import { fixtureGlobalIdLookupState } from '../state/openFixture';
import { modalEditorOpen } from '../state/modals';
import { getFixtures } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';
import { createOptionsFromEnum } from '../util';
import { RequirementTypes } from '../Modals/CreateFixtureModal';

enum BudgetIndicationTab {
  BudgetIndication = 'budget_indication',
}
export const Fixtures = (props: any) => {
  const { tab } = props;
  const [fixturesLoaded, setFixturesLoaded] = useState(false);
  const [fixtures, setFixtures] = useState<any>([]);
  const [subTab, setSubTab] = useState<string>(RequirementTypes.SPOT);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'fixture_date', desc: true });

  const setFixtureGlobalId = useSetRecoilState(fixtureGlobalIdLookupState);
  const [moodalOpen, setModalOpen] = useRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);

  const openFixture = (
    fixtureGlobalId: string,
    isBudgetIndication: boolean
  ) => {
    setFixtureGlobalId(fixtureGlobalId);
    setModalOpen(true);
    if (isBudgetIndication) {
      setModalType(ModalType.UpdateBudgetIndication);
    } else {
      setModalType(ModalType.OpenFixture);
    }
  };

  useEffect(() => {
    if (moodalOpen) {
      return;
    }

    getFixtures(tab, page, perPage, sortBy, subTab, 'subsea').then(
      (fixturesRes) => {
        setFixtures(fixturesRes.data);
        setTotalPages(fixturesRes.pagination.lastPage);
        setFixturesLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setFixturesLoaded(true);
      }
    );
  }, [tab, page, perPage, sortBy, moodalOpen, subTab]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Fixture Date',
        accessor: 'fixture_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Charterer',
        accessor: 'charterer',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Period',
        accessor: 'period',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {row.original.period} {row.original.period_type}
          </div>
        ),
      },
      {
        Header: 'Rate',
        accessor: 'currency',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {row.original.currency}
            <span style={{ paddingLeft: '5px' }}>
              <NumericFormat
                value={row.original.rate}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
            </span>
          </div>
        ),
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Redelivery Date',
        accessor: 'redelivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Region',
        accessor: 'region',
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openFixture(
                  original.row.original.global_id,
                  !!original.row.original.is_budget_indication
                );
                console.log('opening', original.row.original.global_id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>Fixtures</div>

      <ul className='nav nav-tabs'>
        {[
          ...createOptionsFromEnum(RequirementTypes),
          ...createOptionsFromEnum(BudgetIndicationTab),
        ].map((tabOption) => (
          <li key={tabOption.value} className='nav-item'>
            <a
              className={
                tabOption.value === subTab ? 'nav-link active' : 'nav-link'
              }
              onClick={(e) => {
                setSubTab(tabOption.value);
              }}
              aria-current='page'
              href='#1'
            >
              {tabOption.label}
            </a>
          </li>
        ))}
      </ul>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={fixtures}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
