import { Typography } from '@material-ui/core';
import { MainThemeBootstrap } from '../../Theme/MainThemeBootstrap';
import { MainThemeSideBar } from '../../Theme/MainThemeSideBar';
import { FOSBSVesselSearch } from '../../common/FOSBSVesselSearch';
import { TextToJson } from '../../common/TextToJson';
import { z } from 'zod';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { createValuations } from '../../services/fosService';

const preprocessAndValidateDate = z.preprocess(
  (dateString) => {
    if (typeof dateString === 'string') {
      const date = moment.utc(
        dateString,
        ['DD/MM/YYYY', 'DD.MM.YYYY', 'D/M/YYYY', 'D.M.YYYY'],
        true
      );
      if (date.isValid()) return date.toISOString();
    }

    return undefined;
  },
  z.string().or(
    z.undefined().refine(() => false, {
      message:
        "Date must be in one of the DD/MM/YYYY', 'DD.MM.YYYY', 'D/M/YYYY', 'D.M.YYYY format and valid",
    })
  )
);

const preprocessAndValidatePrice = z.preprocess(
  (input) => {
    if (typeof input === 'string') {
      const cleanedInput = input.replace(/\s+/g, '');
      if (/^\d+$/.test(cleanedInput)) {
        return parseInt(cleanedInput, 10);
      }
    }
    return undefined;
  },
  z
    .number()
    .int()
    .or(
      z.undefined().refine(() => false, {
        message:
          'Price must be a valid integer without any non-numeric characters',
      })
    )
);

const ValuationSchema = z.array(
  z.object({
    imo: z
      .string()
      .length(7, 'IMO must be exactly 7 digits long')
      .regex(/^\d{7}$/, 'IMO must be numeric'),
    client: z.string().min(1, 'Client cannot be empty'),
    vessel_name: z.string().min(1, 'Vessel name cannot be empty'),
    effective_date: preprocessAndValidateDate,
    currency: z.string().min(1, 'Currency cannot be empty'),
    price_local_from: preprocessAndValidatePrice,
    price_local_to: preprocessAndValidatePrice,
    source: z.string().min(1, 'Source cannot be empty'),
    comments: z.string().optional(),
  })
);

export const BulkUploadValuation = () => {
  const [valuations, setValuations] = useState([]);
  const [valuationErrors, setValuationErrors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const doCreate = async () => {
    try {
      setIsLoading(true);
      const res = await createValuations(valuations);
      if (res.errors.length > 0) {
        setValuationErrors(res.errors);
        toast.warn('Valuations created with errors');
      } else {
        setValuationErrors([]);
        toast.success('Valuations has been created');
      }
    } catch (error) {
      console.log(error);
      toast.error('Error creating valuations');
    }
    setIsLoading(false);
  };
  return (
    <div>
      <MainThemeBootstrap>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <FOSBSVesselSearch></FOSBSVesselSearch>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-10 main-column'>
              <div className='north-sea-spot-caption'>New Valuations</div>
              <Typography variant='caption' display='block' gutterBottom>
                Copy paste valution table according to{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  style={{ color: 'blue' }}
                  href='https://fearnleys.sharepoint.com/:x:/r/sites/FOSLive/Shared%20Documents/Valuation_upload/Valuation_upload.xlsm?d=wbdcdb9fe37874eb59c1a5dd487135f54&csf=1&web=1&e=2s7ca6&nav=MTVfezg5NkNCRDlCLTI2NzItNEFCQy1BQzNELTVEOUVFRDIwQTlFRX0'
                >
                  example excel format
                </a>
                .
              </Typography>
              <TextToJson
                onChange={setValuations}
                validationSchema={ValuationSchema}
                placeholder='imo  client    vessel_name     effective_date     currency     price_local_from     price_local_to     source     comments'
              />
              {valuationErrors.length > 0 && (
                <ul>
                  {valuationErrors.map((err: any) => (
                    <li>
                      <Typography
                        key={err.field}
                        variant='caption'
                        color='error'
                      >
                        {err}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
              <div
                style={{
                  marginTop: 20,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  type='button'
                  className='btn btn-success btn'
                  onClick={doCreate}
                  disabled={isLoading || valuations.length === 0}
                >
                  {isLoading ? 'Uploading...' : 'Upload Valuations'}
                </button>
              </div>
            </div>

            <div className='col-2 sidebar-column'>
              <MainThemeSideBar></MainThemeSideBar>
            </div>
          </div>
        </div>
      </MainThemeBootstrap>
    </div>
  );
};
