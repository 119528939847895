import { useRecoilState } from 'recoil';
import { imoLookupState } from '../../state/openVessel';
import { FileUploader } from '../../common/FileUploader';

export const VesselAttachments = (props: any) => {
  const [imo, setLookupImo] = useRecoilState(imoLookupState);

  return (
    <div className='row'>
      <div className='container'>
        <FileUploader entityId={imo} entityTag={'vessel'} />
      </div>
    </div>
  );
};
