import React, { useEffect, useState } from 'react';
import { MainThemeBootstrap } from '../Theme/MainThemeBootstrap';
import { FOSBSVesselSearch } from '../common/FOSBSVesselSearch';
import { MainThemeSideBar } from '../Theme/MainThemeSideBar';

interface Props {}

export const Index = (props: Props) => {
  return (
    <MainThemeBootstrap>
      <div className='container-fluid'>
        <div className='row pt-3'>
          <div className='col-12'>
            <FOSBSVesselSearch></FOSBSVesselSearch>
          </div>
        </div>
        <div className='row pt-3'>
          <div className='col-10 main-column'></div>
          <div className='col-2 sidebar-column'>
            <MainThemeSideBar></MainThemeSideBar>
          </div>
        </div>
      </div>
    </MainThemeBootstrap>
  );
};
