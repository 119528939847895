import React, { useState } from 'react';
import { MultiSelectOption, MultiSelectOptions } from '../state/searchVessel';

interface Props {
  options: MultiSelectOptions;
  displayVertical: boolean;
  label: string;
  name: string;
  stateSetter: (name: string, value: any) => void;
  value: MultiSelectOptions;
}

const FOSBSTriggerSelect = (props: Props) => {
  const addOption = (option: MultiSelectOption) => {
    const existingValues = [...props.value];
    existingValues.push(option);
    props.stateSetter(props.name, existingValues);
  };

  const removeOption = (option: MultiSelectOption) => {
    const existingValues = [...props.value];

    // find index of item
    const optIndex = existingValues.findIndex((el) => {
      return el.value === option.value;
    });
    if (optIndex === -1) return;

    // remove from arr
    existingValues.splice(optIndex, 1);

    // call setter on new arr w/o the value
    props.stateSetter(props.name, existingValues);
  };

  return (
    <>
      <div className='row'>
        <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
          {props.label}
        </label>
        <div className='col'>
          {props.options.map((option) => {
            if (option.label.includes('|')) return undefined;
            return (
              <div
                className={
                  props.displayVertical
                    ? 'form-check form-switch pt-1'
                    : 'form-check form-check-inline pt-2'
                }
                key={`triggerselect-${props.name}-${option.value}`}
              >
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={`flexSwitch-${props.name}-${option.value}`}
                  checked={props.value.some((val) => {
                    return val.value === option.value;
                  })}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addOption(option);
                    } else {
                      removeOption(option);
                    }
                  }}
                />
                <label
                  className='form-check-label'
                  htmlFor={`flexSwitch-${props.name}-${option.value}`}
                >
                  {option.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  // due to async fetching of options, we must also check if options are empty or
  const equal =
    prevProps.value === nextProps.value &&
    prevProps.options.length === nextProps.options.length;
  return equal;
}

export default React.memo(FOSBSTriggerSelect, areEqual);
