import React from 'react';
import { useEffect, useState } from 'react';
import { getOSVAvailabilities } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';
import { availabilityDateChecker } from '../util';
import { NumericFormat } from 'react-number-format';
import { FOSTableNextChartererCell } from '../common/table/FOSTableNextChartererCell';
enum Tab {
  All = 'All',
  AHTS = 'ahts',
  PSV = 'psv',
}

export const Availabilities = (props: any) => {
  const { region } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [tab, setTab] = useState<Tab>(Tab.All);
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'date_available', desc: false });

  useEffect(() => {
    getOSVAvailabilities(region, tab, page, perPage, sortBy).then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [region, tab, page, perPage, sortBy]);

  const resetPage = () => {
    setPage(1);
  };

  useEffect(() => {
    resetPage();
  }, [tab]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'DWT',
        accessor: 'dwt',
        Cell: ({ value }: any) => (
          <span>
            <NumericFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        Header: 'DECK',
        accessor: 'deck_area',
        Cell: ({ value }: any) => (
          <span>
            <NumericFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        Header: 'DP',
        accessor: 'dp',
      },
      {
        Header: 'Crane',
        accessor: 'crane',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {[
              row.original.crane_size_1,
              row.original.crane_size_2,
              row.original.crane_size_3,
            ]
              .filter((x) => x)
              .join(', ')}
          </div>
        ),
      },
      {
        Header: 'Current Charterer',
        accessor: 'current_charterer',
      },
      {
        Header: 'Next Charterer',
        accessor: 'next_charterer',
        Cell: ({ row }: any) => (
          <FOSTableNextChartererCell
            currenCharterer={row.original.current_charterer}
            nextCharterer={row.original.next_charterer}
            currentChartererDate={row.original.current_charterer_date}
            nextChartererDate={row.original.next_charterer_date}
          />
        ),
      },
      {
        Header: 'Port',
        accessor: 'port',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Region',
        accessor: 'region',
      },
      {
        Header: 'Date',
        accessor: 'date_available',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>
        OSV AVAILABILITY – AHTS {'>'} 9999 BHP AND PSV {'>'} 3000 DWT
      </div>

      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <a
            className={tab === Tab.All ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.All);
            }}
            href='#1'
          >
            All
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={tab === Tab.AHTS ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.AHTS);
            }}
            aria-current='page'
            href='#2'
          >
            AHTS
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={tab === Tab.PSV ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.PSV);
            }}
            href='#3'
          >
            PSV
          </a>
        </li>
      </ul>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          data={data}
          columns={columns}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          isHighlightedRow={(row: any) =>
            availabilityDateChecker(row.date_available)
          }
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
