import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FOSBSVesselFinder from './FOSBSVesselFinder';
import { useSetRecoilState } from 'recoil';
import { activeModalType, modalEditorOpen, ModalType } from '../state/modals';
import { imoLookupState } from '../state/openVessel';

interface Props {}

export const FOSBSVesselSearchUnauthenticated = (props: Props) => {
  const [vesselDetails, setVesselDetails] = useState<any>(undefined);

  // open modal and set lookup mode
  const setModalOpen = useSetRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);
  const setImo = useSetRecoilState(imoLookupState);

  const openVesselModal = (imo: string) => {
    setImo(imo);
    setModalType(ModalType.OpenVesselUnauthenticated);
    setModalOpen(true);
    console.log('opening as', imo);
  };

  const customDropDownStyle = {
    control: (base: any, state: any) => {
      return {
        ...base,
        border: '1px solid #ced4da',
        borderRadius: '0px',
        outline: '0',
        transition:
          'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        borderColor: state.isFocused ? '#ced4da' : '#ced4da',
        boxShadow: state.isFocused
          ? '0 0 0 0.2rem rgba(227, 223, 217, 0.90);'
          : '0 !important',
        '&:hover': { borderColor: '#ced4da' }, // border style on hover
      };
    },
    container: (base: any, state: any) => {
      return {
        ...base,
        position: 'relative',
        flex: '1 1 auto',
        width: '1%',
        minWidth: 0,
      };
    },
  };

  return (
    <div className='input-group'>
      <FOSBSVesselFinder
        name={'vessel_name'}
        stateSetter={(name, value) => {
          setVesselDetails(value);
          openVesselModal(value.value.imo);
        }}
        value={vesselDetails}
        isMulti={false}
        customStyle={customDropDownStyle}
      />

      <button type='button' className='btn btn-info'>
        Search
      </button>
    </div>
  );
};
