import Map, { Layer, LayerProps, MapboxEvent, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { accessToken } from './config';
import DrawControl from './DrawControl';
import { useCallback, useEffect, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapboxDrawRectangle from 'mapbox-gl-draw-rectangle-mode';
import 'mapbox-gl/dist/mapbox-gl.css';

const layerOpenSea: LayerProps = {
  id: 'open-sea',
  type: 'raster',
  paint: {
    'raster-opacity': 0.8,
  },
};

const mapboxStyledMap = 'mapbox://styles/larssbr/clujvww9400i701nt2x8p8lpu';
const vesselSolidMarker = 'vessel-solid';

interface Props {
  height: string | number;
  width: string | number;
  center?: [number, number];
  onSelect: (polygon: [number, number][]) => void;
}

const handleOnLoad = (e: MapboxEvent) => {
  const map = e.target;
  if (!map.hasImage(vesselSolidMarker)) {
    map.loadImage('/img/map/vessel-solid.png', (error, image) => {
      if (error) throw error;
      if (!map.hasImage(vesselSolidMarker) && image) {
        map.addImage(vesselSolidMarker, image, { sdf: true });
      }
    });
  }
};

export const SelectRegionMap = ({ width, height, center, onSelect }: Props) => {
  const [features, setFeatures] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);

  const onUpdate = useCallback((e: any) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);
  const onDelete = useCallback((e: any) => {
    setFeatures((currFeatures) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  useEffect(() => {
    const polygon =
      features?.[Object.keys(features)?.[0]]?.geometry?.coordinates;

    onSelect(polygon);
    if (!polygon) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [features]);

  return (
    <Map
      mapboxAccessToken={accessToken}
      initialViewState={{
        longitude: center?.[0] ?? 10.757933,
        latitude: center?.[1] ?? 59.911491,
        zoom: 2,
      }}
      style={{ width, height }}
      mapStyle={mapboxStyledMap}
      onLoad={handleOnLoad}
      projection={{ name: 'mercator' }}
    >
      <Source
        type='raster'
        tiles={['https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png']}
        tileSize={256}
        minzoom={0}
        maxzoom={22}
      >
        <Layer beforeId='waterway-label' {...layerOpenSea} />
      </Source>
      {!loading && (
        <DrawControl
          displayControlsDefault={false}
          controls={{
            trash: true,
          }}
          defaultMode='draw_rectangle'
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
          modes={{
            ...MapboxDraw.modes,
            draw_rectangle: MapboxDrawRectangle,
          }}
        />
      )}
    </Map>
  );
};
