import { atom, RecoilState } from 'recoil';
import { FOSDBVessel } from '../server-types';
import { MultiSelectOption, MultiSelectOptions } from './searchVessel';

export const imoLookupState: RecoilState<string> = atom({
  key: 'imoLookupState',
  default: '',
});

export const vesselLookupState: RecoilState<EditVesselForm | undefined> = atom({
  key: 'vesselLookupState',
  default: undefined as EditVesselForm | undefined,
});

export const vesselEditFormState: RecoilState<EditVesselForm | undefined> =
  atom({
    key: 'vesselEditFormState',
    default: undefined as EditVesselForm | undefined,
  });

export interface EditVesselForm {
  global_id: string | null;
  attachments: string | null;
  imo: string;
  vessel_name: string;
  vessel_ex_name: string;
  owner: MultiSelectOption | undefined;
  technical_manager: MultiSelectOption | undefined;
  design: MultiSelectOption | undefined;
  vessel_type: MultiSelectOption | undefined;
  vessel_subtype: MultiSelectOption | undefined;
  vessel_north_sea_spot: string;
  ship_status: MultiSelectOption | undefined;
  // "date_in_service": Date,
  shipyard: MultiSelectOption | undefined;
  shipyard_country: MultiSelectOption | undefined;
  shipyard_region: MultiSelectOption | undefined;
  controlling_contractor: MultiSelectOption | undefined;
  built: Date | undefined;
  dwt: string;
  bhp: string;
  bollard_pull: string; // BP
  deck_area: string; // deck
  // "deck_area_l": string|null,
  // "deck_area_b": string|null,
  crane_size_1: string;
  crane_size_2: string;
  crane_size_3: string;
  dp: MultiSelectOption | undefined;
  accomondation: string;
  // "beam_type": string|null, // only using metres, ignore beam_type for now (hardlock to metres!)
  beam: string; // only using metres
  draft: string;
  // "loa_type": string|null, // only using metres, ignore loa_type for now (hardlock to metres!)
  loa: string;
  mooring: MultiSelectOption | undefined;
  rov: MultiSelectOption | undefined;
  moonpool: string;
  helideck: string;
  wire_capacity: string;
  a_frame: string;
  deck_strength: string;
  winch: string;
  fifi: MultiSelectOption | undefined;
  oilrec: MultiSelectOption | undefined;
  ice_class: MultiSelectOption | undefined;
  fos_ice_class: MultiSelectOption | undefined;
  sps: string;
  max_speed: string;
  standby: string;
  survivors: string;
  passengers: string;
  fuel: string;
  methanol: string;
  bulk: string;
  oil_rec: string;
  brine: string;
  mud: string;
  base_oil: string;
  special_products: string;
  ballast_water: string;
  meg: string;
  pot_water: string;
  hull_number: string;
  vessel_class_2: MultiSelectOption | undefined;
  vessel_class_1: MultiSelectOption | undefined;
  flag: MultiSelectOption | undefined;
  contract_date: Date | undefined;
  contract_price_currency: string;
  contract_price: string;
  dive: MultiSelectOption | undefined;
  tower: MultiSelectOption | undefined;
  carousel: MultiSelectOption | undefined;
  lay_spread: string;
  ern: string;
  // "kw": string|null,
  comments: string;
  fixtureComments: string;
  updated: string | null;
  updated_by: string | null;
  history_log: any;
  // "current_charterer": string|null,
  // "current_charterer_fix_id": string|null,
  // "current_fixture_type": string|null,
  // "current_charterer_sublet": string|null,
  // "current_charterer_sublet_fix_id": string|null,
  next_charterer: MultiSelectOptions | undefined;
  current_charterer: MultiSelectOptions | undefined;
  // "next_charterer_fix_id": string|null,
  date_available: Date | undefined;
  port: MultiSelectOptions | undefined;
  // "position_comment": string|null,
  // "position_updated_by": string|null,
  // "position_updated_on": string|null,
  workscope: string;
  fixtureWorkscope: string;
  // "vessel_image": string|null,
  // "scrapped": string|null,
  // "source": string|null,
  // "date": string|null,
  // "nb_files": string|null,
  // "k_drive_link": string|null,
  // "price": string|null,
  // "manager": string|null,
  // "design_speed": string|null,
  // "bow_thruster": string|null,
  // "marked_sum": string|null,
  // "price_comment": string|null,
  // "comm_advice_note": string|null,
  // "swl": string|null,
  // "statdive": string|null,
  // "callsign": string|null,
  // "mainengines": string|null,
  // "chainlocker": string|null,
  // "workdrum": string|null,
  // "waterdept": string|null,
  // "dimensions": string|null,
  // "cement": string|null,
  // "latestpos": string|null,
  // "direct": null,
  // "typeiceclass": null,
  // "engines": string|null,
  // "winchbhp": string|null,
  // "vspid": string|null,
  // "grabs": string|null,
  // "tuggers": string|null,
  // "seismic_type": string|null,
  // "postype": string|null,
  // "class2": string|null,
  // "position": string|null,
  // "direc": string|null,
  // "opendate": string|null,
  // "seismic_text": string|null,
  // "crane": string,
  // "inserted": string|null,
  // "supplytype": string|null,
  // "seismic_no": string|null,
  // "market": string|null,
  // "lbp": string|null,
  // "gt": string|null,
  // "cons": string|null,
  // "crews": string|null,
  // "nrt": string|null,
  // "cabins": string|null,
  // "enginebuilder": string|null,
  // "enginemake": string|null,
  // "grabcap": string|null,
  // "capstrans": string|null,
  // "dpref": string|null,
  // "next_charterer_with_date": string|null,
  vessel_subsea: string;
  // "next_charterer_date": string|null,
  // "current_charterer_options_until_date": string|null,
  w2w: string;
  streamer_capacity: string;
  battery: string;
  shore_power: string;
  dual_fuel: string;
  // "date_added": string|null,
  // "date_updated": string|null,
  region: MultiSelectOption | undefined;
  lat: number | null;
  lon: number | null;
  region_vesseltracker: string | null;
  signal_received: string | null;
  MoveStatus: string | null;
  eta: Date | undefined;
  destination: string | null;
  draught: number | null;
  heading: string | null;
  speed: number | null;
  date_updated: Date | undefined;
  current_charterer_fix_id: string | null;
  meta: {
    imageUrl: string | undefined;
  };
}
