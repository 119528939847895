import { atom, RecoilState } from 'recoil';
import { MultiSelectOption } from './searchVessel';

export interface CreateSaleForm {
  vesselDetails: MultiSelectOption | undefined;
  effectiveDate: Date | undefined;
  priceUSD: string | number | null | undefined;
  priceLocal: string | number | null | undefined;
  currency: MultiSelectOption | undefined;
  askingPrice: string | number | null | undefined;
  askingPriceCurrency: MultiSelectOption | undefined;
  salesType: MultiSelectOption | undefined;
  privateAndConfidential: '0' | '1';
  FOSASSales: '0' | '1';
  source: string;
  deliveryDate: Date | undefined;
  buyer: MultiSelectOption | undefined;
  seller: MultiSelectOption | undefined;
  owner: MultiSelectOption | undefined;
  comments: string;
  newVesselName?: string;
  newVesselOwner?: string;
}

export const defaultCreateSaleForm: CreateSaleForm = {
  vesselDetails: undefined,
  effectiveDate: new Date(),
  priceUSD: '',
  priceLocal: '',
  currency: { label: 'USD', value: 'USD' },
  askingPrice: '',
  askingPriceCurrency: { label: 'USD', value: 'USD' },
  salesType: undefined,
  privateAndConfidential: '0',
  FOSASSales: '0',
  source: '',
  deliveryDate: undefined,
  buyer: undefined,
  seller: undefined,
  owner: undefined,
  comments: '',
  newVesselName: '',
  newVesselOwner: '',
};

export const createSaleForm: RecoilState<CreateSaleForm> = atom({
  key: 'createSaleForm',
  default: defaultCreateSaleForm,
});
