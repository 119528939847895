import React, { useEffect } from 'react';
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table';
import { getExcelFromTableAll } from '../../services/fosService';
import { exportSearchTable } from '../../services/exportService';

export interface sortBy {
  id: string;
  desc: boolean;
}

interface FOSExportTalbeProps {
  editable?: boolean;
  openEditor?: Function;
  initialSortBy: sortBy;
  onChangeSort: Function;
  setPerPage: Function;
  setPage: Function;
  currentpage: number;
  perPage: number;
  totalPage: number;
  columns: Array<any>;
  // allColumns: Array<any>
  data: any;
  getPayload: Function;
  exportTable: string;
  exportRowKey: string;
  exported?: boolean;
  fileName?: string;
  isHighlightedRow?: Function;
  onRowSelectStateChange?: Function;
  selectedRowIds?: Record<string, boolean>;
  allowExportSelected?: boolean;
}

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      (resolvedRef as any).current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export const FOSExportTable = (props: FOSExportTalbeProps) => {
  const {
    setPerPage,
    setPage,
    columns,
    // allColumns,
    data,
    currentpage,
    perPage,
    totalPage,
    fileName,
    exportRowKey,
    exportTable,
    isHighlightedRow,
    initialSortBy,
    onChangeSort,
    getPayload,
    editable,
    openEditor,
    onRowSelectStateChange,
    allowExportSelected = true,
  } = props;

  if (editable && !columns.find((c) => c.Header === 'Edit')) {
    columns.push({
      Header: 'Edit',
      Cell: (original: any) => {
        return (
          <button
            className='btn btn-sm btn-dark'
            onClick={(e) => {
              if (openEditor) {
                openEditor(original.row.original.global_id);
              }
            }}
          >
            Edit
          </button>
        );
      },
    });
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    state: { pageIndex, sortBy, selectedRowIds },
  } = useTable(
    {
      columns,
      data: data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage - 1,
          }),
          [state, currentpage]
        );
      },
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: {
        pageIndex: currentpage,
        sortBy: [initialSortBy],
        selectedRowIds: props.selectedRowIds ?? {},
      },
      manualPagination: true,
      pageCount: totalPage,
      autoResetSelectedRows: false,
      getRowId: (row: any) => row[exportRowKey],
    },
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onRowSelectStateChange?.(Object.keys(selectedRowIds));
  }, [selectedRowIds]);

  useEffect(() => {
    onChangeSort(sortBy[0]);
  }, [onChangeSort, sortBy]);

  const highlight = (row: any) =>
    isHighlightedRow && isHighlightedRow(row.original as any)
      ? 'available'
      : '';

  const resetPageIndex = () => setPage(1);

  const ExportSelectedData = () => {
    const payload = getPayload();
    let newPayload = JSON.parse(JSON.stringify(payload));
    newPayload.requests.forEach((req: any) => {
      req.general[exportRowKey] = Object.keys(selectedRowIds);
    });

    const excelColumns = columns.map((column) => ({
      header: column.Header,
      key: column.accessor,
      date: column.date,
    }));

    getExcelFromTableAll(
      fileName ? `fos-${fileName}-selected` : `fos-report-selected`,
      excelColumns,
      exportTable,
      newPayload
    );
  };

  const ExportAllData = async () => {
    const requestPayload = await getPayload();
    const payload = {
      ...requestPayload,
      sortBy: sortBy[0],
    };

    const excelColumns = columns.map((column) => ({
      header: column.Header,
      key: column.accessor,
      date: column.date,
    }));

    getExcelFromTableAll(
      fileName ? `fos-${fileName}-all` : `fos-report-all`,
      excelColumns,
      exportTable,
      payload
    );
  };

  const ExportAllPdf = async () => {
    const headers = columns.reduce(
      (ac, col) => ({ ...ac, [col.accessor]: col.Header }),
      {}
    );
    const payload = await getPayload();

    exportSearchTable(exportTable, [headers], payload, sortBy[0]);
  };

  return (
    <>
      <div>
        <div className='table-wrapper'>
          <table {...getTableProps()} className='fl-table' id='bigTable'>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{}}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);

                return (
                  <tr className={highlight(row)} {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()} style={{}}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='af_code_pagination pt-3'>
          <button
            className='btn btn-default btn-sm'
            onClick={() => {
              setPage(1);
            }}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </button>{' '}
          <button
            className='btn btn-default btn-sm'
            onClick={() => {
              setPage((s: number) => (s === 1 ? 1 : s - 1));
            }}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>{' '}
          <button
            className='btn btn-default btn-sm'
            onClick={() => {
              setPage((s: number) => s + 1);
            }}
            disabled={!canNextPage}
          >
            {'>'}
          </button>{' '}
          <button
            className='btn btn-default btn-sm'
            onClick={() => {
              setPage(totalPage);
            }}
            disabled={!canNextPage}
          >
            {'>>'}
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span>
            | Go to page:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              min='1'
              max={totalPage}
              onChange={(e) => {
                const page = Number(e.target.value);
                if (page) {
                  page <= totalPage ? setPage(page) : setPage(totalPage);
                }
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          <select
            value={[10, 20, 30, 40, 50].includes(perPage) ? perPage : 'All'}
            onChange={(e) => {
              setPerPage(
                Number(
                  e.target.value === 'All'
                    ? totalPage * perPage
                    : e.target.value
                )
              );
              resetPageIndex();
            }}
          >
            {[10, 20, 30, 40, 50, 'All'].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>

        <button
          className='btn btn-secondary'
          style={{ marginLeft: 5 }}
          onClick={ExportAllData}
        >
          Export All
        </button>
        {allowExportSelected && (
          <button
            className='btn btn-secondary'
            style={{ marginLeft: 5 }}
            onClick={ExportSelectedData}
          >
            Export Selected
          </button>
        )}
        <button
          className='btn btn-secondary'
          style={{ marginLeft: 5 }}
          onClick={ExportAllPdf}
        >
          Export PDF
        </button>
      </div>
    </>
  );
};
