import React from 'react';
import { FOSExportTable, sortBy } from '../common/table/FOSExportTable';

interface Props {
  data: any;
  columns: Array<any>;
  // initialSortBy: sortBy;
  onChangeSort: Function;
  setPerPage: Function;
  setPage: Function;
  sortBy: sortBy;
  setSortBy: Function;
  // currentpage: number;
  page: number;
  perPage: number;
  totalPages: number;
  exportTable: string;
  exportRowKey: string;
  fileName?: string;
  getPayload: Function;
  editable?: boolean;
  openEditor?: Function;
  onRowSelectStateChange?: Function;
  selectedRowIds?: string[];
  allowExportSelected?: boolean;
}

export const SearchResults = (props: Props) => {
  const {
    data,
    columns,
    setPage,
    setPerPage,
    page,
    perPage,
    totalPages,
    sortBy,
    setSortBy,
    exportRowKey,
    exportTable,
    fileName,
    getPayload,
    editable,
    openEditor,
    onRowSelectStateChange,
    allowExportSelected,
  } = props;

  return (
    <div>
      <FOSExportTable
        editable={editable}
        openEditor={openEditor}
        columns={columns}
        data={data}
        setPage={setPage}
        setPerPage={setPerPage}
        currentpage={page}
        perPage={perPage}
        totalPage={totalPages}
        initialSortBy={sortBy}
        exported={true}
        onChangeSort={setSortBy}
        getPayload={getPayload}
        exportTable={exportTable}
        exportRowKey={exportRowKey}
        fileName={fileName}
        onRowSelectStateChange={onRowSelectStateChange}
        selectedRowIds={
          props.selectedRowIds?.reduce<Record<string, boolean>>((acc, curr) => {
            acc = { ...acc, [curr]: true };
            return acc;
          }, {}) ?? {}
        }
        allowExportSelected={allowExportSelected}
      />
    </div>
  );
};
