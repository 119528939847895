import { getAPIPath } from '../core';
import { getMultiselctFieldData } from '../services/fosService';

//#region VARS
let autoCompleteQueryTimeout: NodeJS.Timeout | undefined = undefined;
//#endregion

export const handleAsyncAutoCompleteInputChange = (
  dbTableName: string,
  dbFieldName: string,
  value: string,
  isMultiSelect: boolean,
  setterOptionsCallback: (newValue: any) => void,
  setterValueCallback: (newValue: any) => void,
  searchVessel: boolean = false
) => {
  // Multiselect only allows an array of values
  if (!isMultiSelect) {
    // Change target value
    setterValueCallback(value);
  }

  // Prevent default limits
  if (value.trim() === '') {
    setterOptionsCallback([]);
    return;
  } // do not search empty strings (backend does not permit)
  if (value.length < 3) {
    setterOptionsCallback([]);
    return;
  } // do not permit searches less than 3 chars

  // Reset timeout if present
  if (autoCompleteQueryTimeout !== undefined) {
    // console.log('timeout interrupted');
    clearTimeout(autoCompleteQueryTimeout);
  }

  // Time out the actual ajax lookup to prevent multiple loads
  // console.time('timeout');
  autoCompleteQueryTimeout = setTimeout(() => {
    // console.timeEnd('timeout');

    if (searchVessel) {
      // TODO: Uncomment if the run comes here

      // special processing for searching vessel (imo + name)
      loadVesselSearchCompleteValues(value, setterOptionsCallback);
    } else {
      // default behaviour: load values for name
      loadAutoCompleteValues(
        dbTableName,
        dbFieldName,
        value,
        setterOptionsCallback
      );
    }
  }, 250);
};

export const loadAutoCompleteValues = async (
  tableName: string,
  fieldName: string,
  value: string,
  callback: (data: any) => void
) => {
  return getMultiselctFieldData(tableName, fieldName, value, callback);
};
/*
export const loadFixtureAutoCompleteValues = async (tableName: string, fieldName: string, value: string, callback: (data: any) => void) => {
  try {
    const res = await fetch(getAPIPath() + `/api/meta/fixtures/field-search/${fieldName}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'content-type': 'Application/JSON'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ searchParam: value })
    });

    if (res.status !== 200) {
      // TODO: Handle load error
    }

    const generalRes = await res.json();
    const data = generalRes[fieldName];
    callback(data);
  } catch (err) {
    // TODO: Handle error properly
    console.error(err);
  }
}
*/
const loadVesselSearchCompleteValues = async (
  value: string,
  callback: (data: any) => void
) => {
  try {
    const res = await fetch(
      getAPIPath() + `/api/meta/vessels/search-name-imo`,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'content-type': 'Application/JSON',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ searchParam: value }),
      }
    );

    if (res.status !== 200) {
      // TODO: Handle load error
    }

    const generalRes = await res.json();
    callback(generalRes);
  } catch (err) {
    // TODO: Handle error properly
    console.error(err);
  }
};
