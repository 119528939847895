import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { getAPIPath, isProd, siteName } from './core';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { justLoggedInState, loginState, userState } from './state/state';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        {siteName}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://fearnleyoffshoresupply.com/wp-content/uploads/2019/12/DJI_0061-Aleksander-Torvik-scaled.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const history = useHistory();

  const classes = useStyles();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setIsError] = useState<boolean>(false);

  const [isPosting, setIsPosting] = useState<boolean>(false);

  const [loginStateVal, setLoginStateVal] = useRecoilState(loginState);
  const [, setJustLoggedInState] = useRecoilState(justLoggedInState);
  const [userStateVal, setUserStateVal] = useRecoilState(userState);

  const resetErrors = async () => {
    setIsError(false);
  };

  const signIn = async () => {
    setIsPosting(true);

    const details = {
      username,
      password,
    };
    const formBody = Object.keys(details)
      .map(
        (key) =>
          encodeURIComponent(key) +
          '=' +
          encodeURIComponent((details as any)[key])
      )
      .join('&');

    const response = await fetch(getAPIPath() + '/login', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'no-cors', // no-cors, *cors, same-origin
      // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: formBody, // body data type must match "Content-Type" header
    });

    // If authenticated --- fetch the user profile
    const userResponse = await fetch(getAPIPath() + '/api/me', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      credentials: 'include', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    const userRespBod = await userResponse.json();
    console.log('welcome', userRespBod.userId);

    // Login OK -- update user state
    setUserStateVal(userRespBod);

    setIsPosting(false);

    const loginSuccess = response.status === 200;
    const loginErr = response.status === 401;

    if (loginErr) {
      setIsError(true);
      return;
    }

    if (loginSuccess) {
      setLoginStateVal(true);
      setJustLoggedInState(true);
      history.push('/north-sea-spot');
      return;
    }

    // TODO: Handle unexpected error

    return response.json(); // parses JSON response into native JavaScript objects
  };

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>

          {isPosting && (
            <div style={{ marginTop: 20 }}>
              <CircularProgress color='secondary' />
            </div>
          )}

          {/* {isProd() && <>
              <Typography component="h1" variant="h5">
                Site in maintenance mode
              </Typography>
            </>} */}
          {
            <>
              <form className={classes.form} noValidate>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='username'
                  label='Username'
                  name='username'
                  autoComplete='username'
                  autoFocus
                  value={username}
                  onChange={(e) => {
                    resetErrors();
                    setUsername(e.target.value);
                  }}
                  error={isError}
                  helperText={isError ? 'Invalid username or password' : ''}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  value={password}
                  onChange={(e) => {
                    resetErrors();
                    setPassword(e.target.value);
                  }}
                  error={isError}
                  helperText={isError ? 'Invalid username or password' : ''}
                />
                <FormControlLabel
                  control={<Checkbox value='remember' color='primary' />}
                  label='Remember me'
                />
                <Button
                  type='button'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={() => {
                    signIn();
                  }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href='#' variant='body2'>
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href='#' variant='body2'>
                      {/* {"Don't have an account? Sign Up"} */}
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
              </form>
            </>
          }
        </div>
      </Grid>
    </Grid>
  );
}
