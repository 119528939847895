import { useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { createFixtureForm } from '../../state/createFixture';
import { modalEditorOpen } from '../../state/modals';
import { ModalHeader } from '../ModalHeader';
import { toast } from 'react-toastify';
import { convertToFixtureCreatePayload } from '../../mapper/fixture-create';
import { createFixture } from '../../services/fosService';
import { BudgetIndiationFormUI } from './BudgetIndiationFormUI';

interface Props {
  onRequestClose(): void;
}

export const CreateBudgetIndicationModal = (props: Props) => {
  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [form, setForm] = useRecoilState(createFixtureForm);
  const resetForm = useResetRecoilState(createFixtureForm);

  // No idea why we need this. But if this is not used then form does not update properly
  const latestFormState = useRef(form);

  // On var update (ref updating)
  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  const closeModal = () => {
    resetForm();
    props.onRequestClose();
  };

  const doCreate = async () => {
    const finalRequestBody = convertToFixtureCreatePayload(form, true);

    try {
      await createFixture(finalRequestBody);

      toast.success('Budget Indication has been created');
      resetForm();
      setModalOpen(false);
    } catch (e) {
      toast.error('Error creating Budget Indication');
    }
  };

  return (
    <div>
      <ModalHeader
        onRequestClose={closeModal}
        header={'Create Budget Indication'}
      />
      <BudgetIndiationFormUI
        form={form}
        setForm={setForm}
        latestFormState={latestFormState}
      />
      <div className='row'>
        <div className='col-sm-12' style={{ textAlign: 'center' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              closeModal();
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-success btn'
            onClick={() => {
              doCreate();
            }}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};
