import { atom, RecoilState } from 'recoil';
import { MultiSelectOption, RangeFormState } from './searchVessel';

export interface CreateValuationForm {
  vesselDetails: MultiSelectOption | undefined;
  effectiveDate: Date | undefined;
  source: string;
  priceLocal: RangeFormState;
  currency: MultiSelectOption | undefined;
  priceUSD: RangeFormState;
  client: string;
  issuedTo: string;
  comments: string;
}

export const defaultCreateValuationForm: CreateValuationForm = {
  vesselDetails: undefined,
  effectiveDate: new Date(),
  source: '',
  priceLocal: { from: '', to: '' },
  currency: undefined,
  priceUSD: { from: '', to: '' },
  client: '',
  issuedTo: '',
  comments: '',
};

export const createValuationForm: RecoilState<CreateValuationForm> = atom({
  key: 'createValuationFormState',
  default: defaultCreateValuationForm,
});
