interface Props {
  onRequestClose(): void;
  header: string;
  contentRight?: React.ReactNode;
}

export const ModalHeader = (props: Props) => {
  return (
    <div className='row'>
      <div className='col-sm-6'>
        <h5 className='mb-3 fw600'>{props.header}</h5>
      </div>
      <div className='col-sm-6' style={{ textAlign: 'right' }}>
        {!!props.contentRight && props.contentRight}
        <button
          type='button'
          className='btn btn-secondary btn-sm'
          onClick={props.onRequestClose}
          style={{ marginLeft: 5 }}
        >
          Close
        </button>
      </div>
    </div>
  );
};
