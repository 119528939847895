import { useEffect, useState } from 'react';
import { getExchangeRates } from '../../services/fosService';
import { Row } from 'react-table';

export const ValuationCellInUSD = ({ row }: { row: Row<any> }) => {
  const valuation = row.original;
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [fromPriceUSD, setFromPriceUSD] = useState<string>('NA');
  const [toPriceUSD, setToPriceUSD] = useState<string>('NA');

  const getPriceUSD = async (
    fromCurrency: string,
    priceLocal: number,
    effectiveDate: string
  ) => {
    try {
      const priceUSD = await getExchangeRates(
        fromCurrency,
        'USD',
        effectiveDate,
        priceLocal
      );
      console.log('priceUSD', priceUSD.rate);
      return priceUSD.rate;
    } catch (err) {
      return 'NA';
    }
  };

  const getUSDPriceFromValuation = async (valuation: any) => {
    const fromPriceUSD = await getPriceUSD(
      valuation.currency,
      valuation.price_local_from,
      valuation.effective_date
    );
    const formatedPriceUSD = fromPriceUSD.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    setFromPriceUSD(formatedPriceUSD);

    const toPriceUSD = await getPriceUSD(
      valuation.currency,
      valuation.price_local_to,
      valuation.effective_date
    );
    const formatedToPriceUSD = toPriceUSD.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    setToPriceUSD(formatedToPriceUSD);

    setIsFetching(false);
  };

  useEffect(() => {
    getUSDPriceFromValuation(valuation);
  }, [valuation]);

  if (isFetching) {
    return <div>Loading...</div>;
  }
  return (
    <p>
      {fromPriceUSD} - {toPriceUSD}
    </p>
  );
};
