import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { NumericFormat } from 'react-number-format';
import { activeModalType, ModalType } from '../state/modals';
import { saleGlobalId } from '../state/openSale';
import { modalEditorOpen } from '../state/modals';
import { getSalesCandidates } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableYesNoCell } from '../common/table/FOSTableYesNoCell';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';

export const SalesCandidates = (props: any) => {
  const [sales, setSales] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'effective_date', desc: true });

  const setSaleGlobalId = useSetRecoilState(saleGlobalId);
  const [moodalOpen, setModalOpen] = useRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);

  const openSale = (saleGlobalId: string) => {
    setSaleGlobalId(saleGlobalId);
    setModalOpen(true);
    setModalType(ModalType.OpenSalesCandidate);
  };

  useEffect(() => {
    if (moodalOpen) {
      return;
    }

    getSalesCandidates(page, perPage, sortBy).then(
      (res) => {
        setSales(res.data);
        setTotalPages(res.pagination.lastPage);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, [page, perPage, sortBy, moodalOpen]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Effective Date',
        accessor: 'effective_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Asking Price',
        accessor: 'asking_price',
        disableSortBy: true,
        Cell: (cell: any) => (
          <div>
            <span style={{ paddingRight: '5px' }}>
              <NumericFormat
                value={cell.value}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
            </span>
            {cell.row.original.asking_price_curr}
          </div>
        ),
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Type',
        accessor: 'sales_type',
      },
      {
        Header: 'PnC',
        accessor: 'p_and_c',
        Cell: (cell: any) => (
          <FOSTableYesNoCell value={cell.value}></FOSTableYesNoCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openSale(original.row.original.id);
                console.log('opening', original.row.original.id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>Sales candidates</div>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={sales}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
