import { VesselCreateRequest } from '../server-types';
import { CreateVesselForm } from '../state/createVessel';

export const convertToVesselCreatePayload = (
  input: CreateVesselForm
): VesselCreateRequest => {
  const responseObj: VesselCreateRequest = {
    imo: '',
    vessel_name: '',
    vessel_ex_name: '',
    owner: '',
    technical_manager: '',
    design: '',
    vessel_type: '',
    vessel_subtype: '',
    vessel_north_sea_spot: '',
    ship_status: '',
    shipyard: '',
    shipyard_country: '',
    shipyard_region: '',
    controlling_contractor: '',
    built: undefined,
    dwt: '',
    bhp: '',
    bollard_pull: '', // BP
    deck_area: '', // deck
    dp: '',
    accomondation: '',
    beam: '', // only using metres
    draft: '',
    loa: '',
    mooring: '',
    rov: '',
    moonpool: '',
    helideck: '',
    wire_capacity: '',
    a_frame: '',
    deck_strength: '',
    winch: '',
    fifi: '',
    oilrec: '',
    ice_class: '',
    fos_ice_class: '',
    sps: '',
    max_speed: '',
    standby: '',
    survivors: '',
    passengers: '',
    fuel: '',
    methanol: '',
    bulk: '',
    oil_rec: '',
    brine: '',
    mud: '',
    base_oil: '',
    special_products: '',
    ballast_water: '',
    meg: '',
    pot_water: '',
    hull_number: '',
    vessel_class_2: '',
    vessel_class_1: '',
    flag: '',
    contract_date: undefined,
    contract_price_currency: '',
    contract_price: '',
    dive: '',
    tower: '',
    carousel: '',
    lay_spread: '',
    ern: '',
    comments: '',
    crane_size_1: '',
    crane_size_2: '',
    crane_size_3: '',
    vessel_subsea: '',
    w2w: '',
    streamer_capacity: '',
    battery: '',
    shore_power: '',
    dual_fuel: '',
  };

  // Map inputs to the create request
  // Can iterate over because they share the same keys (db fields)

  // for (let key of Object.keys(responseObj)) {
  //   if ((input as any)[key] !== undefined) (responseObj as any)[key] = (input as any)[key];
  // }

  if (input.imo !== undefined) responseObj.imo = input.imo;
  if (input.vessel_name !== undefined)
    responseObj.vessel_name = input.vessel_name;
  if (input.vessel_ex_name !== undefined)
    responseObj.vessel_ex_name = input.vessel_ex_name;
  if (input.owner !== undefined) responseObj.owner = input.owner.value;
  if (input.technical_manager !== undefined)
    responseObj.technical_manager = input.technical_manager.value;

  if (input.design !== undefined) responseObj.design = input.design.value;
  if (input.vessel_type !== undefined)
    responseObj.vessel_type = input.vessel_type.value;
  if (input.vessel_subtype !== undefined)
    responseObj.vessel_subtype = input.vessel_subtype.value;
  if (input.vessel_north_sea_spot !== undefined)
    responseObj.vessel_north_sea_spot = input.vessel_north_sea_spot;
  if (input.ship_status !== undefined)
    responseObj.ship_status = input.ship_status.value;

  if (input.shipyard !== undefined) responseObj.shipyard = input.shipyard.value;
  if (input.shipyard_country !== undefined)
    responseObj.shipyard_country = input.shipyard_country.value;
  if (input.shipyard_region !== undefined)
    responseObj.shipyard_region = input.shipyard_region.value;

  if (input.controlling_contractor !== undefined)
    responseObj.controlling_contractor = input.controlling_contractor.value;
  if (input.built !== undefined) responseObj.built = input.built;
  if (input.dwt !== undefined) responseObj.dwt = input.dwt;
  if (input.bhp !== undefined) responseObj.bhp = input.bhp;
  if (input.bollard_pull !== undefined)
    responseObj.bollard_pull = input.bollard_pull;
  if (input.deck_area !== undefined) responseObj.deck_area = input.deck_area;
  if (input.crane_size_1 !== undefined)
    responseObj.crane_size_1 = input.crane_size_1;
  if (input.crane_size_2 !== undefined)
    responseObj.crane_size_2 = input.crane_size_2;
  if (input.crane_size_3 !== undefined)
    responseObj.crane_size_3 = input.crane_size_3;
  if (input.dp !== undefined) responseObj.dp = input.dp.value;
  if (input.accomondation !== undefined)
    responseObj.accomondation = input.accomondation;
  if (input.beam !== undefined) responseObj.beam = input.beam;
  if (input.draft !== undefined) responseObj.draft = input.draft;
  if (input.loa !== undefined) responseObj.loa = input.loa;
  if (input.mooring !== undefined) responseObj.mooring = input.mooring.value;
  if (input.rov !== undefined) responseObj.rov = input.rov.value;
  if (input.moonpool !== undefined) responseObj.moonpool = input.moonpool;
  if (input.helideck !== undefined) responseObj.helideck = input.helideck;
  if (input.wire_capacity !== undefined)
    responseObj.wire_capacity = input.wire_capacity;
  if (input.a_frame !== undefined) responseObj.a_frame = input.a_frame;
  if (input.deck_strength !== undefined)
    responseObj.deck_strength = input.deck_strength;
  if (input.winch !== undefined) responseObj.winch = input.winch;
  if (input.fifi !== undefined) responseObj.fifi = input.fifi.value;
  if (input.oilrec !== undefined) responseObj.oilrec = input.oilrec.value;
  if (input.ice_class !== undefined)
    responseObj.ice_class = input.ice_class.value;
  if (input.fos_ice_class !== undefined)
    responseObj.fos_ice_class = input.fos_ice_class.value;
  if (input.sps !== undefined) responseObj.sps = input.sps;
  if (input.max_speed !== undefined) responseObj.max_speed = input.max_speed;
  if (input.standby !== undefined) responseObj.standby = input.standby;
  if (input.survivors !== undefined) responseObj.survivors = input.survivors;
  if (input.passengers !== undefined) responseObj.passengers = input.passengers;
  if (input.fuel !== undefined) responseObj.fuel = input.fuel;
  if (input.methanol !== undefined) responseObj.methanol = input.methanol;
  if (input.bulk !== undefined) responseObj.bulk = input.bulk;
  if (input.oil_rec !== undefined) responseObj.oil_rec = input.oil_rec;
  if (input.brine !== undefined) responseObj.brine = input.brine;
  if (input.mud !== undefined) responseObj.mud = input.mud;
  if (input.base_oil !== undefined) responseObj.base_oil = input.base_oil;
  if (input.special_products !== undefined)
    responseObj.special_products = input.special_products;
  if (input.ballast_water !== undefined)
    responseObj.ballast_water = input.ballast_water;
  if (input.meg !== undefined) responseObj.meg = input.meg;
  if (input.pot_water !== undefined) responseObj.pot_water = input.pot_water;
  if (input.hull_number !== undefined)
    responseObj.hull_number = input.hull_number;
  if (input.vessel_class_2 !== undefined)
    responseObj.vessel_class_2 = input.vessel_class_2.value;
  if (input.vessel_class_1 !== undefined)
    responseObj.vessel_class_1 = input.vessel_class_1.value;
  if (input.flag !== undefined) responseObj.flag = input.flag.value;
  if (input.contract_date !== undefined)
    responseObj.contract_date = input.contract_date;
  if (input.contract_price_currency !== undefined)
    responseObj.contract_price_currency = input.contract_price_currency;
  if (input.contract_price !== undefined)
    responseObj.contract_price = input.contract_price;
  if (input.dive !== undefined) responseObj.dive = input.dive.value;
  if (input.tower !== undefined) responseObj.tower = input.tower.value;
  if (input.carousel !== undefined) responseObj.carousel = input.carousel.value;
  if (input.lay_spread !== undefined) responseObj.lay_spread = input.lay_spread;
  if (input.ern !== undefined) responseObj.ern = input.ern;
  if (input.comments !== undefined) responseObj.comments = input.comments;
  if (input.vessel_subsea !== undefined)
    responseObj.vessel_subsea = input.vessel_subsea;
  if (input.w2w !== undefined) responseObj.w2w = input.w2w;
  if (input.streamer_capacity !== undefined)
    responseObj.streamer_capacity = input.streamer_capacity;
  if (input.battery !== undefined)
    responseObj.vessel_subsea = input.vessel_subsea;
  if (input.shore_power !== undefined)
    responseObj.shore_power = input.shore_power;
  if (input.dual_fuel !== undefined) responseObj.dual_fuel = input.dual_fuel;

  return responseObj;
};
