export enum Regions {
  All = 'All',
  NorthSea = 'NorthSea',
  NorthAmerica = 'NorthAmerica',
  SouthAmerica = 'SouthAmerica',
  AtlanticRim = 'AtlanticRim',
  MedBlackSea = 'MedBlackSea',
  CaspianSea = 'CaspianSea',
  BarentsArcticSea = 'BarentsArcticSea',
  Africa = 'Africa',
  MiddleEastIndia = 'MiddleEastIndia',
  AsiaPacific = 'AsiaPacific',
  InTransit = 'InTransit',
  // NorwegianContinentalShelf = 'Norwegian Continental Shelf Continental Shelf'
}

export enum OSVRegions {
  All = 'All',
  // NorthSea = 'North Sea',
  NorthAmerica = 'NorthAmerica',
  SouthAmerica = 'SouthAmerica',
  AtlanticRim = 'AtlanticRim',
  MedBlackSea = 'MedBlackSea',
  CaspianSea = 'CaspianSea',
  BarentsArcticSea = 'BarentsArcticSea',
  Africa = 'Africa',
  MiddleEastIndia = 'MiddleEastIndia',
  AsiaPacific = 'AsiaPacific',
  InTransit = 'InTransit',
  // NorwegianContinentalShelf = 'Norwegian Continental Shelf Continental Shelf'
}

export enum SearchMode {
  Single = 'Single',
  Combined = 'Combined',
  Filtering = 'Filtering',
}
