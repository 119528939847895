import React from 'react';
import { useEffect, useState } from 'react';
import { getLayupList } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';

export const LayUpList = (props: any) => {
  const { tab } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'date_available', desc: false });

  useEffect(() => {
    getLayupList(tab, page, perPage, sortBy, undefined, 'subsea').then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [tab, page, perPage, sortBy]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          />
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Region',
        accessor: 'region',
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>Lay-up List</div>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={data}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
