import React, { useState } from 'react';

interface Props {
  value: string | null;
  name: string;
  label: string;
  isDisabled?: any;
  stateSetter: (name: string, value: any) => void;
}

const FOSBSBoolean = (props: Props) => {
  // Number(null) = 0, and would render invalid results for us, preventing this
  const value =
    props.value !== null && props.value !== '' && !isNaN(Number(props.value))
      ? Number(props.value).toFixed()
      : '';

  return (
    <div className='row'>
      <label className='col-sm-4 col-form-label'>{props.label}</label>
      <div className='col'>
        <div className='form-check form-check-inline pt-2'>
          <input
            className='form-check-input'
            type='checkbox'
            id={`${props.name}-${props.label}-no`}
            value='0'
            checked={value === '0'}
            disabled={props.isDisabled}
            onChange={(e) => {
              props.stateSetter(props.name, e.target.checked ? '0' : null);
            }}
          />
          <label
            className='form-check-label'
            htmlFor={`${props.name}-${props.label}-no`}
          >
            NO
          </label>
        </div>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            id={`${props.name}-${props.label}-yes`}
            value='1'
            checked={value === '1'}
            disabled={props.isDisabled}
            onChange={(e) => {
              props.stateSetter(props.name, e.target.checked ? '1' : null);
            }}
          />
          <label
            className='form-check-label'
            htmlFor={`${props.name}-${props.label}-yes`}
          >
            YES
          </label>
        </div>
      </div>
    </div>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = prevProps.value === nextProps.value;
  return equal;
}

export default React.memo(FOSBSBoolean, areEqual);
