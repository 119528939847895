import React from 'react';
import { NumericFormat } from 'react-number-format';
interface Props {
  label: string;
  value: string | number | null | undefined;
  prefix?: string | undefined;
  onChange(e: any): void;
}

const FOSBSPriceInput = (props: Props) => {
  return (
    <div className='row pb-1'>
      <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
        {props.label}
      </label>
      <div className='col'>
        <NumericFormat
          className='form-control form-select-sm'
          value={props.value}
          prefix={props.prefix}
          onValueChange={(values) => {
            const { value } = values;
            props.onChange(value);
          }}
        />
      </div>
    </div>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = prevProps.value === nextProps.value;
  return equal;
}

export default React.memo(FOSBSPriceInput, areEqual);
