import { Link } from 'react-router-dom';
import { Switch, Route, useRouteMatch } from 'react-router';
import { MainThemeBootstrap } from '../Theme/MainThemeBootstrap';
import { TableSelector } from '../FieldCleaner/TableSelector';
import { UserAdministration } from '../UserAdministration/UserAdministration';
import { WarningOutlined } from '@material-ui/icons';
import { DeleteItems } from '../DeleteItems/DeleteItems';
import { RunJobs } from '../RunJobs/RunJobs';

export const Admin = () => {
  let { path, url } = useRouteMatch();

  return (
    <MainThemeBootstrap isAdmin={true}>
      <Switch>
        <Route exact path={path}>
          <div className='container-fluid'>
            <div className='row pt-3'>
              <div className='col-12'>
                <div className='north-sea-spot-caption'>
                  FOS Live administrator tools
                </div>

                <ul>
                  <li>
                    <Link
                      to={`${url}/fieldcleaner`}
                      className='link-primary'
                      aria-current='page'
                    >
                      Clean similar valued fields
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${url}/useradmin`}
                      className='link-primary'
                      aria-current='page'
                    >
                      User administration
                    </Link>
                  </li>
                  <li>
                    <WarningOutlined
                      style={{ marginRight: 5 }}
                      fontSize='small'
                    />
                    <Link
                      to={`${url}/delete`}
                      className='link-primary'
                      aria-current='page'
                    >
                      Delete Items
                    </Link>
                    <WarningOutlined
                      style={{ marginLeft: 5 }}
                      fontSize='small'
                    />
                  </li>
                  <li>
                    <Link
                      to={`${url}/runJobs`}
                      className='link-primary'
                      aria-current='page'
                    >
                      Run Jobs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Route>

        <Route path={`${path}/fieldcleaner`} component={TableSelector} />
        <Route path={`${path}/useradmin`} component={UserAdministration} />
        <Route path={`${path}/delete`} component={DeleteItems} />
        <Route path={`${path}/runJobs`} component={RunJobs} />
      </Switch>
    </MainThemeBootstrap>
  );
};
