import React, { useState } from 'react';
import { MainThemeBootstrap } from '../Theme/MainThemeBootstrap';
import { MainThemeSideBar } from '../Theme/MainThemeSideBar';
import { Fixtures } from '../NorthSeaSpot/Fixtures';
import { OutstandingRequirements } from '../NorthSeaSpot/OutstandingRequirements';
import { AvailabilityNorway } from '../NorthSeaSpot/AvailabilityNorway';
import { AvailabilityUK } from '../NorthSeaSpot/AvailabilityUK';
import { LayUpList } from '../NorthSeaSpot/LayUpList';
import { FOSBSVesselSearch } from '../common/FOSBSVesselSearch';

export const NorthSeaSpot = () => {
  return (
    <div>
      <MainThemeBootstrap>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <FOSBSVesselSearch></FOSBSVesselSearch>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-10 main-column'>
              <Fixtures></Fixtures>
              <br></br>

              <OutstandingRequirements></OutstandingRequirements>
              <br></br>

              <AvailabilityNorway></AvailabilityNorway>
              <br></br>

              <AvailabilityUK></AvailabilityUK>
              <br></br>

              <LayUpList></LayUpList>
            </div>
            <div className='col-2 sidebar-column'>
              <MainThemeSideBar></MainThemeSideBar>
            </div>
          </div>
        </div>
      </MainThemeBootstrap>
    </div>
  );
};
