export const classesOther = [
  'AB',
  'BR',
  'BV',
  'CC',
  'CR',
  'CS',
  'DS',
  'GL',
  'HR',
  'IR',
  'JR',
  'KC',
  'KI',
  'KR',
  'LR',
  'NK',
  'NV',
  'PR',
  'RC',
  'RI',
  'RN',
  'RP',
  'RR',
  'RS',
  'TL',
  'VR',
  'ZC',
];

export const flagsOther = [
  { code: 'ALB', title: 'Albania' },
  { code: 'ALG', title: 'Algeria' },
  { code: 'ALA', title: 'Angola' },
  { code: 'AGL', title: 'Anguilla' },
  { code: 'ABB', title: 'Antigua &amp; Barbuda' },
  { code: 'ARG', title: 'Argentina' },
  { code: 'AST', title: 'Australia' },
  { code: 'AUS', title: 'Austria' },
  { code: 'AZE', title: 'Azerbaijan' },
  { code: 'BAH', title: 'Bahamas' },
  { code: 'BRN', title: 'Bahrain' },
  { code: 'BNG', title: 'Bangladesh' },
  { code: 'BBD', title: 'Barbados' },
  { code: 'BLG', title: 'Belgium' },
  { code: 'BZE', title: 'Belize' },
  { code: 'BER', title: 'Bermuda' },
  { code: 'BOL', title: 'Bolivia' },
  { code: 'BRZ', title: 'Brazil' },
  { code: 'BRU', title: 'Brunei' },
  { code: 'BUL', title: 'Bulgaria' },
  { code: 'CMB', title: 'Cambodia' },
  { code: 'CMN', title: 'Cameroon' },
  { code: 'CAN', title: 'Canada' },
  { code: 'RCV', title: 'Cape Verde' },
  { code: 'CAY', title: 'Cayman Islands' },
  { code: 'CHI', title: 'Channel Islands' },
  { code: 'CHL', title: 'Chile' },
  {
    code: 'CHR',
    title: "China, People's Republic Of",
  },
  { code: 'CHT', title: 'China, Republic Of (Taiwan)' },
  { code: 'COL', title: 'Colombia' },
  { code: 'CMR', title: 'Comoros' },
  { code: 'CDR', title: 'Congo (Democratic Republic)' },
  { code: 'CKI', title: 'Cook Islands' },
  { code: 'COS', title: 'Costa Rica' },
  {
    code: 'IVC',
    title: "Cote D'ivoire",
  },
  { code: 'CRT', title: 'Croatia' },
  { code: 'CUB', title: 'Cuba' },
  { code: 'CYP', title: 'Cyprus' },
  { code: 'DEN', title: 'Denmark' },
  { code: 'DIS', title: 'Denmark (Dis)' },
  { code: 'DJI', title: 'Djibouti' },
  { code: 'DMA', title: 'Dominica' },
  { code: 'DOM', title: 'Dominican Republic' },
  { code: 'ECU', title: 'Ecuador' },
  { code: 'EGY', title: 'Egypt' },
  { code: 'EQG', title: 'Equatorial Guinea' },
  { code: 'ERT', title: 'Eritrea' },
  { code: 'ETN', title: 'Estonia' },
  { code: 'ETH', title: 'Ethiopia' },
  { code: 'FAR', title: 'Faeroe Islands' },
  { code: 'FAS', title: 'Faeroes (Fas)' },
  { code: 'FAL', title: 'Falkland Islands' },
  { code: 'FIJ', title: 'Fiji' },
  { code: 'FIN', title: 'Finland' },
  { code: 'FRA', title: 'France' },
  { code: 'FIS', title: 'France (Fis)' },
  { code: 'FGU', title: 'French Guiana' },
  { code: 'FPO', title: 'French Polynesia' },
  { code: 'GAB', title: 'Gabon' },
  { code: 'GAM', title: 'Gambia' },
  { code: 'GEO', title: 'Georgia' },
  { code: 'GEU', title: 'Germany' },
  { code: 'GHA', title: 'Ghana' },
  { code: 'GIB', title: 'Gibraltar' },
  { code: 'GRC', title: 'Greece' },
  { code: 'GRN', title: 'Greenland' },
  { code: 'GRE', title: 'Grenada' },
  { code: 'GDL', title: 'Guadeloupe' },
  { code: 'GUA', title: 'Guatemala' },
  { code: 'GUI', title: 'Guinea' },
  { code: 'GNB', title: 'Guinea-Bissau' },
  { code: 'GUY', title: 'Guyana' },
  { code: 'HAI', title: 'Haiti' },
  { code: 'HON', title: 'Honduras' },
  { code: 'HKG', title: 'Hong Kong, China' },
  { code: 'ICL', title: 'Iceland' },
  { code: 'IND', title: 'India' },
  { code: 'IDA', title: 'Indonesia' },
  { code: 'IRN', title: 'Iran' },
  { code: 'IRQ', title: 'Iraq' },
  { code: 'IRP', title: 'Irish Republic' },
  { code: 'IOM', title: 'Isle Of Man' },
  { code: 'ISR', title: 'Israel' },
  { code: 'ITL', title: 'Italy' },
  { code: 'JAM', title: 'Jamaica' },
  { code: 'JPN', title: 'Japan' },
  { code: 'JOR', title: 'Jordan' },
  { code: 'KAZ', title: 'Kazakhstan' },
  { code: 'KEN', title: 'Kenya' },
  { code: 'KIR', title: 'Kiribati' },
  { code: 'KRN', title: 'Korea, North' },
  { code: 'KRS', title: 'Korea, South' },
  { code: 'KUW', title: 'Kuwait' },
  { code: 'LAO', title: 'Laos' },
  { code: 'LAV', title: 'Latvia' },
  { code: 'LEB', title: 'Lebanon' },
  { code: 'LIB', title: 'Liberia' },
  { code: 'LBY', title: 'Libya' },
  { code: 'LTH', title: 'Lithuania' },
  { code: 'LUX', title: 'Luxembourg' },
  { code: 'MGY', title: 'Madagascar' },
  { code: 'MAL', title: 'Malaysia' },
  { code: 'MDV', title: 'Maldive Islands' },
  { code: 'MTA', title: 'Malta' },
  { code: 'MAI', title: 'Marshall Islands' },
  { code: 'MTQ', title: 'Martinique' },
  { code: 'MAU', title: 'Mauritania' },
  { code: 'MTS', title: 'Mauritius' },
  { code: 'MYT', title: 'Mayotte' },
  { code: 'MEX', title: 'Mexico' },
  { code: 'MIC', title: 'Micronesia' },
  { code: 'MOL', title: 'Moldova' },
  { code: 'MGL', title: 'Mongolia' },
  { code: 'MTN', title: 'Montenegro' },
  { code: 'MOR', title: 'Morocco' },
  { code: 'MOZ', title: 'Mozambique' },
  { code: 'MYA', title: 'Myanmar' },
  { code: 'NBA', title: 'Namibia' },
  { code: 'NTH', title: 'Netherlands' },
  { code: 'NEA', title: 'Netherlands Antilles' },
  { code: 'NCL', title: 'New Caledonia' },
  { code: 'NZL', title: 'New Zealand' },
  { code: 'NIC', title: 'Nicaragua' },
  { code: 'NIG', title: 'Nigeria' },
  { code: 'NMI', title: 'Northern Mariana Islands' },
  { code: 'NOR', title: 'Norway' },
  { code: 'NIS', title: 'Norway (Nis)' },
  { code: 'OMN', title: 'Oman' },
  { code: 'PAK', title: 'Pakistan' },
  { code: 'PAN', title: 'Panama' },
  { code: 'PNG', title: 'Papua New Guinea' },
  { code: 'PAR', title: 'Paraguay' },
  { code: 'PER', title: 'Peru' },
  { code: 'PHI', title: 'Philippines' },
  { code: 'POL', title: 'Poland' },
  { code: 'PTG', title: 'Portugal' },
  { code: 'MAR', title: 'Portugal (Mar)' },
  { code: 'PRO', title: 'Puerto Rico' },
  { code: 'QAT', title: 'Qatar' },
  { code: 'REU', title: 'Reunion' },
  { code: 'RUM', title: 'Romania' },
  { code: 'RUS', title: 'Russia' },
  { code: 'SMO', title: 'Samoa' },
  { code: 'PST', title: 'Sao Tome &amp; Principe' },
  { code: 'SAU', title: 'Saudi Arabia' },
  { code: 'SEN', title: 'Senegal' },
  { code: 'SEY', title: 'Seychelles' },
  { code: 'SIE', title: 'Sierra Leone' },
  { code: 'SNG', title: 'Singapore' },
  { code: 'SVK', title: 'Slovakia' },
  { code: 'SLI', title: 'Solomon Islands' },
  { code: 'SOM', title: 'Somalia' },
  { code: 'SAF', title: 'South Africa' },
  { code: 'SPN', title: 'Spain' },
  { code: 'CSR', title: 'Spain (Csr)' },
  { code: 'SRI', title: 'Sri Lanka' },
  { code: 'SKN', title: 'St Kitts &amp; Nevis' },
  { code: 'SPM', title: 'St Pierre &amp; Miquelon' },
  { code: 'SVC', title: 'St Vincent &amp; The Grenadines' },
  { code: 'SUD', title: 'Sudan' },
  { code: 'SNM', title: 'Suriname' },
  { code: 'SWD', title: 'Sweden' },
  { code: 'SWZ', title: 'Switzerland' },
  { code: 'SYR', title: 'Syria' },
  { code: 'TAN', title: 'Tanzania' },
  { code: 'THA', title: 'Thailand' },
  { code: 'ETM', title: 'Timor-Leste' },
  { code: 'TOG', title: 'Togo' },
  { code: 'TON', title: 'Tonga' },
  { code: 'TRI', title: 'Trinidad &amp; Tobago' },
  { code: 'TUN', title: 'Tunisia' },
  { code: 'TRK', title: 'Turkey' },
  { code: 'TUR', title: 'Turkmenistan' },
  { code: 'TKC', title: 'Turks &amp; Caicos Islands' },
  { code: 'TLU', title: 'Tuvalu' },
  { code: 'UKE', title: 'Ukraine' },
  { code: 'UAE', title: 'United Arab Emirates' },
  { code: 'GBI', title: 'United Kingdom' },
  { code: 'USA', title: 'United States Of America' },
  { code: 'UNK', title: 'Unknown' },
  { code: 'URG', title: 'Uruguay' },
  { code: 'VAN', title: 'Vanuatu' },
  { code: 'VEN', title: 'Venezuela' },
  { code: 'VNM', title: 'Vietnam' },
  { code: 'BVI', title: 'Virgin Islands, British' },
  { code: 'AVI', title: 'Virgin Islands, Us' },
  { code: 'WFU', title: 'Wallis &amp; Futuna' },
  { code: 'YEM', title: 'Yemen' },
];

export const regions = [
  { value: 'South America', label: 'South America' },
  { value: 'Middle East/India', label: 'Middle East/India' },
  { value: 'North Sea', label: 'North Sea' },
  { value: 'North America', label: 'North America' },
  { value: 'Asia Pacific', label: 'Asia Pacific' },
  { value: 'Africa', label: 'Africa' },
  { value: 'Med/Black Sea', label: 'Med/Black Sea' },
  { value: 'Barents Sea/Arctic', label: 'Barents Sea/Arctic' },
  { value: 'In Transit', label: 'In Transit' },
  { value: 'Caspian Sea', label: 'Caspian Sea' },
  { value: 'N/A', label: 'N/A' },
  { value: 'Atlantic Rim', label: 'Atlantic Rim' },
  { value: 'Barent Sea/Arctic', label: 'Barent Sea/Arctic' },
  { value: 'Norwegian Continental Shelf', label: 'NCS' },
  { value: 'Norwegian Sea', label: 'Norwegian Sea' },
];

export const currencies = [
  'USD',
  'NOK',
  'EUR',
  'GBP',
  'DKK',
  'AED',
  'ARS',
  'AUD',
  'BEF',
  'bhd',
  'BRL',
  'CAD',
  'CLP',
  'COP',
  'DEM',
  'EGP',
  'ESP',
  'FIM',
  'FRF',
  'GRD',
  'HKD',
  'IDR',
  'ILS',
  'INR',
  'ITL',
  'JOD',
  'JPY',
  'KRW',
  'MAD',
  'MXN',
  'MYR',
  'NLG',
  'NZD',
  'PTE',
  'SAR',
  'SEK',
  'SGD',
  'TRY',
  'TWD',
  'VEB',
  'XDR',
  'YUAN',
  'ZAR',
];
