import React, { useEffect, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { NumericFormat } from 'react-number-format';
import { activeModalType, ModalType } from '../state/modals';
import { fixtureGlobalIdLookupState } from '../state/openFixture';
import { modalEditorOpen } from '../state/modals';
import { getFixtures } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';
import { FOSTableYesNoCell } from '../common/table/FOSTableYesNoCell';

export const Fixtures = (props: any) => {
  //
  const { region } = props;
  const [fixturesLoaded, setFixturesLoaded] = useState(false);
  const [fixtures, setFixtures] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'fixture_date', desc: true });

  const setFixtureGlobalId = useSetRecoilState(fixtureGlobalIdLookupState);
  const [moodalOpen, setModalOpen] = useRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);

  const openFixture = (fixtureGlobalId: string) => {
    setFixtureGlobalId(fixtureGlobalId);
    setModalOpen(true);
    setModalType(ModalType.OpenFixture);
  };

  useEffect(() => {
    if (moodalOpen) {
      return;
    }

    getFixtures(region, page, perPage, sortBy).then(
      (fixturesRes) => {
        setFixtures(fixturesRes.data);
        setTotalPages(fixturesRes.pagination.lastPage);
        setFixturesLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setFixturesLoaded(true);
      }
    );
  }, [region, page, perPage, sortBy, moodalOpen]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Fixture Date',
        accessor: 'fixture_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Charterer',
        accessor: 'charterer',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Period',
        accessor: 'period',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {row.original.period} {row.original.period_type}
          </div>
        ),
      },
      {
        Header: 'Rate',
        accessor: 'currency',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {row.original.currency}
            <span style={{ paddingLeft: '5px' }}>
              <NumericFormat
                value={row.original.rate}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
            </span>
          </div>
        ),
      },
      {
        Header: 'Delivery Port',
        accessor: 'delivery_port',
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'PnC',
        accessor: 'p_and_c',
        Cell: (cell: any) => (
          <FOSTableYesNoCell value={cell.value}></FOSTableYesNoCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openFixture(original.row.original.global_id);
                console.log('opening', original.row.original.global_id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>Fixtures</div>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          data={fixtures}
          columns={columns}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
        ></FOSTable>
      </div>
    </div>
  );
};
