import { atom, RecoilState } from 'recoil';

export enum ModalType {
  CreateValuation,
  CreateFixture,

  createAvailability,
  CreateBudgetIndication,

  UpdateBudgetIndication,
  CreateRequirement,
  CreateVessel,
  CreateSale,
  CreateSalesCandidate,
  CreatePurchaseEnquiry,
  CreateRecentSale,
  OpenValuation,
  OpenVessel,
  OpenVesselUnauthenticated,
  OpenFixture,
  OpenRequirement,
  OpenRecentSale,
  OpenSalesCandidate,
  OpenVesselHistory,
}

export const modalEditorOpen = atom({
  key: 'modalEditorOpenState',
  default: false,
});

export const activeModalType: RecoilState<ModalType> = atom({
  key: 'activeModalTypeState',
  default: ModalType.CreateVessel as ModalType,
});
