const styles = {
  light: 'mapbox://styles/mapbox/light-v10',
  dark: 'mapbox://styles/mapbox/dark-v10',
  basic: 'mapbox://styles/mapbox/basic-v10',
  outdoor: 'mapbox://styles/mapbox/outdoors-v10',
};

export const accessToken =
  'pk.eyJ1IjoibGFyc3NiciIsImEiOiJjbGptd21wa3IwMDVpM2VwamV4cXltM3NhIn0.QBQenlDCYEHH46M1GZiHhw';

export const mapStyle = styles.light;
