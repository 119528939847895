import { MultiSelectOption, MultiSelectOptions } from '../common/form-elements';

export const mapToMultiSelect = (
  value: any
): MultiSelectOptions | undefined => {
  if (value === null || value === '') return undefined;

  var values = value.split(',');

  return values.map((el: any) => ({ label: el, value: el }));
};

export const mapToDropdown = (value: string): MultiSelectOption | undefined => {
  if (value === null) return undefined;
  return {
    label: value,
    value,
  };
};

export const mapToVesselFinder = (
  vesselName: string,
  imo: string
): MultiSelectOption | undefined => {
  if (vesselName === null || imo === null) return undefined;

  return {
    label: `${vesselName} (${imo})`,
    value: { vessel_name: vesselName, imo: imo },
  };
};

export const mapToDate = (value: any): Date | undefined => {
  if (value === null || value === '' || value === undefined) return undefined;
  return new Date(value);
};

export const getMultiSelectValues = (value: MultiSelectOptions) => {
  const values = value.map((el: any) => el.value);

  return values.join();
};
