import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import moment from 'moment';

// Time for all dates should be 00:00
Date.prototype.toJSON = function () {
  const onlyDate = new Date(this.toDateString());
  return moment(onlyDate).format('YYYY-MM-DDTHH:mm:ss.SSS');
};
const element = document.getElementById('root');
const root = createRoot(element!);

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
