import { useState, useEffect } from 'react';
import moment from 'moment';
import { useSetRecoilState } from 'recoil';
import FOSBSDatePicker from '../../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../../common/FOSBSTextAreaInput';
import { getAssetsPath } from '../../core';
import { activeModalType, ModalType } from '../../state/modals';
import { fixtureGlobalIdLookupState } from '../../state/openFixture';
import { EditVesselForm } from '../../state/openVessel';
import { regions } from '../../datas';
import { MultiSelectOptions } from '../../common/form-elements';
import { VesselsPositionMap } from '../../Maps/VesselsPositionMap';
import { uploadVesselImage } from '../../services/fosService';
import { CloudUploadOutlined, EditOutlined } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';

interface Props {
  vessel: any;
  setFormStateElement(field: string, value: any): void;
  form: EditVesselForm | undefined;
}

export const VesselPosition = (props: Props) => {
  const [positionRegions, setPositionRegions] = useState<MultiSelectOptions>(
    []
  );
  const [blobImage, setBlobImage] = useState<String>();
  const [uploadingImage, setUploadingImage] = useState(false);
  const { vessel, form, setFormStateElement } = props;
  const posFound = !!vessel.lat && !!vessel.lon;

  const setModalType = useSetRecoilState(activeModalType);
  const setFixtureGlobalId = useSetRecoilState(fixtureGlobalIdLookupState);

  useEffect(() => {
    // map regions
    setPositionRegions(regions);
  }, []);

  const openFixture = (fixtureGlobalId: string) => {
    if (!fixtureGlobalId) return;

    setFixtureGlobalId(fixtureGlobalId);
    setModalType(ModalType.OpenFixture);
  };

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
        <div className='col-md-12'>
          <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
            Position Info
          </h5>
        </div>

        {vessel.current_charterer_fix_id && (
          <div className='row pb-1'>
            <label
              htmlFor='inputEmail3'
              className='col-sm-4 col-form-label'
            ></label>
            <div className='col'>
              <a
                href='#'
                className='link-dark'
                onClick={() => {
                  openFixture(vessel.current_charterer_fix_id);
                }}
              >
                Open fixture
              </a>
            </div>
          </div>
        )}

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Current Charterer
          </label>
          <div className='col'>
            <FOSBSMultiSelectAsync
              dbFieldName='charterer'
              dbTableName='fixtures'
              name='current_charterer'
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              value={form?.current_charterer}
              isCreatable={true}
              isDisabled
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Next Charterer
          </label>
          <div className='col'>
            <FOSBSMultiSelectAsync
              dbFieldName='charterer'
              dbTableName='fixtures'
              name='next_charterer'
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              value={form?.next_charterer}
              isCreatable={true}
              isDisabled
            />
          </div>
        </div>

        <FOSBSDatePicker
          label={'Date Available'}
          value={form?.date_available}
          stateSetter={(name, value) => {
            setFormStateElement(name, value);
          }}
          name={'date_available'}
          isDisabled
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Port Available
          </label>
          <div className='col'>
            <FOSBSMultiSelectAsync
              dbFieldName='delivery_port'
              dbTableName='fixtures'
              name='port'
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              value={form?.port}
              isCreatable={true}
              isDisabled
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Region
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='region'
              value={form?.region}
              options={positionRegions}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
              isDisabled
            />
          </div>
        </div>

        <FOSBSTextAreaInput
          label={'Workscope'}
          value={form?.fixtureWorkscope}
          onChange={(e) => {
            setFormStateElement('fixtureWorkscope', e.target.value);
          }}
        />

        <div style={{ paddingTop: '1rem' }}>
          <FOSBSTextAreaInput
            label={'Comments'}
            value={form?.fixtureComments}
            onChange={(e) => {
              setFormStateElement('fixtureComments', e.target.value);
            }}
          />
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Updated
          </label>
          <div className='col'>
            <label htmlFor='inputEmail3' className='col-form-label'>
              {moment(form?.updated).format('DD.MM.yyyy HH:mm:ss')}
            </label>
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Updated by
          </label>
          <div className='col'>
            <label htmlFor='inputEmail3' className='col-form-label'>
              {form?.updated_by}
            </label>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
        <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
          Image and Position
        </h5>
        <div style={{ display: 'flex' }}>
          <>
            {uploadingImage ? (
              <p>Updating Image...</p>
            ) : (
              (!!vessel.meta.imageUrl || blobImage) && (
                <div
                  style={{
                    display: 'block',
                    width: 400,
                    maxHeight: 400,
                    marginBottom: 20,
                  }}
                >
                  <img
                    src={`${getAssetsPath()}/${
                      blobImage ?? vessel.meta.imageUrl
                    }`}
                    alt=''
                    style={{ maxWidth: '100%', maxHeight: '400px' }}
                  />
                </div>
              )
            )}
            <input
              type='file'
              accept='image/*'
              id='vesselImageUpload'
              onChange={async (event) => {
                console.log(event.target.files);

                if (event?.target?.files?.length) {
                  setUploadingImage(true);
                  const res = await uploadVesselImage(
                    event.target.files[0],
                    vessel
                  );
                  console.log('res', res);
                  if (res) setBlobImage(res);
                  setUploadingImage(false);
                }
              }}
              style={{ display: 'none' }}
            />
          </>
          <Button
            variant='outlined'
            onClick={() => {
              document.getElementById('vesselImageUpload')?.click();
            }}
            startIcon={
              !!vessel.meta.imageUrl || blobImage ? (
                <EditOutlined />
              ) : (
                <CloudUploadOutlined />
              )
            }
            style={{ height: 40, margin: 20 }}
          >
            {!!vessel.meta.imageUrl || blobImage
              ? 'Edit Image'
              : 'Upload Image'}
          </Button>
        </div>
        {/* <button
            onClick={async () => {
              const res = await uploadVesselImage(vessel.id);
            }}
          >
            Get token
          </button> */}

        {posFound && (
          <VesselsPositionMap
            height={400}
            width={'100%'}
            zoom={[3]}
            center={[Number(vessel.lon), Number(vessel.lat)]}
            vessels={[
              {
                ...vessel,
                coordinates: [Number(vessel.lon), Number(vessel.lat)],
                vessel_type: vessel.vessel_type?.label,
              },
            ]}
          />
        )}

        {!posFound && (
          <div className='alert alert-warning' role='alert'>
            No position found for vessel.
          </div>
        )}
      </div>
    </div>
  );
};
