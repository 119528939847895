import { SetterOrUpdater } from 'recoil';
import FOSBSBoolean from '../../common/FOSBSBoolean';
import FOSBSDatePicker from '../../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../../common/FOSBSTextInput';
import FOSBSVesselFinder from '../../common/FOSBSVesselFinder';
import { CreateFixtureForm } from '../../state/createFixture';
import { useEffect } from 'react';
import moment from 'moment';
import { regions } from '../../datas';
import { createOptionsFromEnum } from '../../util';
import { RequirementTypes } from '../CreateFixtureModal';
import { brokerOptions } from './brokerList';

enum optionsUnitToMomentDuration {
  days = 'days',
  daily = 'days',
  weeks = 'weeks',
  months = 'months',
  monthly = 'months',
  years = 'years',
  yearly = 'years',
}

type optionsUnitStrings = keyof typeof optionsUnitToMomentDuration;

const momentDuration = (key: optionsUnitStrings) => {
  return optionsUnitToMomentDuration[key];
};

export const BudgetIndiationFormUI = ({
  form,
  setForm,
  latestFormState,
}: {
  form: CreateFixtureForm;
  setForm: SetterOrUpdater<CreateFixtureForm>;
  latestFormState: React.MutableRefObject<CreateFixtureForm>;
}) => {
  const updateReDeliveryDate = async () => {
    if (
      !!form &&
      form.deliveryDate !== undefined &&
      form.periodAmountUnit !== undefined &&
      form.periodAmountUnit?.value !== undefined &&
      form.periodAmountValue !== undefined
    ) {
      const newValue = moment(form.deliveryDate)
        .add(form.periodAmountValue, form.periodAmountUnit?.value)
        .toDate();

      if (
        form.reDeliveryDate === undefined ||
        !moment(form.reDeliveryDate).isSame(newValue)
      ) {
        await setForm((form: any) => ({ ...form, reDeliveryDate: newValue }));
      }
    }
  };

  const updateOptionsUntil = async () => {
    if (
      !!form &&
      form.optionDurationUnit !== undefined &&
      form.optionDurationUnit?.value !== undefined &&
      form.optionDurationValue !== undefined &&
      form.reDeliveryDate !== undefined
    ) {
      const unit = form.optionDurationUnit?.value as optionsUnitStrings;
      const duration = momentDuration(unit) as any;

      const newValue = moment(form.reDeliveryDate)
        .add(form.optionDurationValue, duration)
        .toDate();

      if (
        form.optionsUntil === undefined ||
        !moment(form.optionsUntil).isSame(newValue)
      ) {
        await setForm((form: any) => ({
          ...form,
          optionsUntil: newValue,
        }));
      }
    }
  };

  const updatePeriodAmount = async () => {
    if (
      !!form &&
      form.deliveryDate !== undefined &&
      form.reDeliveryDate !== undefined
    ) {
      if (form.periodAmountUnit && form.periodAmountValue) {
        const newValueByUnit = moment(form.reDeliveryDate)
          .diff(form.deliveryDate, form.periodAmountUnit.value)
          .toString();

        if (newValueByUnit === form.periodAmountValue) return;
      }

      const newValueByDays = moment(form.reDeliveryDate)
        .diff(form.deliveryDate, 'days')
        .toString();

      await setForm((form: any) => ({
        ...form,
        periodAmountUnit: { label: 'Days', value: 'days' },
        periodAmountValue: newValueByDays,
      }));
    }
  };

  const updateOptionDuration = () => {
    if (
      !!form &&
      form.optionsUntil !== undefined &&
      form.reDeliveryDate !== undefined
    ) {
      if (form.optionDurationUnit && form.optionDurationValue) {
        const unit = form.optionDurationUnit?.value as optionsUnitStrings;
        const duration = momentDuration(unit) as any;

        const newValueByUnit = moment(form.optionsUntil)
          .diff(form.reDeliveryDate, duration)
          .toString();

        if (newValueByUnit === form.optionDurationValue) return;
      }

      const newValueByDays = moment(form.optionsUntil)
        .diff(form.reDeliveryDate, 'days')
        .toString();

      setForm({
        ...form,
        optionDurationUnit: { label: 'Days', value: 'days' },
        optionDurationValue: newValueByDays,
      });
    }
  };
  useEffect(() => {
    updateReDeliveryDate();
  }, [form?.deliveryDate]);

  useEffect(() => {
    updateReDeliveryDate();
  }, [form?.periodAmountUnit, form?.periodAmountValue]);

  useEffect(() => {
    updateOptionsUntil();
    updatePeriodAmount();
  }, [form?.reDeliveryDate]);

  useEffect(() => {
    updateOptionsUntil();
  }, [form?.optionDurationUnit, form?.optionDurationValue]);

  useEffect(() => {
    updateOptionDuration();
  }, [form?.optionsUntil]);

  const setFormStateElement = async (field: string, value: any) => {
    const newForm = {
      ...latestFormState.current,
      [field]: value,
    };

    setForm(newForm);
  };

  return (
    <div className='row'>
      <div
        className='col-sm-12 col-md-6 col-lg-6 pt-2'
        style={{ backgroundColor: '#bdd3e9' }}
      >
        <div className='col-md-12'>
          <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
            General Info
          </h5>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Vessel Name
          </label>
          <div className='col'>
            <FOSBSVesselFinder
              name={'vessel_name'}
              stateSetter={(name, value) => {
                setFormStateElement('vesselDetails', value);
              }}
              value={form.vesselDetails}
              isMulti={false}
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Connected Imo
          </label>
          <div className='col'>
            {form.vesselDetails !== undefined && (
              <>
                {form.vesselDetails.value.imo === '' && (
                  <p>No vessel selected</p>
                )}
                {form.vesselDetails.value.imo !== '' && (
                  <p>{form.vesselDetails.value.imo}</p>
                )}
              </>
            )}
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Requirement Type
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='requirementType'
              value={form.requirementType}
              options={createOptionsFromEnum(RequirementTypes)}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Subtype
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='requirementSubType'
              value={form.requirementSubType}
              options={[{ label: 'SUBSEA', value: 'SUBSEA' }]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSDatePicker
          label={'Fixture Date'}
          value={form.fixtureDate}
          stateSetter={(name, value) => {
            setFormStateElement(name, value);
          }}
          name={'fixtureDate'}
        />

        <FOSBSDatePicker
          label={'Delivery Date'}
          value={form.deliveryDate}
          stateSetter={(name, value) => {
            setFormStateElement(name, value);
          }}
          name={'deliveryDate'}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Delivery Port
          </label>
          <div className='col'>
            <FOSBSMultiSelectAsync
              dbFieldName='delivery_port'
              dbTableName='fixtures'
              name='deliveryPort'
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              value={form.deliveryPort}
              isCreatable={false}
              isMulti={false}
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Region
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='region'
              value={form.region}
              options={regions}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSTextInput
          label={'Period Amount'}
          value={form.periodAmountValue}
          onChange={(e) => {
            setFormStateElement('periodAmountValue', e.target.value);
          }}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Period Amount Unit
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='periodAmountUnit'
              value={form.periodAmountUnit}
              options={[
                { label: 'Days', value: 'days' },
                { label: 'Weeks', value: 'weeks' },
                { label: 'Months', value: 'months' },
                { label: 'Years', value: 'years' },
                { label: 'Wells', value: 'wells' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSTextInput
          label={'Option Duration'}
          value={form.optionDurationValue}
          onChange={(e) => {
            setFormStateElement('optionDurationValue', e.target.value);
          }}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Option Duration Unit
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='optionDurationUnit'
              value={form.optionDurationUnit}
              options={[
                { label: 'Days', value: 'days' },
                { label: 'Daily', value: 'daily' },
                { label: 'Weeks', value: 'weeks' },
                { label: 'Months', value: 'months' },
                { label: 'Monthly', value: 'monthly' },
                { label: 'Years', value: 'years' },
                { label: 'Yearly', value: 'yearly' },
                { label: 'Wells', value: 'wells' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSTextAreaInput
          label={'Workscope'}
          value={form.workScope}
          onChange={(e) => {
            setFormStateElement('workScope', e.target.value);
          }}
        />

        <FOSBSTextAreaInput
          label={'Comments'}
          value={form.comments}
          onChange={(e) => {
            setFormStateElement('comments', e.target.value);
          }}
        />
      </div>
      <div
        className='col-sm-12 col-md-6 col-lg-6  pt-2'
        style={{ backgroundColor: '#bdd3e9' }}
      >
        <div className='col-md-12'>
          <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
            Details
          </h5>
        </div>

        <FOSBSDatePicker
          label={'Redelivery Date'}
          value={form.reDeliveryDate}
          stateSetter={(name, value) => {
            setFormStateElement(name, value);
          }}
          name={'reDeliveryDate'}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Redelivery Port
          </label>
          <div className='col'>
            <FOSBSMultiSelectAsync
              dbFieldName='delivery_port'
              dbTableName='fixtures'
              name='reDeliveryPort'
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              value={form.reDeliveryPort}
              isCreatable={false}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSDatePicker
          label={'Options Until'}
          value={form.optionsUntil}
          stateSetter={(name, value) => {
            setFormStateElement(name, value);
          }}
          name={'optionsUntil'}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Charterer
          </label>
          <div className='col'>
            <FOSBSMultiSelectAsync
              dbFieldName='charterer'
              dbTableName='fixtures'
              name='charterer'
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              value={form.charterer}
              isCreatable={true}
            />
          </div>
        </div>

        <FOSBSTextInput
          label={'Source'}
          value={form.source}
          onChange={(e) => {
            setFormStateElement('source', e.target.value);
          }}
        />

        <FOSBSTextInput
          label={'Rate'}
          value={form.rate}
          onChange={(e) => {
            setFormStateElement('rate', e.target.value);
          }}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Rate Currency
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='rateCurrency'
              value={form.rateCurrency}
              options={[
                { label: 'USD', value: 'USD' },
                { label: 'DKK', value: 'DKK' },
                { label: 'NOK', value: 'NOK' },
                { label: 'GBP', value: 'GBP' },
                { label: 'EUR', value: 'EUR' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Rate Type
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='rateType'
              value={form.rateType}
              options={[
                { label: 'Per Day', value: 'perday' },
                { label: 'Lumpsum', value: 'lumpsum' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSBoolean
          label={'Show rate as RNR'}
          name={'showRateRNR'}
          value={form.showRateRNR}
          stateSetter={(name: string, value: string) => {
            setFormStateElement(name, value);
          }}
        />

        <FOSBSTextInput
          label={'Option Rate'}
          value={form.optionRate}
          onChange={(e) => {
            setFormStateElement('optionRate', e.target.value);
          }}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Option Rate Currency
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='optionRateCurrency'
              value={form.optionRateCurrency}
              options={[
                { label: 'USD', value: 'USD' },
                { label: 'DKK', value: 'DKK' },
                { label: 'NOK', value: 'NOK' },
                { label: 'GBP', value: 'GBP' },
                { label: 'EUR', value: 'EUR' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Option Rate Type
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='optionRateType'
              value={form.optionRateType}
              options={[
                { label: 'Per Day', value: 'day' },
                { label: 'Lumpsum', value: 'lumpsum' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSTextInput
          label={'Mobilisation Fee Amount'}
          value={form.mobilisationFeeAmount}
          onChange={(e) => {
            setFormStateElement('mobilisationFeeAmount', e.target.value);
          }}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Mobilisation Currency
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='mobilisationFeeAmountCurrency'
              value={form.mobilisationFeeAmountCurrency}
              options={[
                { label: 'USD', value: 'USD' },
                { label: 'DKK', value: 'DKK' },
                { label: 'NOK', value: 'NOK' },
                { label: 'GBP', value: 'GBP' },
                { label: 'EUR', value: 'EUR' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <FOSBSTextInput
          label={'Demob fee'}
          value={form.demobFeeAmount}
          onChange={(e) => {
            setFormStateElement('demobFeeAmount', e.target.value);
          }}
        />

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Demob fee currency
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='demobFeeAmountCurrency'
              value={form.demobFeeAmountCurrency}
              options={[
                { label: 'USD', value: 'USD' },
                { label: 'DKK', value: 'DKK' },
                { label: 'NOK', value: 'NOK' },
                { label: 'GBP', value: 'GBP' },
                { label: 'EUR', value: 'EUR' },
              ]}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>

        <div className='row pb-1'>
          <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
            Broker
          </label>
          <div className='col'>
            <FOSBSMultiSelect
              name='broker'
              value={form.broker}
              options={brokerOptions}
              stateSetter={(name: string, value: string) => {
                setFormStateElement(name, value);
              }}
              isMulti={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
