import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeModalType, ModalType } from '../state/modals';
import { vesselLookupState } from '../state/openVessel';
import { ModalHeader } from './ModalHeader';

interface Props {
  onRequestClose(): void;
}

export const OpenVesselHistoryModal = (props: Props) => {
  const [vessel, setVessel] = useRecoilState(vesselLookupState);
  const setModalType = useSetRecoilState(activeModalType);

  const openVessel = () => {
    setModalType(ModalType.OpenVessel);
  };

  let historyLog = undefined;
  let parseFailure = false;
  try {
    if (vessel === undefined) throw new Error('vessel not found');
    historyLog = JSON.parse(vessel.history_log as string);
  } catch (err) {
    parseFailure = true;
  }

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={`Vessel History - ${vessel!.vessel_name}`}
        contentRight={
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={openVessel}
          >
            Back to Vessel
          </button>
        }
      />
      {parseFailure && (
        <div className='alert alert-warning' role='alert'>
          Unable to parse Vessel Change History
        </div>
      )}
      {!parseFailure && (
        <div>
          {historyLog.reverse().map((record: any) => {
            return (
              <div className='card'>
                <div className='card-body'>
                  <h6 className='card-subtitle mb-2 text-muted'>
                    {record.date} - {record.logType} - By: {record.username}
                  </h6>
                  <ul className='list-group'>
                    {record.changes.map((change: any) => {
                      return (
                        <li className='list-group-item'>
                          <strong>{change.fieldName}</strong> from{' '}
                          <span className='badge bg-secondary'>
                            {change.valueFrom}{' '}
                          </span>
                          to{' '}
                          <span className='badge bg-primary'>
                            {change.valueTo}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                  {!!record.message && (
                    <p className='card-text'>{record.message}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
