import { useRecoilState } from 'recoil';
import { activeModalType, modalEditorOpen, ModalType } from '../state/modals';
import Modal from 'react-modal';
import { CreateValuationModal } from './CreateValuationModal';
import { CreateFixtureModal } from './CreateFixtureModal';
import { OpenVesselModal } from './OpenVesselModal/OpenVesselModal';
import { OpenVesselModalUnauthenticated } from './OpenVesselModalUnauthenticated';
import { OpenVesselHistoryModal } from './OpenVesselHistoryModal';
import { OpenFixtureModal } from './OpenFixtureModal';
import { OpenRequirementModal } from './OpenRequirementModal';
import { CreateRequirementModal } from './CreateRequirementModal';
import { CreateRecentSaleModal } from './CreateRecentSaleModal';
import { CreateSalesCandidateModal } from './CreateSalesCandidateModal';
import { OpenSalesCandidateModal } from './OpenSalesCandidateModal';
import { OpenRecentSaleModal } from './OpenRecentSaleModal';
import { OpenValuationModal } from './OpenValuationModal';
import { CreateVesselModal } from './CreateVesselModal';
import { CreateBudgetIndicationModal } from './BudgetIndicationModal/CreateBudgetIndicationModal';
import { UpdateBudgetIndicationModal } from './BudgetIndicationModal/UpdateBudgetIndicationModal';
import { CreateAvailabilityModal } from './AvailabilityModal/CreateAvailabilityModal';

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)'
  },
  overlay: { zIndex: 1000 },
};

Modal.setAppElement('#root'); // needs to bind to root/app element

export const ModalMother = () => {
  const [open, setOpen] = useRecoilState(modalEditorOpen);
  const [activeType, setActiveType] = useRecoilState(activeModalType);

  const onRequestClose = () => {
    setOpen(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onAfterClose = () => {
    console.log('Goodbye from', activeType);
  };

  const renderActiveModal = (modal: ModalType) => {
    switch (modal) {
      case ModalType.CreateValuation:
        return <CreateValuationModal onRequestClose={onRequestClose} />;
      case ModalType.OpenValuation:
        return <OpenValuationModal onRequestClose={onRequestClose} />;
      case ModalType.CreateFixture:
        return <CreateFixtureModal onRequestClose={onRequestClose} />;
      case ModalType.CreateBudgetIndication:
        return <CreateBudgetIndicationModal onRequestClose={onRequestClose} />;
      case ModalType.createAvailability:
        return <CreateAvailabilityModal onRequestClose={onRequestClose} />;
      case ModalType.UpdateBudgetIndication:
        return <UpdateBudgetIndicationModal onRequestClose={onRequestClose} />;
      case ModalType.OpenFixture:
        return <OpenFixtureModal onRequestClose={onRequestClose} />;
      case ModalType.OpenRequirement:
        return <OpenRequirementModal onRequestClose={onRequestClose} />;
      case ModalType.CreateRequirement:
        return <CreateRequirementModal onRequestClose={onRequestClose} />;
      case ModalType.CreateRecentSale:
        return <CreateRecentSaleModal onRequestClose={onRequestClose} />;
      case ModalType.OpenRecentSale:
        return <OpenRecentSaleModal onRequestClose={onRequestClose} />;
      case ModalType.CreateSalesCandidate:
        return <CreateSalesCandidateModal onRequestClose={onRequestClose} />;
      case ModalType.OpenSalesCandidate:
        return <OpenSalesCandidateModal onRequestClose={onRequestClose} />;
      case ModalType.OpenVessel:
        return <OpenVesselModal onRequestClose={onRequestClose} />;
      case ModalType.OpenVesselUnauthenticated:
        return <OpenVesselModalUnauthenticated onRequestClose={onClose} />;
      case ModalType.OpenVesselHistory:
        return <OpenVesselHistoryModal onRequestClose={onRequestClose} />;
      case ModalType.CreateVessel:
      default:
        return <CreateVesselModal onRequestClose={onRequestClose} />;
    }
  };

  return (
    <Modal
      isOpen={open}
      onAfterClose={onAfterClose}
      shouldCloseOnEsc={true}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <div className='container-fluid'>{renderActiveModal(activeType)}</div>
    </Modal>
  );
};
