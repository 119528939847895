import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeModalType, ModalType } from '../../state/modals';
import { fixtureGlobalIdLookupState } from '../../state/openFixture';
import { vesselLookupState, EditVesselForm } from '../../state/openVessel';
import { FOSTable } from '../../common/table/FOSTable';
import { FOSTableCurrencyCell } from '../../common/table/FOSTableCurrencyCell';
import { FOSTableDateCell } from '../../common/table/FOSTableDateCell';
import { FOSTableTooltipCell } from '../../common/table/FOSTableTooltipCell';
import { FOSTableYesNoCell } from '../../common/table/FOSTableYesNoCell';
import { getLatestFixturesByImo } from '../../services/fosService';

interface Props {
  setFormStateElement(field: string, value: any): void;
  form: EditVesselForm | undefined;
  type?: string;
}

export const VesselLatestFixtures = (props: Props) => {
  const { form, setFormStateElement } = props;

  const setModalType = useSetRecoilState(activeModalType);
  const setFixtureGlobalId = useSetRecoilState(fixtureGlobalIdLookupState);
  const dbVessel = useRecoilValue(vesselLookupState);

  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'fixture_date', desc: true });

  const openFixture = (fixtureGlobalId: string) => {
    setFixtureGlobalId(fixtureGlobalId);
    if (props.type === 'budget_indication') {
      setModalType(ModalType.UpdateBudgetIndication);
    } else {
      setModalType(ModalType.OpenFixture);
    }
  };

  // On mount!
  useEffect(() => {
    if (form === undefined || dbVessel === undefined) return;

    getLatestFixturesByImo(
      dbVessel.imo,
      page,
      perPage,
      sortBy,
      props.type
    ).then(
      (res) => {
        setData(res.data);
        setTotalPages(res.pagination.lastPage);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, [form, page, perPage, sortBy]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Fixture Date',
        accessor: 'fixture_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
      },
      {
        Header: 'Charterer',
        accessor: 'charterer',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Period',
        accessor: 'period',
      },
      {
        Header: 'Rate',
        accessor: 'rate',
        disableSortBy: true,
        Cell: ({ cell, row }: any) => (
          <FOSTableCurrencyCell
            value={cell.value}
            currency={row.original.currency}
          ></FOSTableCurrencyCell>
        ),
      },
      {
        Header: 'Delivery Port',
        accessor: 'delivery_port',
      },
      {
        Header: 'Delivery Date',
        accessor: 'delivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Redelivery Date',
        accessor: 'redelivery_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'P&C',
        accessor: 'p_and_c',
        Cell: (cell: any) => (
          <FOSTableYesNoCell value={cell.value}></FOSTableYesNoCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openFixture(original.row.original.global_id);
                console.log('opening', original.row.original.global_id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <FOSTable
      columns={columns}
      data={data}
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={page}
      perPage={perPage}
      totalPage={totalPages}
      initialSortBy={sortBy}
      onChangeSort={setSortBy}
    ></FOSTable>
  );
};
