import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { downloadFile } from '../../services/fosService';

function downloadBase64File(fileName, base64Url) {
  const downloadLink = document.createElement('a');
  downloadLink.href = base64Url;
  downloadLink.download = fileName;
  downloadLink.click();
}

class PreviewButton extends Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.number.isRequired,
    onlyRenderIfPreviewable: PropTypes.bool,
    uploader: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onlyRenderIfPreviewable: true,
  };

  constructor(props) {
    super(props);

    this.state = { previewable: true };

    const statusEnum = props.uploader.qq.status;

    this._onStatusChange = (id, oldStatus, newStatus) => {
      if (id === this.props.id && !this._unmounted) {
        if (!isPreviewable(newStatus, statusEnum) && this.state.previewable) {
          this.setState({ previewable: false });
        } else if (
          isPreviewable(newStatus, statusEnum) &&
          !this.state.previewable
        ) {
          this.setState({ previewable: true });
        } else if (
          newStatus === statusEnum.DELETED ||
          newStatus === statusEnum.CANCELED
        ) {
          this._unregisterStatusChangeHandler();
        }
      }
    };

    this._onClick = async () => {
      const uuid = this.props.uploader.methods.getUuid(this.props.id);
      const name = this.props.uploader.methods.getName(this.props.id);

      const blob = await downloadFile(uuid);
      var file = window.URL.createObjectURL(blob);

      downloadBase64File(name, file);
      // window.open(file, "_blank");
    };
  }

  componentDidMount() {
    this.props.uploader.on('statusChange', this._onStatusChange);
  }

  componentWillUnmount() {
    this._unmounted = true;
    this._unregisterStatusChangeHandler();
  }

  render() {
    const { children, onlyRenderIfPreviewable, id, uploader, ...elementProps } =
      this.props; // eslint-disable-line no-unused-vars
    const content = children || 'Preview';

    if (this.state.previewable || !onlyRenderIfPreviewable) {
      return (
        <button
          aria-label='preview'
          className={`react-fine-uploader-preview-button ${this.props.className || ''}`}
          disabled={!this.state.previewable}
          onClick={this.state.previewable ? this._onClick : undefined}
          type='button'
          {...elementProps}
        >
          {content}
        </button>
      );
    }

    return null;
  }

  _unregisterStatusChangeHandler() {
    this.props.uploader.off('statusChange', this._onStatusChange);
  }
}

const isPreviewable = (statusToCheck, statusEnum) => {
  return [statusEnum.UPLOAD_SUCCESSFUL].indexOf(statusToCheck) >= 0;
};

export default PreviewButton;
