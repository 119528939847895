import { getAPIPath, getAssetsPath } from '../core';
import { saveAs } from 'file-saver';
import { parse } from 'content-disposition';
import { FOSUser } from '../server-types';

interface sortBy {
  id: string;
  desc: boolean;
}

const MIME: Record<string, string> = {
  json: 'application/json',
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const getFileName = (response: Response) => {
  const header = response.headers.get('content-disposition');
  if (!header) return undefined;

  return decodeURIComponent(parse(header).parameters.filename).replace(
    /:/g,
    '_'
  );
};

export async function getSearchColumns(userId: string, columnKey: string) {
  const response = await fetch(
    getAPIPath() + `/api/search-columns/${userId}/${columnKey}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {},
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function updateSearchColumns(
  userId: string,
  columnKey: string,
  columns: string[]
) {
  const response = await fetch(
    `${getAPIPath()}/api/search-columns/${userId}/${columnKey}`,
    {
      method: 'PUT',
      body: JSON.stringify({ columns }),
      headers: {
        'content-type': 'Application/JSON',
      },
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function searchFixtures(
  searchParams: any[],
  displayFields: string[],
  removedRows: string[],
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/search/fixtures/?${query}`,
    {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'content-type': 'Application/JSON',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        requests: searchParams,
        displayFields,
        removedRows,
      }),
    }
  );

  return await response.json();
}

export async function searchVessels(
  searchParams: readonly any[],
  displayFields: readonly string[],
  removedRows: readonly string[],
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(`${getAPIPath()}/api/search/vessels/?${query}`, {
    method: 'POST',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'content-type': 'Application/JSON',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      requests: searchParams,
      displayFields,
      removedRows,
    }),
  });

  return await response.json();
}

export async function searchValuations(
  searchParams: any[],
  displayFields: string[],
  removedRows: string[],
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/search/valuations/?${query}`,
    {
      method: 'POST',
      credentials: 'include',
      redirect: 'follow',
      headers: {
        'content-type': 'Application/JSON',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        requests: searchParams,
        displayFields,
        removedRows,
      }),
    }
  );

  return await response.json();
}

export async function searchSales(
  searchParams: any[],
  displayFields: string[],
  removedRows: string[],
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(`${getAPIPath()}/api/search/sales/?${query}`, {
    method: 'POST',
    credentials: 'include',
    redirect: 'follow',
    headers: {
      'content-type': 'Application/JSON',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      requests: searchParams,
      displayFields,
      removedRows,
    }),
  });

  return await response.json();
}

export async function getSearchExportData(
  table: string,
  payload: { requests: any[]; displayFields: string[]; removedRows: string[] },
  sortBy: sortBy
) {
  const body = {
    ...payload,
    sortBy,
  };

  const response = await fetch(`${getAPIPath()}/api/export/search/${table}`, {
    method: 'POST',
    headers: {
      'content-type': 'Application/JSON',
    },
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(body),
  });

  return await response.json();
}

export async function getNorthSeaExportData() {
  const response = await fetch(`${getAPIPath()}/api/export/pdf/northsea`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getFixtures(
  region: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  type?: string,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
    type: type?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/fixtures/${region}/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getUnauthenticatedFixtures(
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  type?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/unauthenticated/fixtures/${type || ''}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getFixtureLookup(fixtureId: string) {
  const response = await fetch(
    getAPIPath() + `/api/fixture/lookup/${fixtureId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {},
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function createFixture(fixture: any) {
  const response = await fetch(getAPIPath() + `/api/fixture`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(fixture),
  });

  return await response.json();
}

export async function createAvailability(availability: any) {
  const response = await fetch(getAPIPath() + `/api/availability`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(availability),
  });
  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
}

export async function updateAvailability(availability: any) {
  const response = await fetch(getAPIPath() + `/api/availability`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(availability),
  });
  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
}

export async function updateFixture(fixtureId: string, fixturePatchObj: any) {
  const response = await fetch(getAPIPath() + `/api/fixture/${fixtureId}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(fixturePatchObj),
  });

  return await response.json();
}

export async function deleteFixture(fixtureId: string) {
  const response = await fetch(getAPIPath() + `/api/fixture/${fixtureId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getVesselLookup(vesselId: string) {
  const response = await fetch(
    getAPIPath() + `/api/vessel/lookup/${vesselId}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {},
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

const readFile = async (selectedFile: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const uploadVesselImage = async (
  selectedFile: Blob,
  vessel: {
    global_id: string;
    vessel_name: string;
    meta?: { imageUrl?: string };
    vessel_image: string;
  }
): Promise<String | void> => {
  const tokenRequest = await fetch(getAPIPath() + `/api/sas-token`, {
    method: 'GET',
    credentials: 'include',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
  const readerResult = await readFile(selectedFile);
  const tokenResponse = await tokenRequest.json();
  const imageName = `${vessel.vessel_name}.${selectedFile.type.split('/')[1]}`;
  const blobUri = `${getAssetsPath()}/attachments/${
    vessel.global_id
  } ${imageName}?${tokenResponse.token}`;

  const headers = new Headers();
  headers.append('x-ms-blob-type', 'BlockBlob');
  await fetch(blobUri, {
    method: 'PUT',
    body: readerResult as ArrayBuffer,
    headers,
  });
  await updateVessel(vessel.global_id, [
    {
      field: 'vessel_image',
      oldValue: vessel.vessel_image,
      newValue: imageName,
    },
  ]);
  return `attachments/${vessel.global_id} ${imageName}`;
};

export async function updateVessel(vesselId: any, vesselPatchObj: any) {
  const response = await fetch(getAPIPath() + `/api/vessel/${vesselId}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(vesselPatchObj),
  });

  return await response.json();
}

export async function createVessel(vessel: any) {
  const response = await fetch(getAPIPath() + `/api/vessel`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(vessel),
  });

  return await response.json();
}

export async function deleteVessel(imo: any) {
  const response = await fetch(getAPIPath() + `/api/vessel/${imo}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getAllRequirements(
  region: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  type?: string,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/requirements/${region}/${type || ''}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getAllUnauthenticatedRequirements(
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  type?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/unauthenticated/requirements/${type || ''}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function createRequirement(fixture: any) {
  const response = await fetch(getAPIPath() + `/api/requirement`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(fixture),
  });

  return await response.json();
}

export async function getAvailabilityById(availabilityId: string) {
  const response = await fetch(
    `${getAPIPath()}/api/availability/${availabilityId}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );
  return response.json();
}

export async function deleteAvailabilityById(availabilityId: string) {
  const response = await fetch(
    `${getAPIPath()}/api/availability/${availabilityId}`,
    {
      method: 'DELETE',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );
  if (response.status === 200) return response.json();
  else throw new Error('Error deleting availability');
}

export async function getAvailabilities(
  country: string,
  fixtureType: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/availabilities/nss/${country}/${fixtureType}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getUnauthenticatedAvailabilities(
  country: string,
  fixtureType: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/unauthenticated/availabilities/${country}/${fixtureType}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getSubseaAvailabilities(
  region: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/availabilities/${region}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getOSVAvailabilities(
  region: string,
  fixtureType: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/availabilities/osv/${region}/${fixtureType}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getLayupList(
  region: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  type?: string,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/layup/${region}/${type || ''}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getUnauthenticatedLayupList(
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  type?: string,
  section?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    section: section?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/unauthenticated/layup/${type || ''}?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getVesselsUnderConstruction(
  country: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/vessels/underconstruction/${country}/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getRecentlyDeliveredUnits(
  country: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/vessels/recentlydelivered/${country}/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getRecentSales(
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(`${getAPIPath()}/api/sales/recent/?${query}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getLatestSalesByImo(
  imo: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/vessel/lookup/${imo}/sales/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getLatestFixturesByImo(
  imo: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy,
  type?: string
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
    type: type?.toString() || '',
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/vessel/lookup/${imo}/fixtures/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getAvailabiliesByImo(
  imo: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/availabilities/lookup/${imo}/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getLatestValuationsByImo(
  imo: string,
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/vessel/lookup/${imo}/valuations/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getRecentSale(id: string) {
  const response = await fetch(`${getAPIPath()}/api/sales/recent/${id}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function updateSale(saleId: string, salePatchObj: any) {
  const response = await fetch(getAPIPath() + `/api/sale/${saleId}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(salePatchObj),
  });

  return await response.json();
}

export async function createSale(sale: any, category: string) {
  const response = await fetch(getAPIPath() + `/api/sale/${category}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(sale),
  });

  return await response.json();
}

export async function deleteSale(saleId: string) {
  const response = await fetch(getAPIPath() + `/api/sale/${saleId}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getSalesCandidates(
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(
    `${getAPIPath()}/api/sales/candidates/?${query}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function downloadFile(entityId: string) {
  const response = await fetch(
    `${getAPIPath()}/api/fileuploads/${entityId}/download/`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.blob();
}

export async function getRecentUploads(entityId: string, entityTag: string) {
  const response = await fetch(
    `${getAPIPath()}/api/fileuploads/${entityId}/tag/${entityTag}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function getSpotRatesExcel(year: number) {
  const response = await fetch(
    `${getAPIPath()}/api/export/xlsx/spotrates/${year}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': MIME.json,
        Accept: MIME.xlsx,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  const fileName = getFileName(response) || `export.xlsx`;
  const blob = await response.blob();

  saveAs(blob, fileName);
}

export async function getExcelFromData(
  columns: any,
  raws: any,
  fileName: string
) {
  const body = {
    columns,
    raws,
    fileName,
  };

  const response = await fetch(`${getAPIPath()}/api/export/xlsx`, {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      'content-type': MIME.json,
      Accept: MIME.xlsx,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  const blob = await response.blob();

  saveAs(blob, fileName);
}

export async function getExcelFromTableAll(
  fileName: string,
  columns: any,
  table: string,
  payload: any
) {
  const body = {
    columns,
    table,
    payload,
    fileName,
  };

  const response = await fetch(`${getAPIPath()}/api/export/xlsx/table`, {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    headers: {
      'content-type': MIME.json,
      Accept: MIME.xlsx,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  const blob = await response.blob();

  saveAs(blob, fileName);
}

export async function getSpotUtilization() {
  const response = await fetch(`${getAPIPath()}/api/spotutilization/`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getCurrencies() {
  const response = await fetch(`${getAPIPath()}/api/currency/`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getOilPrices() {
  /* This snippet is to test an alternative API call
    const url = 'https://api.oilpriceapi.com/v1/prices/latest'
    const headers = {
      'Authorization': 'Token 5ef574b0bc93eb0570e81d83858aa575',
      'Content-Type': 'application/json'
    }
    
    const response = await fetch(url, { headers })
  */
  const response = await fetch(
    `www.oil-price.net/widgets/brent_text/gen.php?lang=en`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return response.json();
}

export async function getAllFields(tableName: string) {
  const response = await fetch(`${getAPIPath()}/api/db/fields/${tableName}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getAllUniqeFieldValues(
  tableName: string,
  fieldname: string
) {
  const response = await fetch(
    `${getAPIPath()}/api/db/unique-field-values/${tableName}/${fieldname}`,
    {
      method: 'GET',
      credentials: 'include',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    }
  );

  return await response.json();
}

export async function updateFieldValues(
  table: string,
  field: string,
  newValue: string,
  existingValues: string[]
) {
  const body = {
    table,
    field,
    newValue,
    existingValues,
  };

  const response = await fetch(`${getAPIPath()}/api/db/fieldcleaner/`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getUsers() {
  const response = await fetch(`${getAPIPath()}/api/users`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function createUser(userId: string) {
  const response = await fetch(`${getAPIPath()}/api/user/${userId}`, {
    method: 'POST',
    headers: {
      'content-type': 'Application/JSON',
    },
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function deleteUser(userId: string) {
  const response = await fetch(getAPIPath() + `/api/user/${userId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'Application/JSON',
    },
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function updateUser(fields: FOSUser) {
  const response = await fetch(`${getAPIPath()}/api/user`, {
    method: 'PUT',
    body: JSON.stringify(fields),
    headers: {
      'content-type': 'Application/JSON',
    },
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getValuations(
  currentPage: number,
  perPage: number,
  sortBy: sortBy
) {
  const params = {
    sortBy: JSON.stringify(sortBy),
    currentPage: currentPage.toString(),
    perPage: perPage.toString(),
  };
  const query = new URLSearchParams(params);

  const response = await fetch(`${getAPIPath()}/api/valuations/?${query}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getRecentValuation(id: string) {
  const response = await fetch(`${getAPIPath()}/api/valuation/${id}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function createValuations(valutions: any) {
  const response = await fetch(getAPIPath() + `/api/valuation/bulk`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(valutions),
  });

  return await response.json();
}

export async function updateValuation(id: string, patchObj: any) {
  const response = await fetch(getAPIPath() + `/api/valuation/${id}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(patchObj),
  });

  return await response.json();
}

export async function deleteValuation(id: string) {
  const response = await fetch(getAPIPath() + `/api/valuation/${id}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'content-type': 'Application/JSON',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getShipyardInfo(name: string) {
  const params = { name: JSON.stringify(name) };
  const query = new URLSearchParams(params);

  const response = await fetch(getAPIPath() + `/api/shipyard/?${query}`, {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return await response.json();
}

export async function getMultiselctFieldData(
  tableName: string,
  fieldName: string,
  value: string,
  callback: (data: any) => void
) {
  try {
    const res = await fetch(
      getAPIPath() + `/api/meta/field-search/${tableName}/${fieldName}`,
      {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'content-type': 'Application/JSON',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ searchParam: value }),
      }
    );

    if (res.status !== 200) {
      // TODO: Handle load error
    }

    const generalRes = await res.json();
    const data = generalRes[fieldName];
    callback(data);
  } catch (err) {
    // TODO: Handle error properly
    console.error(err);
  }
}

export async function getExchangeRates(
  fromCurrency: string,
  toCurrency: string,
  date: string,
  amount: number
) {
  try {
    const res = await fetch(
      getAPIPath() +
        `/api/unauthenticated/exchange-rates/${fromCurrency}/${toCurrency}?date=${date}&amount=${amount}`,
      {
        method: 'get', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'content-type': 'Application/JSON',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
    );

    if (res.status !== 200) {
      throw new Error('Error getting exchange rates');
    }

    const generalRes = await res.json();
    return generalRes;
  } catch (err) {
    // TODO: Handle error properly
    console.error(err);
  }
}
