import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  MultiSelectOption,
  MultiSelectOptions,
  vesselBudgetIndication,
} from '../../state/searchVessel';
import { searchFixtures } from '../../services/fosService';
import FOSBSMultiSelectSort from '../../common/FOSBSMultiSelectSort';
import { SearchColumns } from '../search-columns';
import { MainThemeBootstrap } from '../../Theme/MainThemeBootstrap';
import { BarLoader } from 'react-spinners';
import { SearchResults } from '../SearchResults';
import { union } from '../../util';
import { SelectedVesselsDB } from './SelectedVesselsDB';
import { FOSSelectedVessel } from './NewSearch';

const defaultColumns = SearchColumns()
  .Fixture.filter((el) => el.default)
  .map((el) => el.accessor);

const mapToMultiSelect = (rawArr: string[]): MultiSelectOptions => {
  return rawArr.map((el) => {
    return { label: el, value: el };
  });
};

export const BudgetIndication = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searched, setSearched] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(1000000000);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'vessel_name', desc: false });

  const [definedColumns, setDefinedColumns] = useState<MultiSelectOptions>(
    mapToMultiSelect(defaultColumns)
  );

  const [budgetIndicationPossibleColumns] = useState<MultiSelectOptions>([]);

  const [searchResults, setSearchResults] = useRecoilState(
    vesselBudgetIndication
  );

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [removedRows, setRemovedRows] = useState<string[]>([]);

  const getSearchRequest = async () => {
    const imos = await localStorage.getItem(FOSSelectedVessel);
    if (imos) {
      return [
        {
          general: {
            imos: JSON.parse(imos),
            isBudgetIndication: true,
          },
        },
      ];
    } else throw new Error('No imos found');
  };

  useEffect(() => {
    doSearch();
  }, []);

  useEffect(() => {
    if (searched) {
      doSearch();
    }
  }, [page, perPage, sortBy]);

  const doSearch = async () => {
    setLoading(true);

    const displayFields = definedColumns.map((columns) => columns.value);
    const searchRequests = await getSearchRequest();
    const searchResponse = await searchFixtures(
      searchRequests,
      displayFields,
      removedRows,
      page,
      perPage,
      sortBy
    );
    const budgetIndication = searchResponse.fixtures.map((el: any) => {
      return {
        id: `${el.imo}-${el.global_id}`,
        ...el,
      };
    });

    setSearchResults(budgetIndication);

    setLoading(false);
    setSearched(true);
    setTotalRecords(searchResponse.pagination.total);
    setTotalPages(searchResponse.pagination.lastPage);
  };

  const filteredColumns = definedColumns.map((accessor: MultiSelectOption) =>
    SearchColumns().Fixture.find((column) => column.accessor === accessor.value)
  );

  const getPayload = async () => {
    const displayFields = definedColumns.map((columns) => columns.value);
    const searchRequests = await getSearchRequest();
    return {
      requests: searchRequests,
      displayFields,
      removedRows,
    };
  };

  const removeRows = (rows: string[]) => {
    if (rows) {
      setRemovedRows(union(removedRows || [], rows));
    }
  };
  return (
    <MainThemeBootstrap>
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Other
            </h5>
          </div>

          <div className='row'>
            <label
              htmlFor='budgetIndicationSearchColumns'
              className='col-sm-4 col-form-label'
            >
              Displayed Columns
            </label>
            <div className='col'>
              <FOSBSMultiSelectSort
                name='budgetIndicationSearchColumns'
                value={definedColumns}
                options={budgetIndicationPossibleColumns}
                stateSetter={(name: string, value: MultiSelectOptions) => {
                  setDefinedColumns(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div
          className='col-md-12'
          style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}
        >
          <button
            className='btn btn-success'
            style={{ marginRight: 5 }}
            onClick={() => {
              doSearch();
            }}
          >
            Search
          </button>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row pt-3 ' style={{ minHeight: '400px' }}>
          <div className='col-sm-12 col-md-12 col-lg-12'>
            <BarLoader
              loading={loading}
              height={4}
              width={200}
              cssOverride={{ display: 'block', margin: 'auto' }}
            />

            {searched && !loading && (
              <span className='btn btn-light mb-3'>
                Matches{' '}
                <span className='badge' style={{ backgroundColor: '#2B5B5F' }}>
                  {totalRecords}
                </span>
              </span>
            )}

            {searched && !loading && selectedRowIds.length !== 0 && (
              <a
                style={{ marginBottom: '15px', marginLeft: '10px' }}
                className='btn btn-outline-danger btn-sm'
                href='#'
                role='button'
                onClick={() => {
                  removeRows(selectedRowIds);
                }}
              >
                Remove rows
              </a>
            )}

            {searched && !loading && (
              <SearchResults
                data={searchResults}
                columns={filteredColumns}
                onRowSelectStateChange={setSelectedRowIds}
                setPage={setPage}
                setPerPage={setPerPage}
                page={page}
                perPage={perPage}
                totalPages={totalPages}
                sortBy={sortBy}
                setSortBy={setSortBy}
                onChangeSort={setSortBy}
                fileName={'search-budget-indication'}
                exportTable={'fixture'}
                exportRowKey={'id'}
                getPayload={getPayload}
                allowExportSelected={false}
              />
            )}
          </div>
        </div>
      </div>
    </MainThemeBootstrap>
  );
};
