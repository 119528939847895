import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  getNorthSeaExportData,
  getSearchExportData,
} from '../services/fosService';
import moment from 'moment';

export const exportSearchTable = async (
  table: string,
  headers: any[],
  payload: any,
  sortBy: any
) => {
  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'landscape'; // portrait or landscape

  const today = moment().format('DD.MM.yyyy');
  const capitalizedTable = table[0].toUpperCase() + table.substring(1);
  const title = `${capitalizedTable} List`;

  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);

  const logo = new Image();
  logo.src = `/img/search_logo_${table}_list.png`;

  doc.addImage(logo, 'PNG', 40, 25, 400, 22);
  doc.text(`${today}`, 725, 40);

  const exportData = await getSearchExportData(table, payload, sortBy);

  const exportTable = {
    head: headers,
    body: exportData,
    startY: 100,
    pageBreak: 'auto' as const,
    margin: { top: 60 },
    headStyles: {
      fillColor: '#142E3D',
    },
    didDrawPage({ settings, pageNumber }: any) {
      // Header
      const currentPage = doc.getNumberOfPages();

      // Footer
      const str = `Page ${currentPage}`;
      doc.setFontSize(10);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(str, settings.margin.left, pageHeight - 10);
    },
  };

  exportData.length && autoTable(doc, exportTable);

  doc.save(`${title} - ${today}.pdf`);
};

export const exportNorthSeaAvailabilitiesPDF = async () => {
  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'landscape'; // portrait or landscape

  const today = moment().format('DD.MM.yyyy');
  const title = `North Sea Availability Report`;

  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);

  const logo = new Image();
  logo.src = '/img/logo-pdf.png';

  doc.addImage(logo, 'PNG', 40, 25, 400, 22);
  doc.text(`${today}`, 725, 40);

  const exportData = await getNorthSeaExportData();

  const availabilityAHTSHeaders = [
    {
      vessel_name: 'Vessel Name',
      bhp: 'BHP',
      bollard_pull: 'BP',
      deck_area: 'DECK',
      rov: 'ROV',
      current_charterer: 'Current Charterer',
      next_charterer: 'Next Charterer',
      workscope: 'Workscope',
      port: 'Port Available',
      date_available: 'Date Available',
    },
  ];

  const availabilityPsvHeaders = [
    {
      vessel_name: 'Vessel Name',
      bhp: 'BHP',
      dwt: 'DWT',
      deck_area: 'DECK',
      current_charterer: 'Current Charterer',
      next_charterer: 'Next Charterer',
      workscope: 'Workscope',
      port: 'Port Available',
      date_available: 'Date Available',
    },
  ];

  const psvNorwayAvailabilities = generateAutotable(
    doc,
    availabilityPsvHeaders,
    exportData.psvNorwayAvailabilities,
    'PSV Norway'
  );
  const ahtsUKAvailabilities = generateAutotable(
    doc,
    availabilityAHTSHeaders,
    exportData.ahtsUKAvailabilities,
    'AHTS UK'
  );
  const psvUKAvailabilities = generateAutotable(
    doc,
    availabilityPsvHeaders,
    exportData.psvUKAvailabilities,
    'PSV UK'
  );

  const ahtsNorwayAvailabilities = generateAutotable(
    doc,
    availabilityAHTSHeaders,
    exportData.ahtsNorwayAvailabilities,
    'AHTS Norway'
  );
  ahtsNorwayAvailabilities.startY = 100;
  ahtsNorwayAvailabilities.pageBreak = 'auto';
  ahtsNorwayAvailabilities.didDrawPage = ({ settings, pageNumber }: any) => {
    // Header
    const currentPage = doc.getNumberOfPages();

    if (pageNumber === 1) {
      doc.setFontSize(15);
      doc.text('AHTS Norway', settings.margin.left, 80);

      // Footer
      const str = `Page ${currentPage}`;
      doc.setFontSize(10);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      doc.text(str, settings.margin.left, pageHeight - 10);
    } else {
      doc.deletePage(currentPage);
    }
  };

  exportData.ahtsNorwayAvailabilities.length &&
    autoTable(doc, ahtsNorwayAvailabilities);
  exportData.psvNorwayAvailabilities.length &&
    autoTable(doc, psvNorwayAvailabilities);
  exportData.ahtsUKAvailabilities.length &&
    autoTable(doc, ahtsUKAvailabilities);
  exportData.psvUKAvailabilities.length && autoTable(doc, psvUKAvailabilities);

  doc.save(`${title} - ${today}.pdf`);
};

const generateAutotable = (
  document: jsPDF,
  headers: Array<any>,
  body: Array<any>,
  tableName: string
): any => {
  const result = {
    head: headers,
    body: body,
    startY: null,
    pageBreak: 'always' as const,
    margin: { top: 60 },
    headStyles: {
      fillColor: '#142E3D',
    },
    didDrawPage({ settings, pageNumber }: any) {
      // Header
      const currentPage = document.getNumberOfPages();

      if (pageNumber === 1) {
        document.setFontSize(15);
        document.text(tableName, settings.margin.left, 40);

        // Footer
        const str = `Page ${currentPage}`;
        document.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        const pageSize = document.internal.pageSize;
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        document.text(str, settings.margin.left, pageHeight - 10);
      } else {
        document.deletePage(currentPage);
      }
    },
    didParseCell: function ({ row, cell, column }: any) {
      if (
        row.section === 'body' &&
        (row.raw.date_available === 'Prompt' ||
          row.raw.date_available === 'Today')
      ) {
        row.index % 2
          ? (cell.styles.fillColor = '#bdddf4')
          : (cell.styles.fillColor = '#cde3f2');
      }
    },
  };

  return result;
};
