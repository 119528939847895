import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  label: string;
  value: string | undefined;
  onChange(e: any): void;
  required?: string;
}

const FOSBSValidatableTextInput = ({
  label,
  value,
  onChange,
  required,
}: Props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className='row pb-1'>
      <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
        {label}
      </label>
      <div className='col'>
        <input
          type='text'
          className='form-control form-select-sm'
          {...register(label, { required, value: value })}
          onChange={onChange}
        />
        {errors && errors[label] && (
          <p className='form-validation-error-message'>
            {errors[label]?.message as string}
          </p>
        )}
      </div>
    </div>
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = prevProps.value === nextProps.value;
  return equal;
}

export default React.memo(FOSBSValidatableTextInput, areEqual);
