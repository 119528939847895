import { atom, RecoilState } from 'recoil';
import { CreateValuationForm } from './createValuation';

export const valuationGlobalId: RecoilState<string> = atom({
  key: 'valuationGlobalId',
  default: '',
});

export const valuationState: RecoilState<EditValuationForm | undefined> = atom({
  key: 'valuationState',
  default: undefined as EditValuationForm | undefined,
});

export const valuationEditFormState: RecoilState<
  EditValuationForm | undefined
> = atom({
  key: 'valuationEditFormState',
  default: undefined as EditValuationForm | undefined,
});

export interface EditValuationForm extends CreateValuationForm {
  id: string;
  imo: string | null;
  updated: Date | undefined;
  updatedBy: string;
}
