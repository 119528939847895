import React from 'react';
import { useEffect, useState } from 'react';
import { getSubseaAvailabilities } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';
import { availabilityDateChecker } from '../util';
import { NumericFormat } from 'react-number-format';
import { FOSTableNextChartererCell } from '../common/table/FOSTableNextChartererCell';
export const SubseaAvailabilities = (props: any) => {
  const { tab } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'date_available', desc: false });

  useEffect(() => {
    getSubseaAvailabilities(tab, page, perPage, sortBy).then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [tab, page, perPage, sortBy]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'LOA (M)',
        accessor: 'loa',
      },
      {
        Header: 'DECK',
        accessor: 'deck_area',
        Cell: ({ value }: any) => (
          <span>
            <NumericFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        Header: 'Crane',
        accessor: 'crane',
        disableSortBy: true,
        Cell: ({ row }: any) => (
          <div>
            {[
              row.original.crane_size_1,
              row.original.crane_size_2,
              row.original.crane_size_3,
            ]
              .filter((x) => x)
              .join(', ')}
          </div>
        ),
      },
      {
        Header: 'BEDS',
        id: 'accomondation',
        accessor: 'accomondation',
      },
      {
        Header: 'Current Charterer',
        accessor: 'current_charterer',
      },
      {
        Header: 'Next Charterer',
        accessor: 'next_charterer',
        Cell: ({ row }: any) => (
          <FOSTableNextChartererCell
            currenCharterer={row.original.current_charterer}
            nextCharterer={row.original.next_charterer}
            currentChartererDate={row.original.current_charterer_date}
            nextChartererDate={row.original.next_charterer_date}
          />
        ),
      },
      {
        Header: 'Controlling Contractor',
        accessor: 'controlling_contractor',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Region',
        accessor: 'region',
      },
      {
        Header: 'Date Available',
        accessor: 'date_available',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>Subsea Availability</div>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={data}
          isHighlightedRow={(row: any) =>
            availabilityDateChecker(row.date_available)
          }
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
