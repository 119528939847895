import React, { useState } from 'react';
import { MainThemeBootstrap } from '../Theme/MainThemeBootstrap';
import { MainThemeSideBar } from '../Theme/MainThemeSideBar';
import { Fixtures } from '../Subsea/Fixtures';
import { OutstandingRequirements } from '../Subsea/OutstandingRequirements';
import { SubseaAvailabilities } from '../Subsea/SubseaAvailabilities';
import { LayUpList } from '../Subsea/LayUpList';
import { Regions } from '../common/types';
import { FOSBSVesselSearch } from '../common/FOSBSVesselSearch';
import { NavLink } from 'react-router-dom';

export const Subsea = () => {
  const [tab, setTab] = useState<Regions>(Regions.All);

  return (
    <div>
      <MainThemeBootstrap>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <FOSBSVesselSearch></FOSBSVesselSearch>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-10 main-column'>
              <ul className='nav nav-tabs' style={{ marginBottom: '20px' }}>
                {Object.keys(Regions).map((key, idx) => {
                  return (
                    <li className='nav-item'>
                      <NavLink
                        to={{ pathname: '/subsea', search: `region=${key}` }}
                        className='nav-link'
                        isActive={(_, location) => {
                          if (location.search === `?region=${key}`) {
                            setTab(key as Regions);
                            return true;
                          }

                          return false;
                        }}
                      >
                        {(Regions as any)[key]}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>

              <Fixtures tab={tab}></Fixtures>
              <br></br>

              <OutstandingRequirements tab={tab}></OutstandingRequirements>
              <br></br>

              <SubseaAvailabilities tab={tab}></SubseaAvailabilities>
              <br></br>

              <LayUpList tab={tab}></LayUpList>
            </div>

            <div className='col-2 sidebar-column'>
              <MainThemeSideBar></MainThemeSideBar>
            </div>
          </div>
        </div>
      </MainThemeBootstrap>
    </div>
  );
};
