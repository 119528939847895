import React, { useEffect } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';

interface sortBy {
  id: string;
  desc: boolean;
}

interface FOSTalbeProps {
  initialSortBy: sortBy;
  onChangeSort: Function;
  setPerPage: Function;
  setPage: Function;
  currentpage: number;
  perPage: number;
  totalPage: number;
  columns: Array<any>;
  data: any;
  isHighlightedRow?: Function;
}

export const FOSTable = (props: FOSTalbeProps) => {
  const {
    setPerPage,
    setPage,
    columns,
    data,
    currentpage,
    perPage,
    totalPage,
    isHighlightedRow,
    initialSortBy,
    onChangeSort,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage - 1,
          }),
          [state, currentpage]
        );
      },
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
      initialState: { pageIndex: currentpage, sortBy: [initialSortBy] },
      manualPagination: true,
      pageCount: totalPage,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    onChangeSort(sortBy[0]);
  }, [onChangeSort, sortBy]);

  const highlight = (row: any) =>
    isHighlightedRow && isHighlightedRow(row.original as any)
      ? 'available'
      : '';

  const resetPageIndex = () => setPage(1);

  return (
    <div>
      <div className='table-wrapper'>
        <table {...getTableProps()} className='fl-table' id='bigTable'>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{}}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={highlight(row)} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (cell.column.id === 'built') {
                      const dateForm = new Date(cell.value);
                      return <td>{dateForm.getFullYear()}</td>;
                    }

                    return (
                      <td {...cell.getCellProps()} style={{}}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='af_code_pagination pt-3'>
        <button
          className='btn btn-default btn-sm'
          onClick={() => {
            setPage(1);
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>{' '}
        <button
          className='btn btn-default btn-sm'
          onClick={() => {
            setPage((s: number) => (s === 1 ? 1 : s - 1));
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          className='btn btn-default btn-sm'
          onClick={() => {
            setPage((s: number) => s + 1);
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        <button
          className='btn btn-default btn-sm'
          onClick={() => {
            setPage(totalPage);
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type='number'
            defaultValue={pageIndex}
            min='1'
            max={totalPage}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 1;
              page <= totalPage ? setPage(page) : setPage(totalPage);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={perPage}
          onChange={(e) => {
            setPerPage(
              Number(e.target.value === 'All' ? 4500 : e.target.value)
            );
            resetPageIndex();
          }}
        >
          {[10, 20, 30, 40, 50, 'All'].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
