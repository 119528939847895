import React from 'react';
import { useEffect, useState } from 'react';
import { getVesselsUnderConstruction } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableVesselLinkCell } from '../common/table/FOSTableVesselLinkCell';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableDateCell } from '../common/table/FOSTableDateCell';

export const VesselsUnderConstruction = (props: any) => {
  const { tab } = props;
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'built', desc: false });

  useEffect(() => {
    getVesselsUnderConstruction(tab, page, perPage, sortBy).then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [tab, page, perPage, sortBy]);

  const resetPage = () => {
    setPage(1);
  };

  useEffect(() => {
    resetPage();
  }, [tab]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Built',
        accessor: 'build_date',
        Cell: (cell: any) => (
          <FOSTableDateCell value={cell.value}></FOSTableDateCell>
        ),
      },
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkCell>
        ),
      },
      {
        Header: 'Owner',
        accessor: 'owner',
      },
      {
        Header: 'Vessel Type',
        accessor: 'vessel_type',
      },
      {
        Header: 'Shipyard Country',
        accessor: 'shipyard_country',
      },
      {
        Header: 'Shipyard',
        accessor: 'shipyard',
      },
      {
        Header: 'Contract Price',
        accessor: 'contract_price',
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
    ],
    []
  );

  return (
    <div>
      <div className='north-sea-spot-caption'>VESSELS UNDER CONSTRUCTION</div>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={data}
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
