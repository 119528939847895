import { MainThemeBootstrap } from '../../Theme/MainThemeBootstrap';
import { MainThemeSideBar } from '../../Theme/MainThemeSideBar';
import { RecentValuations } from '../../Valuation/RecentValuations';
import { FOSBSVesselSearch } from '../../common/FOSBSVesselSearch';

export const Valuation = () => {
  return (
    <div>
      <MainThemeBootstrap>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <FOSBSVesselSearch></FOSBSVesselSearch>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-10 main-column'>
              <RecentValuations></RecentValuations>
              <br></br>
            </div>

            <div className='col-2 sidebar-column'>
              <MainThemeSideBar></MainThemeSideBar>
            </div>
          </div>
        </div>
      </MainThemeBootstrap>
    </div>
  );
};
