import { useState, useRef, useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import moment from 'moment';
import { activeModalType, ModalType } from '../state/modals';
import {
  EditFixtureForm,
  fixtureEditFormState,
  fixtureGlobalIdLookupState,
  fixtureLookupState,
} from '../state/openFixture';
import { ModalHeader } from './ModalHeader';
import FOSBSBoolean from '../common/FOSBSBoolean';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import { MultiSelectOptions } from '../common/form-elements';
import { regions } from '../datas';
import {
  convertDbFixtureToEditFixtureForm,
  convertToFixtureEditPayload,
} from '../mapper/fixture-edit';
import { toast } from 'react-toastify';
import { modalEditorOpen } from '../state/modals';
import { createOptionsFromEnum, shallowFormDiff } from '../util';
import {
  getFixtureLookup,
  updateFixture,
  deleteFixture,
} from '../services/fosService';
import { RequirementTypes } from './CreateFixtureModal';

interface Props {
  onRequestClose(): void;
}

const generateFixtureEditState = (oldFixture: any, newFixture: any) => {
  if (oldFixture === undefined || newFixture === undefined) {
    return null;
  }

  const oldPayload = convertToFixtureEditPayload(oldFixture);
  const newPayload = convertToFixtureEditPayload(newFixture);

  const stateChange = shallowFormDiff(oldPayload, newPayload);

  return stateChange;
};

export const OpenRequirementModal = (props: Props) => {
  const [positionRegions, setPositionRegions] = useState<MultiSelectOptions>(
    []
  );
  const [lookupGlobalId, setLookupGlobalId] = useRecoilState(
    fixtureGlobalIdLookupState
  );

  const setModalOpen = useSetRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);

  const [fixture, setFixture] = useRecoilState(fixtureLookupState);
  const [form, setForm] = useRecoilState(fixtureEditFormState);
  const latestFormState = useRef(form);

  const resetFixture = useResetRecoilState(fixtureLookupState);
  const resetForm = useResetRecoilState(fixtureEditFormState);

  let stateChange = generateFixtureEditState(fixture, form);
  const untouched = stateChange === undefined || stateChange === null;

  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  useEffect(() => {
    const loadData = async () => {
      const lookupResp = await getFixtureLookup(lookupGlobalId);
      const fixture = convertDbFixtureToEditFixtureForm(lookupResp[0]);

      setForm(fixture);
      setFixture(fixture);

      // map regions
      setPositionRegions(regions);
    };

    loadData();
  }, [lookupGlobalId]);

  const setFormStateElement = (field: string, value: any) => {
    const latest = latestFormState.current as EditFixtureForm;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
    latestFormState.current = newForm;
  };

  const doUpdate = async () => {
    stateChange = generateFixtureEditState(fixture, latestFormState.current);
    if (stateChange === undefined) return;

    await updateFixture(lookupGlobalId, stateChange);

    toast.success('Fixture has been updated');
    resetForm();
    resetFixture();
    setModalOpen(false);
  };

  const doCreate = async () => {
    setModalType(ModalType.CreateFixture);
  };

  const doDelete = async () => {
    const r = window.confirm('Are you sure you want to delete requirement?');
    if (!r) return;

    const response = await deleteFixture(lookupGlobalId);

    if (response.status) {
      toast.success('Requirement has been deleted');
      resetForm();
      resetFixture();
      setModalOpen(false);
    } else {
      toast.error(response.message);
    }
  };

  if (form === undefined) return <div></div>;

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={`Edit Fixture`}
      />
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Requirement Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='requirementType'
                value={form.requirementType}
                options={createOptionsFromEnum(RequirementTypes)}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Subtype
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='requirementSubType'
                value={form.requirementSubType}
                options={[{ label: 'SUBSEA', value: 'SUBSEA' }]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSDatePicker
            label={'Delivery Date'}
            value={form.deliveryDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'deliveryDate'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Delivery Port
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='delivery_port'
                dbTableName='fixtures'
                name='deliveryPort'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.deliveryPort}
                isCreatable={false}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Region
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='region'
                value={form.region}
                options={positionRegions}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Period Amount'}
            value={form.periodAmountValue}
            onChange={(e) => {
              setFormStateElement('periodAmountValue', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Period Amount Unit
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='periodAmountUnit'
                value={form.periodAmountUnit}
                options={[
                  { label: 'days', value: 'days' },
                  { label: 'years', value: 'years' },
                  { label: 'months', value: 'months' },
                  { label: 'weeks', value: 'weeks' },
                  { label: 'wells', value: 'wells' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'Option Duration'}
            value={form.optionDurationValue}
            onChange={(e) => {
              setFormStateElement('optionDurationValue', e.target.value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Option Duration Unit
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='optionDurationUnit'
                value={form.optionDurationUnit}
                options={[
                  { label: 'monthly', value: 'monthly' },
                  { label: 'years', value: 'years' },
                  { label: 'yearly', value: 'yearly' },
                  { label: 'days', value: 'days' },
                  { label: 'months', value: 'months' },
                  { label: 'dayly', value: 'dayly' },
                  { label: 'wells', value: 'wells' },
                  { label: 'daily', value: 'daily' },
                  { label: 'weeks', value: 'weeks' },
                ]}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextAreaInput
            label={'Workscope'}
            value={form.workScope}
            onChange={(e) => {
              setFormStateElement('workScope', e.target.value);
            }}
          />

          <FOSBSTextAreaInput
            label={'Comments'}
            value={form.comments}
            onChange={(e) => {
              setFormStateElement('comments', e.target.value);
            }}
          />
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Details
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Charterer
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='charterer'
                dbTableName='fixtures'
                name='charterer'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                value={form.charterer}
                isCreatable={true}
              />
            </div>
          </div>

          <FOSBSBoolean
            label={'P&C'}
            name={'privateAndConfidential'}
            value={form.privateAndConfidential}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Updated
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {moment(form.updated).format('DD.MM.yyyy HH:mm:ss')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='btn btn-success btn'
            disabled={untouched}
            onClick={() => {
              doUpdate();
            }}
          >
            Update
          </button>

          <button
            type='button'
            style={{ marginLeft: 10 }}
            className='btn btn-warning btn'
            onClick={() => {
              doCreate();
            }}
          >
            Create fixture
          </button>
        </div>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-danger btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              doDelete();
            }}
          >
            Delete requirement
          </button>
        </div>
      </div>
    </div>
  );
};
