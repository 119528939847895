import { atom, RecoilState, selector } from 'recoil';

//#region TYPES (TODO: Move)
export interface MultiSelectFormState {
  selectedValues: any[];
  textValue: string;
}

export interface MultiSelectOption {
  label: string;
  value: any;
}

export type MultiSelectOptions = MultiSelectOption[];

export interface RangeFormState {
  from: string;
  to: string;
}

//#endregion

//#region Form Interfaces

export enum FormPart {
  General,
}

export interface GeneralForm {
  client: string;
  effectiveDateFrom: Date | null;
  effectiveDateTo: Date | null;
  imoNumber: string;
  vesselName: string;
  groupOwner: string;
  issuedTo: MultiSelectOptions;
  vesselSubType: MultiSelectOptions;
  shipyard: MultiSelectOptions;
  builtFrom: Date | null;
  builtTo: Date | null;
  DWT: RangeFormState;
  BHP: RangeFormState;
  dive: MultiSelectOptions;
}

// --- Default vals ---

export const defaultValuationGeneralForm: GeneralForm = {
  client: '',
  effectiveDateFrom: null,
  effectiveDateTo: null,
  imoNumber: '',
  vesselName: '',
  groupOwner: '',
  issuedTo: [],
  vesselSubType: [],
  shipyard: [],
  builtFrom: null,
  builtTo: null,
  DWT: { from: '', to: '' },
  BHP: { from: '', to: '' },
  dive: [],
};

// --- Recoil States ---

export const valuationSearchGeneralForm: RecoilState<GeneralForm> = atom({
  key: 'valuationSearchGeneralForm',
  default: defaultValuationGeneralForm,
});

export const valuationSearchResults: RecoilState<any> = atom({
  key: 'valuationSearchResults',
  default: [],
});

export const keptValuationSearchResults: RecoilState<any> = atom({
  key: 'valuationSearchResults',
  default: [],
});

export const forceValuationSearchState = atom({
  key: 'forceValuationSearchState',
  default: false,
});

//#endregion
