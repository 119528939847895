interface TooltipProps {
  value: any;
  iconOnly?: boolean;
  textLength?: number;
}

export const FOSTableTooltipCell = (props: TooltipProps) => {
  const { value, textLength = 20, iconOnly: tooltipOnly } = props;

  if (!value) return <div></div>;

  if (tooltipOnly) {
    return (
      <div data-bs-toggle='tooltip' data-bs-html='true' title={value}>
        <div>
          <i className='fas fa-mouse fa-lg'></i>
        </div>
      </div>
    );
  }

  let tooltip;
  if (value.length > 50) {
    tooltip = (
      <div data-bs-toggle='tooltip' data-bs-html='true' title={value}>
        <div>{' ' + value.substring(0, textLength) + '...'}</div>
      </div>
    );
  } else {
    tooltip = <div>{value}</div>;
  }

  return tooltip;
};
