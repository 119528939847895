import React from 'react';
import { useEffect, useState } from 'react';
import { getUnauthenticatedAvailabilities } from '../services/fosService';
import { FOSTable } from '../common/table/FOSTable';
import { FOSTableTooltipCell } from '../common/table/FOSTableTooltipCell';
import { FOSTableVesselLinkUnauthenticatedCell } from '../common/table/FOSTableVesselLinkUnauthenticatedCell';
import { FOSTableAvailabilityDateCell } from '../common/table/FOSTableAvailabilityDateCell';
import { FOSTableCurrentChartererCell } from '../common/table/FOSTableCurrentChartererCell';
import { FOSTableNextChartererCell } from '../common/table/FOSTableNextChartererCell';
import { availabilityDateChecker } from '../util';
import { NumericFormat } from 'react-number-format';

enum Tab {
  AHTS = 'ahts',
  PSV = 'psv',
  Standby = 'standby',
}

export const AvailabilityNorway = (props: any) => {
  const columns_other = React.useMemo(
    () => [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkUnauthenticatedCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkUnauthenticatedCell>
        ),
      },
      {
        Header: 'BHP',
        accessor: 'bhp',
        Cell: ({ value }: any) => (
          <span>
            <NumericFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        Header: 'BP',
        accessor: 'bollard_pull',
      },
      {
        Header: 'DECK',
        accessor: 'deck_area',
        Cell: ({ value }: any) => (
          <span>
            <NumericFormat
              value={value}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
            />
          </span>
        ),
      },
      {
        Header: 'ROV',
        accessor: 'rov',
      },
      {
        Header: 'Current Charterer',
        accessor: 'current_charterer',
      },
      {
        Header: 'Next Charterer',
        accessor: 'next_charterer',
        Cell: ({ row }: any) => (
          <FOSTableNextChartererCell
            currenCharterer={row.original.current_charterer}
            nextCharterer={row.original.next_charterer}
            currentChartererDate={row.original.current_charterer_date}
            nextChartererDate={row.original.next_charterer_date}
          />
        ),
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Port Available',
        accessor: 'port',
      },
      {
        Header: 'Date Available',
        accessor: 'date_available',
        Cell: (cell: any) => (
          <FOSTableAvailabilityDateCell
            value={cell.value}
          ></FOSTableAvailabilityDateCell>
        ),
      },
    ],
    []
  );

  const columns_psv = React.useMemo(
    () => [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
        Cell: ({ row }: any) => (
          <FOSTableVesselLinkUnauthenticatedCell
            imo={row.original.imo}
            vesselName={row.original.vessel_name}
          ></FOSTableVesselLinkUnauthenticatedCell>
        ),
      },
      {
        Header: 'BHP',
        accessor: 'bhp',
      },
      {
        Header: 'DWT',
        accessor: 'dwt',
      },
      {
        Header: 'DECK',
        accessor: 'deck_area',
      },
      {
        Header: 'Current Charterer',
        accessor: 'current_charterer',
      },
      {
        Header: 'Next Charterer',
        accessor: 'next_charterer',
        Cell: ({ row }: any) => (
          <FOSTableNextChartererCell
            currenCharterer={row.original.current_charterer}
            nextCharterer={row.original.next_charterer}
            currentChartererDate={row.original.current_charterer_date}
            nextChartererDate={row.original.next_charterer_date}
          />
        ),
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
        Cell: (cell: any) => (
          <FOSTableTooltipCell value={cell.value}></FOSTableTooltipCell>
        ),
      },
      {
        Header: 'Port Available',
        accessor: 'port',
      },
      {
        Header: 'Date Available',
        accessor: 'date_available',
        Cell: (cell: any) => (
          <FOSTableAvailabilityDateCell
            value={cell.value}
          ></FOSTableAvailabilityDateCell>
        ),
      },
    ],
    []
  );

  let columns = columns_other;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [tab, setTab] = useState<Tab>(Tab.AHTS);
  const [data, setData] = useState<any>([]);

  if (tab == 'psv') {
    columns = columns_psv;
  } else {
    columns = columns_other;
  }

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'date_available', desc: false });

  useEffect(() => {
    getUnauthenticatedAvailabilities('no', tab, page, perPage, sortBy).then(
      (dataRes) => {
        setData(dataRes.data);
        setTotalPages(dataRes.pagination.lastPage);
        setDataLoaded(true);
      },
      (reason) => {
        console.log('ERROR');
        setDataLoaded(true);
      }
    );
  }, [tab, page, perPage, sortBy]);

  const resetPage = () => {
    setPage(1);
  };

  useEffect(() => {
    resetPage();
  }, [tab]);

  return (
    <div>
      <div className='north-sea-spot-caption'>Availability - Norway</div>

      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <a
            className={tab === Tab.AHTS ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.AHTS);
            }}
            aria-current='page'
            href='#1'
          >
            AHTS
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={tab === Tab.PSV ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.PSV);
            }}
            href='#2'
          >
            PSV
          </a>
        </li>
        <li className='nav-item'>
          <a
            className={tab === Tab.Standby ? 'nav-link active' : 'nav-link'}
            onClick={(e) => {
              setTab(Tab.Standby);
            }}
            href='#3'
          >
            Standby
          </a>
        </li>
      </ul>

      <div style={{ marginTop: '20px' }}>
        <FOSTable
          columns={columns}
          data={data}
          isHighlightedRow={(row: any) =>
            availabilityDateChecker(row.date_available)
          }
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={page}
          perPage={perPage}
          totalPage={totalPages}
          initialSortBy={sortBy}
          onChangeSort={setSortBy}
        ></FOSTable>
      </div>
    </div>
  );
};
