import { ValuationCreateRequest } from '../server-types';
import { CreateValuationForm } from '../state/createValuation';

export const convertToValuationCreatePayload = (
  input: CreateValuationForm
): ValuationCreateRequest => {
  const responseObj: ValuationCreateRequest = {
    imo: '',
    client: '',
    comments: '',
    currency: '',
    effectiveDate: new Date(),
    issuedTo: '',
    priceLocal: {
      from: '',
      to: '',
    },
    priceUSD: {
      from: '',
      to: '',
    },
    source: '',
  };

  // Map inputs to the create request
  if (input.vesselDetails !== undefined)
    responseObj.imo = input.vesselDetails.value.imo;
  if (input.client !== undefined) responseObj.client = input.client;
  if (input.comments !== undefined) responseObj.comments = input.comments;
  if (input.currency !== undefined) responseObj.currency = input.currency.value;
  if (input.priceLocal !== undefined) responseObj.priceLocal = input.priceLocal;
  if (input.priceUSD !== undefined) responseObj.priceUSD = input.priceUSD;

  // We must multiply price local by 1 because its denoted in millions
  responseObj.priceLocal = {
    from: !isNaN(Number(responseObj.priceLocal.from))
      ? (Number(responseObj.priceLocal.from) * 1000000).toFixed(0)
      : responseObj.priceLocal.from,
    to: !isNaN(Number(responseObj.priceLocal.to))
      ? (Number(responseObj.priceLocal.to) * 1000000).toFixed(0)
      : responseObj.priceLocal.to,
  };

  if (input.source !== undefined) responseObj.source = input.source;
  if (input.issuedTo !== undefined) responseObj.issuedTo = input.issuedTo;
  if (input.effectiveDate !== undefined)
    responseObj.effectiveDate = input.effectiveDate;

  return responseObj;
};
