import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import engb from 'date-fns/locale/en-GB';

registerLocale('en-GB', {
  ...engb,
  options: { ...engb.options, weekStartsOn: 1 },
});

interface Props {
  value: Date | undefined | null;
  name: string;
  label?: string | undefined | null;
  placeholder?: string | undefined;
  stateSetter: (name: string, value: any) => void;
  isDisabled?: boolean;
}

const FOSBSDatePicker = (props: Props) => {
  if (props.label) {
    return (
      <div className='row pb-1'>
        <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
          {props.label}
        </label>
        <div className='col'>
          <div className='FOSBSDatePickerFullWidth'>
            <DatePicker
              locale='en-GB'
              className='form-control form-select-sm form-control-fullwidth'
              placeholderText={props.placeholder}
              selected={props.value}
              onBlur={(event) => {
                const value = event.target.value;
                console.log('value', value);
                if (value.length === 4 && !isNaN(parseInt(value))) {
                  const date = new Date(parseInt(value), 0, 1);
                  props.stateSetter(props.name, date);
                }
              }}
              onChange={(date: any) => {
                // Time for all dates should be 00:00
                const onlyDate = new Date(date.toDateString());
                props.stateSetter(props.name, onlyDate);
              }}
              dateFormat='dd.MM.yyyy'
              disabled={props.isDisabled}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='row'>
        <div className='col'>
          <div className='FOSBSDatePickerFullWidth'>
            <DatePicker
              locale='en-GB'
              className='form-control form-select-sm form-control-fullwidth'
              placeholderText={props.placeholder}
              selected={props.value}
              onBlur={(event) => {
                const value = event.target.value;
                if (value.length === 4 && !isNaN(parseInt(value))) {
                  const date = new Date(parseInt(value), 0, 1);
                  props.stateSetter(props.name, date);
                }
              }}
              onChange={(date) => {
                console.log('date 1', date);
                props.stateSetter(props.name, date);
              }}
              dateFormat='dd.MM.yyyy'
              disabled={props.isDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
};

function areEqual(prevProps: Props, nextProps: Props) {
  const equal = prevProps.value === nextProps.value;
  return equal;
}

export default React.memo(FOSBSDatePicker, areEqual);
