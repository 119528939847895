import { useState, useRef, useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { currencies } from '../datas';
import { CreateSaleForm, createSaleForm } from '../state/createSale';
import { ModalHeader } from './ModalHeader';
import FOSBSBoolean from '../common/FOSBSBoolean';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import FOSBSPriceInput from '../common/FOSBSPriceInput';
import FOSBSVesselFinder from '../common/FOSBSVesselFinder';
import { MultiSelectOptions } from '../common/form-elements';
import { convertToSaleCreatePayload } from '../mapper/sale';
import { toast } from 'react-toastify';
import { modalEditorOpen } from '../state/modals';
import { createSale } from '../services/fosService';

const saleTypes = [
  { label: 'Reported sold', value: 'Reported sold' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Failed sale', value: 'Failed sale' },
  { label: 'Sold for Demolition', value: 'Sold for Demolition' },
  { label: 'Demolition', value: 'Demolition' },
  { label: 'Offers Invited', value: 'Offers Invited' },
  { label: 'Requested Price', value: 'Requested Price' },
  { label: 'Contract Price', value: 'Contract Price' },
  { label: 'NewBuilding', value: 'NewBuilding' },
];

interface Props {
  onRequestClose(): void;
}

export const CreateSalesCandidateModal = (props: Props) => {
  const [currenciesMapped, setCurrenciesMapped] = useState<MultiSelectOptions>(
    []
  );
  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [form, setForm] = useRecoilState(createSaleForm);
  const latestFormState = useRef(form);

  const resetForm = useResetRecoilState(createSaleForm);

  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  useEffect(() => {
    const loadData = async () => {
      // map currencies
      setCurrenciesMapped(
        currencies.map((el) => {
          return {
            label: el,
            value: el,
          };
        })
      );
    };

    loadData();
  }, []);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current as CreateSaleForm;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
    latestFormState.current = newForm;
  };

  const doCreate = async () => {
    const finalRequestBody = convertToSaleCreatePayload(
      latestFormState.current
    );

    const response = await createSale(finalRequestBody, 'candidate');

    if (response.status) {
      toast.success('Sales candidate has been created');
      resetForm();
      setModalOpen(false);
    } else {
      toast.error(response.message);
    }
  };

  if (form === undefined) return <div></div>;

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={`Register Sales Candidate`}
      />
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Connected Imo
            </label>
            <div className='col'>
              {form.vesselDetails !== undefined && (
                <>
                  {form.vesselDetails.value.imo === '' && (
                    <p>No vessel selected</p>
                  )}
                  {form.vesselDetails.value.imo !== '' && (
                    <p>{form.vesselDetails.value.imo}</p>
                  )}
                </>
              )}
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Vessel Name
            </label>
            <div className='col'>
              <FOSBSVesselFinder
                name={'vessel_name'}
                stateSetter={(name, value) => {
                  setFormStateElement('vesselDetails', value);
                }}
                value={form.vesselDetails}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSTextInput
            label={'New Name'}
            value={form.newVesselName}
            onChange={(e) => {
              setFormStateElement('newVesselName', e.target.value);
            }}
          />

          <p>Will update vessel name on effective date if filled out.</p>

          <FOSBSTextInput
            label={'New Owner'}
            value={form.newVesselOwner}
            onChange={(e) => {
              setFormStateElement('newVesselOwner', e.target.value);
            }}
          />

          <p>Will update vessel owner on effective date if filled out.</p>

          <FOSBSDatePicker
            label={'Effective Date'}
            value={form.effectiveDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'effectiveDate'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Owner
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='owner'
                name='owner'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement('owner', value);
                }}
                value={form.owner}
                isCreatable={true}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='salesType'
                value={form.salesType}
                options={saleTypes}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSPriceInput
            label={'Asking Price amount'}
            value={form.askingPrice}
            onChange={(val) => {
              setFormStateElement('askingPrice', val);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='askingPriceCurrency'
                value={form.askingPriceCurrency}
                options={currenciesMapped}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div style={{ paddingTop: '1rem' }}>
            <FOSBSTextAreaInput
              label={'Comments'}
              value={form.comments}
              onChange={(e) => {
                setFormStateElement('comments', e.target.value);
              }}
            />
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Details
            </h5>
          </div>

          <FOSBSTextInput
            label={'Source'}
            value={form.source}
            onChange={(e) => {
              setFormStateElement('source', e.target.value);
            }}
          />

          <FOSBSDatePicker
            label={'Delivery Date'}
            value={form.deliveryDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'deliveryDate'}
          />

          <FOSBSBoolean
            label={'P&C'}
            name={'privateAndConfidential'}
            value={form.privateAndConfidential}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='btn btn-success btn'
            onClick={() => {
              doCreate();
            }}
          >
            Create candidate
          </button>
        </div>
      </div>
    </div>
  );
};
