import {
  ValuationSearchRequest,
  ValuationSearchRequestGeneralInfo,
} from '../server-types';
import { GeneralForm, RangeFormState } from '../state/searchValuation';
import moment from 'moment';

interface FOSRange {
  from?: number | string;
  to?: number | string;
}

interface InputFormat {
  general: GeneralForm;
}

export const convertToValuationSearchPayload = (
  input: InputFormat
): ValuationSearchRequest => {
  const mapRange = (source: RangeFormState, target: any, key: any) => {
    const createRange = source.from !== '' || source.to !== '';
    if (!createRange) return; // neither from or to was specified

    // Special handling: If (Built), include last year (map to db values)
    let valuesPrepared = { ...source };

    const targetRange: FOSRange = {};
    if (valuesPrepared.from !== '') targetRange.from = valuesPrepared.from;
    if (valuesPrepared.to !== '') targetRange.to = valuesPrepared.to;
    target[key] = targetRange;
  };

  const mapDateRange = (
    startDate: Date | null,
    endDate: Date | null,
    target: any,
    key: any
  ) => {
    const createRange = startDate !== null || endDate !== null;
    if (!createRange) return; // neither from or to was specified

    let valuesPrepared = { from: startDate, to: endDate };

    const targetRange: FOSRange = {};
    if (valuesPrepared.from !== null)
      targetRange.from = moment(valuesPrepared.from).format('Y-MM-DD');
    if (valuesPrepared.to !== null)
      targetRange.to = moment(valuesPrepared.to).format('Y-MM-DD');
    target[key] = targetRange;
  };

  const generalPayload: ValuationSearchRequestGeneralInfo = {};

  // --- MAP: GENERAL
  // prepare general (str)
  if (input.general.imoNumber !== '')
    generalPayload.imo = Number(input.general.imoNumber);
  if (input.general.vesselName !== '')
    generalPayload.vesselName = input.general.vesselName;
  if (input.general.client !== '') generalPayload.client = input.general.client;
  if (input.general.groupOwner !== '')
    generalPayload.groupOwner = input.general.groupOwner;
  // pick first (arr to str) (TODO: make these arr to arr later on)

  // if (input.general.client.length !== 0) generalPayload.client = input.general.client[0].value;
  // if (input.general.groupOwner.length !== 0) generalPayload.groupOwner = input.general.groupOwner[0].value;

  // pick arr (arr to arr)
  if (input.general.vesselSubType.length !== 0)
    generalPayload.vesselSubType = input.general.vesselSubType.map((el) => {
      return el.value;
    }); // map from [{label: string, value: string},...] -> [value,...]
  if (input.general.dive.length !== 0)
    generalPayload.dive = input.general.dive.map((el) => {
      return el.value;
    });
  if (input.general.shipyard.length !== 0)
    generalPayload.shipyard = input.general.shipyard.map((el) => {
      return el.value;
    });

  // Ranges
  mapDateRange(
    input.general.builtFrom,
    input.general.builtTo,
    generalPayload,
    'built'
  );
  mapRange(input.general.DWT, generalPayload, 'DWT');
  mapRange(input.general.BHP, generalPayload, 'BHP');
  mapDateRange(
    input.general.effectiveDateFrom,
    input.general.effectiveDateTo,
    generalPayload,
    'effectiveDate'
  );

  return {
    general: generalPayload,
  };
};

// client?: string[]
// effectiveDate?: FOSRange
// imo?: number
// vesselName?: string
// groupOwner?: string[]
// issuedTo?: string[]
// subtype?: string[]
// shipyard?: string[]
// built?: FOSRange
// DWT?: FOSRange
// BHP?: FOSRange
// dive?: string[]
