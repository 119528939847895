import React, { MouseEventHandler } from 'react';
import { components, MultiValueProps } from 'react-select';
import Createable from 'react-select/creatable';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';

import { MultiSelectOptions } from '../state/searchVessel';

const style1 = {
  control: (base: any, state: any) => {
    return {
      ...base,
      border: '1px solid #ced4da',
      borderRadius: '0px',
      outline: '0',
      transition:
        'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      borderColor: state.isFocused ? '#ced4da' : '#ced4da',
      boxShadow: state.isFocused
        ? '0 0 0 0.2rem rgba(227, 223, 217, 0.90);'
        : '0 !important',
      '&:hover': { borderColor: '#ced4da' }, // border style on hover
    };
  },
};

interface Props {
  options: MultiSelectOptions;
  name: string;
  stateSetter: (name: string, value: any) => void;
  value: MultiSelectOptions;
}

const arrayMoveMutable = (array: any, fromIndex: number, toIndex: number) => {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
};

const arrayMoveImmutable = (
  array: MultiSelectOptions,
  fromIndex: number,
  toIndex: number
) => {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
};

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<MultiSelectOptions>) => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableSelect = SortableContainer<any>(Createable);

const FOSBSMultiSelectSort = (props: Props) => {
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMoveImmutable(props.value, oldIndex, newIndex);
    props.stateSetter(props.name, newValue);
  };

  return (
    <SortableSelect
      // react-sortable-hoc props:
      axis='xy'
      onSortEnd={onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }: any) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      options={props.options}
      value={props.value}
      onChange={(vals: any) => {
        props.stateSetter(props.name, vals);
      }}
      components={{
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
      }}
      closeMenuOnSelect={false}
      styles={style1}
    />
  );
};

function areEqual(prevProps: Props, nextProps: Props) {
  // due to async fetching of options, we must also check if options are empty or
  const equal =
    prevProps.value === nextProps.value &&
    prevProps.options.length === nextProps.options.length;
  return equal;
}

export default React.memo(FOSBSMultiSelectSort, areEqual);
