import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { getAPIPath } from '../core';
import { convertDBVesselToEditVesselForm } from '../mapper/vessel-open';
import { imoLookupState, vesselLookupState } from '../state/openVessel';
import { getAssetsPath } from '../core';
import { ModalHeader } from './ModalHeader';

import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { VesselsPositionMap } from '../Maps/VesselsPositionMap';

const Cell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#00335b',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const Row = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  onRequestClose(): void;
}

export const OpenVesselModalUnauthenticated = (props: Props) => {
  const [loaded, setLoaded] = useState(false);

  const [lookupImo, setLookupImo] = useRecoilState(imoLookupState);
  const [vessel, setVessel] = useRecoilState<any>(vesselLookupState);
  const [fixtures, setFixtures] = useState<any>([]);

  // on mount
  useEffect(() => {
    const loadData = async () => {
      // Fetch data about vessel
      const resVessel = await fetch(
        getAPIPath() + `/api/unauthenticated/vessel/lookup/${lookupImo}`,
        {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          credentials: 'include', // include, *same-origin, omit
          headers: {},
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }
      );

      const resVesselFixtures = await fetch(
        getAPIPath() +
          `/api/unauthenticated/vessel/lookup/${lookupImo}/fixtures`,
        {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          credentials: 'include', // include, *same-origin, omit
          headers: {},
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        }
      );

      if (resVessel.status !== 200 || resVesselFixtures.status !== 200) {
        // TODO: Handle load error
      }

      const vesselLookupResp = await resVessel.json();
      const fixturesResp = await resVesselFixtures.json();

      setVessel(convertDBVesselToEditVesselForm(vesselLookupResp));
      setFixtures(fixturesResp);
      setLoaded(true);
    };

    loadData();
  }, []);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  if (vessel === undefined) {
    return <div>Vessel not defined</div>;
  }

  // Loading
  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={vessel.vessel_name}
      />

      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div
            style={{
              display: 'flex',
              width: '100%',
              maxHeight: 500,
              marginBottom: 20,
              justifyContent: 'center',
            }}
          >
            {!!vessel.meta?.imageUrl && (
              <img
                src={`${getAssetsPath()}/${vessel.meta?.imageUrl}`}
                alt=''
                style={{ maxWidth: '100%', maxHeight: 400 }}
              />
            )}
          </div>

          {!!vessel && (
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <Cell>VESSEL SPECIFICATIONS</Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>Vessel Type</b>
                  </Cell>
                  <Cell>{vessel.vessel_type?.label}</Cell>
                  <Cell component='th'>
                    <b>DWT</b>
                  </Cell>
                  <Cell>{vessel.dwt}</Cell>
                </Row>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>Built</b>
                  </Cell>
                  <Cell>{moment(vessel.built).format('MM.YYYY')}</Cell>
                  <Cell component='th'>
                    <b>BHP</b>
                  </Cell>
                  <Cell>{vessel.bhp}</Cell>
                </Row>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>Design</b>
                  </Cell>
                  <Cell>{vessel.design?.label}</Cell>
                  <Cell component='th'>
                    <b>BP</b>
                  </Cell>
                  <Cell>{vessel.bollard_pull}</Cell>
                </Row>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>LOA (m)</b>
                  </Cell>
                  <Cell>{vessel.loa}</Cell>
                  <Cell component='th'>
                    <b>Deck</b>
                  </Cell>
                  <Cell>{vessel.deck_area}</Cell>
                </Row>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>Beam (m)</b>
                  </Cell>
                  <Cell>{vessel.beam}</Cell>
                  <Cell component='th'>
                    <b>DP</b>
                  </Cell>
                  <Cell>{vessel.dp?.label}</Cell>
                </Row>
              </TableBody>
            </Table>
          )}
        </div>

        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          {vessel && (
            <div>
              <VesselsPositionMap
                height={400}
                width={'100%'}
                zoom={[3]}
                center={[Number(vessel.lon), Number(vessel.lat)]}
                vessels={[
                  {
                    ...vessel,
                    coordinates: [Number(vessel.lon), Number(vessel.lat)],
                    vessel_type: vessel.vessel_type?.label,
                  },
                ]}
              />
              Position received:{' '}
              {moment(vessel.signal_received).format('DD.MM.yyyy HH:mm')}
            </div>
          )}

          {!vessel && (
            <div className='alert alert-warning' role='alert'>
              No position found for vessel.
            </div>
          )}

          {!!fixtures && (
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <Cell>LAST 5 FIXTURES</Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>Fixture Date</b>
                  </Cell>
                  <Cell>
                    <b>Charterer</b>
                  </Cell>
                  <Cell component='th'>
                    <b>Workscope</b>
                  </Cell>
                  <Cell>
                    <b>Rate</b>
                  </Cell>
                </Row>
                {fixtures.map((fixture: any) => (
                  <Row key={fixture.global_id}>
                    <Cell>
                      {moment(fixture.fixture_date).format('dd.MM.yyyy')}
                    </Cell>
                    <Cell>{fixture.charterer}</Cell>
                    <Cell>{fixture.workscope}</Cell>
                    <Cell>RNR</Cell>
                  </Row>
                ))}
              </TableBody>
            </Table>
          )}

          <br />

          {!!vessel && (
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <Cell>CHARTERING</Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                  <Cell></Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>Current Charterer</b>
                  </Cell>
                  <Cell>
                    {vessel.current_charterer
                      ? vessel.current_charterer[0].label
                      : null}
                  </Cell>
                  <Cell component='th'>
                    <b>Next Charterer</b>
                  </Cell>
                  <Cell>
                    {vessel.next_charterer
                      ? vessel.next_charterer[0].label
                      : null}
                  </Cell>
                </Row>
                <Row>
                  <Cell component='th' scope='row'>
                    <b>Port Available</b>
                  </Cell>
                  <Cell>{vessel.port ? vessel.port[0].label : null}</Cell>
                  <Cell component='th'>
                    <b>Date Available</b>
                  </Cell>
                  <Cell>
                    {moment(vessel.date_available).format('dd.MM.yyyy')}
                  </Cell>
                </Row>
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    </div>
  );
};
