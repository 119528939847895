import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { getAPIPath } from '../../core';
import { convertDBVesselToEditVesselForm } from '../../mapper/vessel-open';
import {
  activeModalType,
  modalEditorOpen,
  ModalType,
} from '../../state/modals';
import {
  imoLookupState,
  vesselLookupState,
  vesselEditFormState,
  EditVesselForm,
} from '../../state/openVessel';
import { convertToVesselEditPayload } from '../../mapper/vessel-open';

import { shallowFormDiff } from '../../util';
import { ModalHeader } from '../ModalHeader';
import { VesselAttachments } from './VesselAttachments';
import { VesselLatestFixtures } from './VesselLatestFixtures';
import { VesselLatestSales } from './VesselLatestSales';
import { VesselLatestValuations } from './VesselLatestValuations';
import { VesselPosition } from './VesselPosition';
import { VesselSpecification } from './VesselSpecification';
import { toast } from 'react-toastify';
import {
  updateVessel as sendUpdateVesselRequest,
  getVesselLookup,
} from '../../services/fosService';
import { useForm, FormProvider } from 'react-hook-form';
import { ManualAvailability } from './ManualAvailability';

enum Tab {
  Position,
  Specification,
  Attachments,
  LatestFixtures,

  ManualAvailability,
  LatestBudgetIndication,
  LatestValuations,
  LatestSales,
}

interface Props {
  onRequestClose(): void;
}

const generateVesselEditState = (oldVessel: any, newVessel: any) => {
  if (oldVessel === undefined || newVessel === undefined) {
    return null;
  }

  const oldPayload = convertToVesselEditPayload(oldVessel);
  const newPayload = convertToVesselEditPayload(newVessel);

  const stateChange = shallowFormDiff(oldPayload, newPayload);

  return stateChange;
};

export const OpenVesselModal = (props: Props) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  const [loaded, setLoaded] = useState(false);
  const [tab, setTab] = useState<Tab>(Tab.Position);

  const setModalOpen = useSetRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);

  const [lookupImo, setLookupImo] = useRecoilState(imoLookupState);
  const [vessel, setVessel] = useRecoilState(vesselLookupState);
  const [form, setForm] = useRecoilState(vesselEditFormState);

  const resetVessel = useResetRecoilState(vesselLookupState);
  const resetForm = useResetRecoilState(vesselEditFormState);

  let stateChange = generateVesselEditState(vessel, form);
  const untouched = stateChange === undefined || stateChange === null;

  const latestFormState = useRef(form);

  const updateVessel = async () => {
    if (form === undefined) return;
    if (stateChange === undefined) return;

    stateChange = generateVesselEditState(vessel, latestFormState.current);

    const vesselNameChange = stateChange?.find(
      (el) => el.field === 'vessel_name'
    );
    if (vesselNameChange) {
      stateChange?.push({
        field: 'vessel_ex_name',
        oldValue: vessel?.vessel_ex_name,
        newValue: vesselNameChange.oldValue,
      });
    }

    // the stateChange is the payload
    const res = await sendUpdateVesselRequest(form.global_id, stateChange);

    toast.success(`Vessel has been updated (${form.imo})`);
    resetForm();
    resetVessel();
    setModalOpen(false);
  };

  // On var update (ref updating)
  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current;
    if (latest === undefined) return; // form is not loaded yet
    const newForm = {
      ...latest,
      [field]: value,
    };

    // call setter func and update its vals
    setForm(newForm);
    // We need to update the ref/current because the updater might be called directly in updatePriceUSD method (staling the data)
    latestFormState.current = newForm;
  };

  // on mount
  useEffect(() => {
    const loadData = async () => {
      const lookupResp = await getVesselLookup(lookupImo);

      setVessel(convertDBVesselToEditVesselForm(lookupResp));
      setForm(convertDBVesselToEditVesselForm(lookupResp));

      setLoaded(true);
    };

    loadData();
  }, []);

  // On var update (ref updating)
  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  const openVesselHistory = () => {
    setModalType(ModalType.OpenVesselHistory);
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }

  if (vessel === undefined) {
    return <div>Vessel not defined</div>;
  }

  // imo
  // vessel_name

  // Loading
  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={`${vessel.vessel_name} - ${vessel.imo}`}
        contentRight={
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={openVesselHistory}
          >
            History
          </button>
        }
      />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(updateVessel)}>
          <div className='row'>
            <div className='col-sm-12 pt-2'>
              <ul className='nav nav-tabs'>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.Position ? 'nav-link active' : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.Position);
                    }}
                    aria-current='page'
                    href='#'
                  >
                    Position
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.Specification ? 'nav-link active' : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.Specification);
                    }}
                    href='#'
                  >
                    Specification
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.Attachments ? 'nav-link active' : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.Attachments);
                    }}
                    href='#'
                  >
                    Attachments
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.LatestFixtures
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.LatestFixtures);
                    }}
                    href='#'
                  >
                    Latest fixtures
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.ManualAvailability
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.ManualAvailability);
                    }}
                    href='#'
                  >
                    Manual Availability
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.LatestBudgetIndication
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.LatestBudgetIndication);
                    }}
                    href='#'
                  >
                    Latest Budget Indication
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.LatestValuations
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.LatestValuations);
                    }}
                    href='#'
                  >
                    Latest Valuations
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={
                      tab === Tab.LatestSales ? 'nav-link active' : 'nav-link'
                    }
                    onClick={(e) => {
                      setTab(Tab.LatestSales);
                    }}
                    href='#'
                  >
                    Latest sales
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div style={{ paddingLeft: 12, paddingRight: 12 }}>
            {tab === Tab.Position && (
              <VesselPosition
                vessel={vessel}
                form={form}
                setFormStateElement={setFormStateElement}
              />
            )}
            {tab === Tab.Specification && (
              <VesselSpecification
                form={form}
                setFormStateElement={setFormStateElement}
              />
            )}
            {tab === Tab.Attachments && <VesselAttachments />}
            {tab === Tab.LatestFixtures && (
              <VesselLatestFixtures
                form={form}
                setFormStateElement={setFormStateElement}
              />
            )}
            {tab === Tab.LatestBudgetIndication && (
              <VesselLatestFixtures
                form={form}
                setFormStateElement={setFormStateElement}
                type='budget_indication'
              />
            )}
            {tab === Tab.ManualAvailability && (
              <ManualAvailability
                form={form}
                setFormStateElement={setFormStateElement}
              />
            )}
            {tab === Tab.LatestValuations && (
              <VesselLatestValuations
                form={form}
                setFormStateElement={setFormStateElement}
              />
            )}
            {tab === Tab.LatestSales && (
              <VesselLatestSales
                form={form}
                setFormStateElement={setFormStateElement}
              />
            )}
          </div>

          {tab !== Tab.Attachments && (
            <div className='row'>
              <div className='col-sm-12' style={{ textAlign: 'center' }}>
                <hr />
                <button
                  type='button'
                  className='btn btn-secondary btn'
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    props.onRequestClose();
                  }}
                >
                  Cancel
                </button>
                <input
                  type='submit'
                  disabled={untouched}
                  className='btn btn-success btn'
                  value='Update'
                />
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};
