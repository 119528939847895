import { atom, RecoilState } from 'recoil';
import { CreateFixtureForm } from '../state/createFixture';

export const fixtureGlobalIdLookupState: RecoilState<string> = atom({
  key: 'fixtureGlobalIdLookupState',
  default: '',
});

export const fixtureLookupState: RecoilState<EditFixtureForm | undefined> =
  atom({
    key: 'fixtureLookupState',
    default: undefined as EditFixtureForm | undefined,
  });

export const fixtureEditFormState: RecoilState<EditFixtureForm | undefined> =
  atom({
    key: 'fixtureEditFormState',
    default: undefined as EditFixtureForm | undefined,
  });

export interface EditFixtureForm extends CreateFixtureForm {
  globalId: string;
  imo: string | null;
  updated: Date | undefined;
  updatedBy: string;
}
