import { capitalize } from '@material-ui/core';
import { CurrencyResponse } from './api/currency';
import moment from 'moment';

export const shallowFormDiff = (
  lhs: any,
  rhs: any
): { field: string; oldValue: any; newValue: any }[] | undefined => {
  if (lhs === null || lhs === undefined) return undefined;
  if (rhs === null || rhs === undefined) return undefined;
  const changes = [];

  // Compare keys in both lhs and rhs !
  const lhsKeys = Object.keys(lhs);
  const rhsKeys = Object.keys(rhs);
  const allKeys = lhsKeys.concat(rhsKeys);
  const uniqueKeys = allKeys.filter(
    (item, pos) => allKeys.indexOf(item) === pos
  );

  for (let key of uniqueKeys) {
    const lhsValue = (lhs as any)[key];
    const rhsValue = (rhs as any)[key];

    if (
      (lhsValue === undefined || rhsValue === null) &&
      (lhsValue === null || rhsValue === undefined)
    ) {
      continue;
    }

    // .value is for selects, support "all kinds" of inputs for diff comparison
    const lhsCompareValue =
      typeof lhsValue === 'object' &&
      lhsValue !== null &&
      lhsValue.value !== undefined
        ? lhsValue.value
        : lhsValue;
    const rhsCompareValue =
      typeof rhsValue === 'object' &&
      rhsValue !== null &&
      rhsValue.value !== undefined
        ? rhsValue.value
        : rhsValue;

    // Serialize and compare
    const serializedLHS = JSON.stringify(lhsCompareValue);
    const serializedRHS = JSON.stringify(rhsCompareValue);

    if (serializedLHS !== serializedRHS) {
      changes.push({
        field: key,
        oldValue: lhsValue,
        newValue: rhsValue === '' ? null : rhsValue,
      });
    }
  }
  if (changes.length === 0) return undefined;
  return changes;
};

export const updatePriceUSD = (
  currency: string,
  priceLocal: string | number | null | undefined,
  currencyRates: CurrencyResponse[]
): { usd: string | Number; calculatedInfo: any } => {
  if (currency === undefined)
    return {
      usd: '',
      calculatedInfo: {
        calculated: false,
        interestRateDate: '',
        reason: 'Currency not defined',
      },
    };

  // If USD, skip calculation
  if (currency.toLowerCase() === 'usd') {
    return {
      usd: Number(priceLocal) * 1000000,
      calculatedInfo: {
        calculated: true,
        interestRateDate: '',
        reason: 'USD->USD',
      },
    };
  }

  // Calculation part
  const resetWithMsg = (reason: string) => {
    return {
      usd: '',
      calculatedInfo: {
        calculated: false,
        interestRateDate: '',
        reason,
      },
    };
  };

  // reset if anything fails or is unavailable
  if (currencyRates.length === 0) {
    return resetWithMsg('');
  }

  const usdNOK = currencyRates.find((el) => {
    return el.currency.toLowerCase() === 'usd';
  });

  if (usdNOK === undefined) {
    return resetWithMsg('Missing USD');
  }

  // If NOK is used, do simple direct calculation
  if (currency.toLowerCase() === 'nok') {
    // Currency is found!
    let newUSDPrice = '';

    if (priceLocal !== '' && !isNaN(Number(priceLocal))) {
      newUSDPrice = ((Number(priceLocal) / usdNOK.rate) * 1000000).toFixed(2);
    }

    // Finally update vals!

    return {
      usd: newUSDPrice,
      calculatedInfo: {
        calculated: true,
        interestRateDate: usdNOK.rate_date,
        reason: '',
      },
    };
  }

  // Not NOK -- Do INDIRECT calculation via USDNOK

  const findCurrencyRate = currencyRates.find((el) => {
    return el.currency.toLowerCase() === currency.toLowerCase(); // TODO: NOK->USD only supported. //form.currency?.value.toLowerCase();
  });

  if (findCurrencyRate === undefined) {
    return {
      usd: '',
      calculatedInfo: {
        calculated: false,
        interestRateDate: '',
        reason: `Found no currency rate for ${currency}`,
      },
    };
  }

  // do indirect conversion to usd!
  const indirectRate = Number(usdNOK.rate) / Number(findCurrencyRate.rate);

  // Currency is found!
  let newUSDPrice = '';

  if (priceLocal !== '' && !isNaN(Number(priceLocal))) {
    newUSDPrice = ((Number(priceLocal) / indirectRate) * 1000000).toFixed(2);
  }

  // Finally update vals!
  return {
    usd: newUSDPrice,
    calculatedInfo: {
      calculated: true,
      interestRateDate: findCurrencyRate.rate_date,
      reason: `Calculated ${currency}/USD rate (${(1 / indirectRate).toFixed(
        2
      )}) indirectly via ${currency}/NOK -> NOK/USD`,
    },
  };
};

export const availabilityDateChecker = (value: Date) => {
  const date = moment(value);
  const now = moment();

  return date.isBefore(now, 'day') || date.isSame(now, 'day');
};

export const union = (arr1: string[], arr2: string[]): string[] => {
  return [...new Set([...arr1, ...arr2])];
};

export const createOptionsFromEnum = <T extends Record<string, string>>(
  enumObject: T
): { label: string; value: string }[] => {
  return Object.values(enumObject).map((value) => {
    return {
      label: capitalize(value.split('_').join(' ')),
      value: value,
    };
  });
};
