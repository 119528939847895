import {
  Button,
  Paper,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useState } from 'react';
import { deleteVessel } from '../services/fosService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: '20px',
    },
  })
);

export const DeleteItems = () => {
  const [imo, setImo] = useState<String>();

  const classes = useStyles();
  return (
    <div className='container-fluid' style={{ height: '65vh' }}>
      <div className='row pt-3'>
        <div className='col-12'>
          <div className='north-sea-spot-caption'>Delete Vessel</div>
          <Paper className={classes.paper}>
            <TextField
              label='Imo to delete'
              placeholder='IMO'
              size='small'
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={imo}
              onChange={(e) => {
                setImo(e.target.value);
              }}
            />

            <Button
              variant='contained'
              color='secondary'
              style={{ marginTop: 20 }}
              disabled={!imo}
              onClick={async () => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this vessel?'
                  ) === true
                ) {
                  await deleteVessel(imo);
                  alert('Vessel deleted');
                }
              }}
            >
              Delete
            </Button>
          </Paper>
        </div>
      </div>
    </div>
  );
};
