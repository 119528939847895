import React, { useState } from 'react';
import { MainThemeBootstrap } from '../Theme/MainThemeBootstrap';
import { MainThemeSideBar } from '../Theme/MainThemeSideBar';
import { RecentSales } from '../SNP/RecentSales';
import { SalesCandidates } from '../SNP/SalesCandidates';
import { FOSBSVesselSearch } from '../common/FOSBSVesselSearch';

export const SNP = () => {
  return (
    <div>
      <MainThemeBootstrap>
        <div className='container-fluid'>
          <div className='row pt-3'>
            <div className='col-12'>
              <FOSBSVesselSearch></FOSBSVesselSearch>
            </div>
          </div>
          <div className='row pt-3'>
            <div className='col-10 main-column'>
              <RecentSales></RecentSales>
              <br></br>

              <SalesCandidates></SalesCandidates>
              <br></br>
            </div>

            <div className='col-2 sidebar-column'>
              <MainThemeSideBar></MainThemeSideBar>
            </div>
          </div>
        </div>
      </MainThemeBootstrap>
    </div>
  );
};
