import { EditVesselForm } from '../state/openVessel';
import { MultiSelectOption } from '../state/searchVessel';
import {
  mapToDropdown,
  mapToMultiSelect,
  mapToDate,
  getMultiSelectValues,
} from '../mapper/form-elements';

export const convertDBVesselToEditVesselForm = (input: any): EditVesselForm => {
  return {
    global_id: input.global_id,
    attachments: input.attachments,

    // general
    imo: input.imo,
    vessel_name: input.vessel_name,
    vessel_ex_name: input.vessel_ex_name,
    owner: mapToDropdown(input.owner),
    technical_manager: mapToDropdown(input.technical_manager),
    design: mapToDropdown(input.design),
    vessel_type: mapToDropdown(input.vessel_type),
    vessel_subtype: mapToDropdown(input.vessel_subtype),
    vessel_north_sea_spot: input.vessel_north_sea_spot,
    ship_status: mapToDropdown(input.ship_status),
    shipyard: mapToDropdown(input.shipyard),
    shipyard_country: mapToDropdown(input.shipyard_country),
    shipyard_region: mapToDropdown(input.shipyard_region),
    controlling_contractor: mapToDropdown(input.controlling_contractor),
    vessel_subsea: input.vessel_subsea,

    // position data
    lat: input.lat,
    lon: input.lon,
    region: mapToDropdown(input.region),
    region_vesseltracker: input.region_vesseltracker,
    signal_received: input.signal_received,
    MoveStatus: input.MoveStatus,
    eta: mapToDate(input.eta),
    destination: input.destination,
    draught: input.draught,
    heading: input.heading,
    speed: input.speed,
    date_available: mapToDate(input.date_available),
    date_updated: mapToDate(input.date_updated),
    next_charterer: mapToMultiSelect(input.next_charterer),
    port: mapToMultiSelect(input.port),
    workscope: input.workscope,
    updated: input.updated,
    updated_by: input.updated_by,

    // position page data
    current_charterer: mapToMultiSelect(input.current_charterer),
    current_charterer_fix_id: input.current_charterer_fix_id,

    // dimensions
    built: mapToDate(input.built),
    dwt: input.dwt,
    bhp: input.bhp,
    bollard_pull: input.bollard_pull,
    deck_area: input.deck_area,
    crane_size_1: input.crane_size_1,
    crane_size_2: input.crane_size_2,
    crane_size_3: input.crane_size_3,
    dp: mapToDropdown(input.dp),
    accomondation: input.accomondation,
    beam: input.beam,
    draft: input.draft,
    loa: input.loa,
    mooring: mapToDropdown(input.mooring),

    // extras
    rov: mapToDropdown(input.rov),
    moonpool: input.moonpool,
    helideck: input.helideck,
    wire_capacity: input.wire_capacity,
    a_frame: input.a_frame,
    deck_strength: input.deck_strength,
    winch: input.winch,
    fifi: mapToDropdown(input.fifi),
    oilrec: mapToDropdown(input.oilrec),
    ice_class: mapToDropdown(input.ice_class),
    fos_ice_class: mapToDropdown(input.fos_ice_class),
    sps: input.sps,
    max_speed: input.max_speed,
    standby: input.standby,
    w2w: input.w2w,
    survivors: input.survivors,
    passengers: input.passengers,
    battery: input.battery,
    shore_power: input.shore_power,
    dual_fuel: input.dual_fuel,
    // under deck
    fuel: input.fuel,
    methanol: input.methanol,
    bulk: input.bulk,
    oil_rec: input.oil_rec,
    brine: input.brine,
    mud: input.mud,
    base_oil: input.base_oil,
    special_products: input.special_products,
    ballast_water: input.ballast_water,
    meg: input.meg,
    pot_water: input.pot_water,

    // other
    hull_number: input.hull_number,
    vessel_class_1: mapToDropdown(input.vessel_class_1),
    vessel_class_2: mapToDropdown(input.vessel_class_2),
    flag: mapToDropdown(input.flag),
    contract_date: mapToDate(input.contract_date),
    contract_price_currency: input.contract_price_currency,
    contract_price: input.contract_price,
    dive: mapToDropdown(input.dive),
    tower: mapToDropdown(input.tower),
    carousel: mapToDropdown(input.carousel),
    lay_spread: input.lay_spread,
    ern: input.ern,
    streamer_capacity: input.streamer_capacity,

    // comments
    comments: input.comments,
    fixtureComments: input.fixtureComments,
    fixtureWorkscope: input.fixtureWorkscope,
    // meta and stuff
    history_log: input.history_log,
    meta: input.meta,
  };
};

export const convertToVesselEditPayload = (input: EditVesselForm): any => {
  const responseObj = {
    port: '',
    current_charterer: '',
    next_charterer: '',
  };

  Object.assign(responseObj, input);

  if (input.port !== undefined)
    responseObj.port = getMultiSelectValues(input.port);
  if (input.current_charterer !== undefined)
    responseObj.current_charterer = getMultiSelectValues(
      input.current_charterer
    );
  if (input.next_charterer !== undefined)
    responseObj.next_charterer = getMultiSelectValues(input.next_charterer);

  return responseObj;
};
