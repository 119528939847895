import moment from 'moment';

export const FOSTableAvailabilityDateCell = (props: any) => {
  const { value } = props;

  if (!value) return <div></div>;

  const date = moment(value);
  const now = moment();

  if (date.isBefore(now, 'day')) {
    return <div>Prompt</div>;
  } else if (date.isSame(now, 'day')) {
    return <div>Today</div>;
  }

  const dateForm = new Date(value);
  return <div>{moment(value).format('DD.MM.yyyy')}</div>;
};
