import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { getAPIPath } from '../core';
import { flagsOther } from '../datas';
import { createVesselForm } from '../state/createVessel';
import { modalEditorOpen } from '../state/modals';
import { MultiSelectOptions, MultiSelectOption } from '../state/searchVessel';
import { ModalHeader } from './ModalHeader';
import FOSBSBoolean from '../common/FOSBSBoolean';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import FOSBSValidatableTextInput from '../common/FOSBSValidatableTextInput';
import { toast } from 'react-toastify';
import { convertToVesselCreatePayload } from '../mapper/vessel-create';
import { getShipyardInfo, createVessel } from '../services/fosService';
import { useForm, FormProvider } from 'react-hook-form';

const mapToMultiSelect = (rawArr: string[]): MultiSelectOptions => {
  return rawArr.map((el) => {
    return { label: el, value: el };
  });
};

interface Props {
  onRequestClose(): void;
}

export const CreateVesselModal = (props: Props) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [form, setForm] = useRecoilState(createVesselForm);
  const resetForm = useResetRecoilState(createVesselForm);

  // data lists
  const [vesselTypes, setVesselTypes] = useState<MultiSelectOptions>([]);
  const [vesselSubTypes, setVesselSubTypes] = useState<MultiSelectOptions>([]);
  const [shipStatuses, setShipStatuses] = useState<MultiSelectOptions>([]);
  const [shipyardRegions, setShipyardRegions] = useState<MultiSelectOptions>(
    []
  );
  const [iceClasses, setIceClasses] = useState<MultiSelectOptions>([]);
  const [shipClasses, setShipClasses] = useState<MultiSelectOptions>([]);
  const [flags, setFlags] = useState<MultiSelectOptions>([]);
  const [diveOpts, setDiveOpts] = useState<MultiSelectOptions>([]);
  const [towerOpts, setTowerOpts] = useState<MultiSelectOptions>([]);
  const [carouselOpts, setCarouselOpts] = useState<MultiSelectOptions>([]);

  const latestFormState = useRef(form);

  // On var update (ref updating)
  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  // On mount!
  useEffect(() => {
    const loadData = async () => {
      const res = await fetch(getAPIPath() + `/api/meta/vessels/general`, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        credentials: 'include', // include, *same-origin, omit
        headers: {},
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (res.status !== 200) {
        // TODO: Handle load error
      }

      const generalMetaInfo = await res.json();

      setVesselTypes(mapToMultiSelect(generalMetaInfo.vesselTypes));
      setVesselSubTypes(mapToMultiSelect(generalMetaInfo.vesselSubTypes));
      setShipStatuses(mapToMultiSelect(generalMetaInfo.shipStatuses));
      setShipyardRegions(mapToMultiSelect(generalMetaInfo.shipyardRegions));
      setIceClasses(mapToMultiSelect(generalMetaInfo.iceClasses));
      setShipClasses(mapToMultiSelect(generalMetaInfo.shipClasses));
      setDiveOpts(mapToMultiSelect(generalMetaInfo.diveOpts));
      setTowerOpts(mapToMultiSelect(generalMetaInfo.towerOpts));
      setCarouselOpts(mapToMultiSelect(generalMetaInfo.carouselOpts));

      // map flags to MultiSelectOption format
      setFlags(
        flagsOther.map((el) => {
          return {
            label: `${el.code} - ${el.title}`,
            value: el.code,
          };
        })
      );
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadShipyardInfo = async (shipyard: MultiSelectOption) => {
      const latest = latestFormState.current;
      const shipyardInfo = await getShipyardInfo(shipyard.value);

      const newForm = {
        ...latest,
        shipyard_country: {
          label: shipyardInfo.country,
          value: shipyardInfo.country,
        },
        shipyard_region: {
          label: shipyardInfo.continent,
          value: shipyardInfo.continent,
        },
      };

      setForm(newForm);
    };

    if (!!form && form.shipyard) loadShipyardInfo(form.shipyard);
  }, [form?.shipyard]);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
  };

  const doCreate = async () => {
    if (form === undefined) return;

    // const finalRequestBody = stateChange; // we simply use the stateChange as the form;)

    const finalRequestBody = convertToVesselCreatePayload(
      latestFormState.current
    );

    try {
      const createResponse = await createVessel(finalRequestBody);

      if (!createResponse.status) {
        toast.error(createResponse.message);
        return;
      }

      toast.success(`Vessel has been created: ${form.vessel_name}`);
      resetForm();
      setModalOpen(false);
    } catch (e) {
      toast.error(`(${(e as Error).message})`);
    }
  };

  if (form === undefined) return <div></div>;

  return (
    <div>
      <ModalHeader
        onRequestClose={props.onRequestClose}
        header={'Create Vessel'}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(doCreate)}>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-3 pt-2 bg-light'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  General Info
                </h5>
              </div>

              <FOSBSTextInput
                label={'Imo Number'}
                value={form.imo}
                onChange={(e) => {
                  setFormStateElement('imo', e.target.value);
                }}
              />

              <FOSBSValidatableTextInput
                required='Vessel Name is required'
                label={'Vessel Name'}
                value={form.vessel_name}
                onChange={(e) => {
                  setFormStateElement('vessel_name', e.target.value);
                }}
              />

              <FOSBSTextAreaInput
                label={'Vessel Ex Names'}
                value={form.vessel_ex_name}
                onChange={(e) => {
                  setFormStateElement('vessel_ex_name', e.target.value);
                }}
              />

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Owner
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='owner'
                    name='owner'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement('owner', value);
                    }}
                    value={form.owner}
                    isCreatable={true}
                    isMulti={false}
                  />
                </div>
              </div>
              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Technical Manager
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='owner'
                    name='technical_manager'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement('technical_manager', value);
                    }}
                    value={form.technical_manager}
                    isCreatable={true}
                    isMulti={false}
                  />
                </div>
              </div>
              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Design
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='design'
                    name='design'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement('design', value);
                    }}
                    value={form.design}
                    isCreatable={true}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Vessel Type
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='vessel_type'
                    value={form.vessel_type}
                    options={vesselTypes}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isCreatable={true}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Vessel Subtype
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='vessel_subtype'
                    value={form.vessel_subtype}
                    options={vesselSubTypes}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isCreatable={true}
                    isMulti={false}
                  />
                </div>
              </div>

              <FOSBSBoolean
                label={'Vessel Subsea'}
                name={'vessel_subsea'}
                value={form.vessel_subsea}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />

              <FOSBSBoolean
                label={'North Sea Spot'}
                name={'vessel_north_sea_spot'}
                value={form.vessel_north_sea_spot}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Ship Status
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='ship_status'
                    value={form.ship_status}
                    options={shipStatuses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Shipyard
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='shipyard'
                    name='shipyard'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    value={form.shipyard}
                    isCreatable={false}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Shipyard Country
                </label>
                <div className='col'>
                  <FOSBSMultiSelectAsync
                    dbFieldName='shipyard_country'
                    name='shipyard_country'
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    value={form.shipyard_country}
                    isCreatable={false}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Shipyard Region
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='shipyard_region'
                    value={form.shipyard_region}
                    options={shipyardRegions}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 pt-2 bg-light'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Dimensions
                </h5>
              </div>

              <FOSBSDatePicker
                label={'Built'}
                value={form.built}
                stateSetter={(name, value) => {
                  setFormStateElement('built', value);
                }}
                name={'built'}
              />

              <FOSBSTextInput
                label={'DWT'}
                value={form.dwt}
                onChange={(e) => {
                  setFormStateElement('dwt', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'BHP'}
                value={form.bhp}
                onChange={(e) => {
                  setFormStateElement('bhp', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'BP'}
                value={form.bollard_pull}
                onChange={(e) => {
                  setFormStateElement('bollard_pull', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Deck'}
                value={form.deck_area}
                onChange={(e) => {
                  setFormStateElement('deck_area', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Crane 1'}
                value={form.crane_size_1}
                onChange={(e) => {
                  setFormStateElement('crane_size_1', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Crane 2'}
                value={form.crane_size_2}
                onChange={(e) => {
                  setFormStateElement('crane_size_2', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Crane 3'}
                value={form.crane_size_3}
                onChange={(e) => {
                  setFormStateElement('crane_size_3', e.target.value);
                }}
              />

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  DP
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='dp'
                    value={form.dp}
                    options={[
                      { label: 'NO', value: 'NO' },
                      { label: 'N', value: 'N' },
                      { label: '1', value: '1' },
                      { label: '2', value: '2' },
                      { label: '3', value: '3' },
                    ]}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <FOSBSTextInput
                label={'Beds'}
                value={form.accomondation}
                onChange={(e) => {
                  setFormStateElement('accomondation', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Beam (m)'}
                value={form.beam}
                onChange={(e) => {
                  setFormStateElement('beam', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Max draft (m)'}
                value={form.draft}
                onChange={(e) => {
                  setFormStateElement('draft', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'LOA (m)'}
                value={form.loa}
                onChange={(e) => {
                  setFormStateElement('loa', e.target.value);
                }}
              />
              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Mooring
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='mooring'
                    value={form.mooring}
                    options={[
                      { label: '1', value: '1' },
                      { label: '2', value: '2' },
                      { label: '4', value: '4' },
                      { label: '6', value: '6' },
                      { label: '8', value: '8' },
                      { label: '10', value: '10' },
                      { label: '12', value: '12' },
                      { label: 'No', value: 'No' },
                    ]}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 pt-2 bg-light'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Extras
                </h5>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  ROV
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='rov'
                    value={form.rov}
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Yes', value: 'Yes' },
                      { label: 'Mezzdeck', value: 'Mezzdeck' },
                      { label: 'Hangar', value: 'Hangar' },
                    ]}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <FOSBSBoolean
                label={'Moonpool'}
                name={'moonpool'}
                value={form.moonpool}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />
              <FOSBSBoolean
                label={'Helideck'}
                name={'helideck'}
                value={form.helideck}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />

              <FOSBSTextInput
                label={'Wire Capacity'}
                value={form.wire_capacity}
                onChange={(e) => {
                  setFormStateElement('wire_capacity', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'AFrame'}
                value={form.a_frame}
                onChange={(e) => {
                  setFormStateElement('a_frame', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Deck Strength (t/m2)'}
                value={form.deck_strength}
                onChange={(e) => {
                  setFormStateElement('deck_strength', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Winch Pull'}
                value={form.winch}
                onChange={(e) => {
                  setFormStateElement('winch', e.target.value);
                }}
              />

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Fifi
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='fifi'
                    value={form.fifi}
                    options={[
                      { label: 'No', value: 'No' },
                      { label: 'Prepared', value: 'Prepared' },
                      { label: '1', value: '1' },
                      { label: '2', value: '2' },
                      { label: '3', value: '3' },
                      { label: '1+2', value: '1+2' },
                    ]}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Oil Rec
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='oilrec'
                    value={form.oilrec}
                    options={[
                      { label: 'N', value: 'N' },
                      { label: 'Y', value: 'Y' },
                      { label: 'Unknown', value: 'Unknown' },
                      { label: 'NOFO 2009', value: 'NOFO 2009' },
                      { label: 'NOFO 2005', value: 'NOFO 2005' },
                    ]}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Ice Class
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='ice_class'
                    value={form.ice_class}
                    options={iceClasses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  FOS Ice Class
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='fos_ice_class'
                    value={form.fos_ice_class}
                    options={[
                      { label: 'Ice C', value: 'Ice C' },
                      { label: 'Icebreaker', value: 'Icebreaker' },
                      { label: 'Ice 1B', value: 'Ice 1B' },
                      { label: 'No', value: 'No' },
                      { label: 'Ice 1A', value: 'Ice 1A' },
                      { label: 'Ice IA Super', value: 'Ice IA Super' },
                      { label: 'Ice 1C', value: 'Ice 1C' },
                    ]}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <FOSBSBoolean
                label={'SPS'}
                name={'sps'}
                value={form.sps}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />

              <FOSBSTextInput
                label={'Max speed (knots)'}
                value={form.max_speed}
                onChange={(e) => {
                  setFormStateElement('max_speed', e.target.value);
                }}
              />

              <FOSBSBoolean
                label={'Standby'}
                name={'standby'}
                value={form.standby}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />
              <FOSBSBoolean
                label={'Walk2work'}
                name={'w2w'}
                value={form.w2w}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />

              <FOSBSTextInput
                label={'Survivors'}
                value={form.survivors}
                onChange={(e) => {
                  setFormStateElement('survivors', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Passengers'}
                value={form.passengers}
                onChange={(e) => {
                  setFormStateElement('passengers', e.target.value);
                }}
              />

              <FOSBSBoolean
                label={'Battery'}
                name={'battery'}
                value={form.battery}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />
              <FOSBSBoolean
                label={'Dual fuel'}
                name={'dual_fuel'}
                value={form.dual_fuel}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />
              <FOSBSBoolean
                label={'Shore power'}
                name={'shore_power'}
                value={form.shore_power}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />
            </div>
            <div className='col-sm-12 col-md-6 col-lg-3 pt-2 bg-light'>
              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Under Deck
                </h5>
              </div>

              <FOSBSTextInput
                label={'Fuel (m3)'}
                value={form.fuel}
                onChange={(e) => {
                  setFormStateElement('fuel', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Methanol (m3)'}
                value={form.methanol}
                onChange={(e) => {
                  setFormStateElement('methanol', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Bulk (m3)'}
                value={form.bulk}
                onChange={(e) => {
                  setFormStateElement('bulk', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Oil rec (m3)'}
                value={form.oil_rec}
                onChange={(e) => {
                  setFormStateElement('oil_rec', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Brine (m3)'}
                value={form.brine}
                onChange={(e) => {
                  setFormStateElement('brine', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Mud (m3)'}
                value={form.mud}
                onChange={(e) => {
                  setFormStateElement('mud', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Base Oil (m3)'}
                value={form.base_oil}
                onChange={(e) => {
                  setFormStateElement('base_oil', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Special Products (m3)'}
                value={form.special_products}
                onChange={(e) => {
                  setFormStateElement('special_products', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Ballast Water (m3)'}
                value={form.ballast_water}
                onChange={(e) => {
                  setFormStateElement('ballast_water', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'MEG (m3)'}
                value={form.meg}
                onChange={(e) => {
                  setFormStateElement('meg', e.target.value);
                }}
              />
              <FOSBSTextInput
                label={'Pot Water (m3)'}
                value={form.pot_water}
                onChange={(e) => {
                  setFormStateElement('pot_water', e.target.value);
                }}
              />

              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Other
                </h5>
              </div>

              <FOSBSTextInput
                label={'Hull Number'}
                value={form.hull_number}
                onChange={(e) => {
                  setFormStateElement('hull_number', e.target.value);
                }}
              />

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Class
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='vessel_class_1'
                    value={form.vessel_class_1}
                    options={shipClasses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />

                  <FOSBSMultiSelect
                    name='vessel_class_2'
                    value={form.vessel_class_2}
                    options={shipClasses}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Flag
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='flag'
                    value={form.flag}
                    options={flags}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <FOSBSDatePicker
                label={'NB/Order Date'}
                value={form.contract_date}
                stateSetter={(name, value) => {
                  setFormStateElement('contract_date', value);
                }}
                name={'contract_date'}
              />

              <FOSBSTextInput
                label={'Contract Price Currency'}
                value={form.contract_price_currency}
                onChange={(e) => {
                  setFormStateElement(
                    'contract_price_currency',
                    e.target.value
                  );
                }}
              />

              <FOSBSTextInput
                label={'Contract Price'}
                value={form.contract_price}
                onChange={(e) => {
                  setFormStateElement('contract_price', e.target.value);
                }}
              />

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Dive
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='dive'
                    value={form.dive}
                    options={diveOpts}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Tower
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='tower'
                    value={form.tower}
                    options={towerOpts}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <div className='row pb-1'>
                <label
                  htmlFor='inputEmail3'
                  className='col-sm-4 col-form-label'
                >
                  Underdeck Carousel
                </label>
                <div className='col'>
                  <FOSBSMultiSelect
                    name='carousel'
                    value={form.carousel}
                    options={carouselOpts}
                    stateSetter={(name: string, value: string) => {
                      setFormStateElement(name, value);
                    }}
                    isMulti={false}
                  />
                </div>
              </div>

              <FOSBSBoolean
                label={'Lay Spread'}
                name={'lay_spread'}
                value={form.lay_spread}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
              />

              <FOSBSTextInput
                label={'ERN'}
                value={form.ern}
                onChange={(e) => {
                  setFormStateElement('ern', e.target.value);
                }}
              />

              <FOSBSTextInput
                label={'Streamer Capacity'}
                value={form.streamer_capacity}
                onChange={(e) => {
                  setFormStateElement('streamer_capacity', e.target.value);
                }}
              />

              <div className='col-md-12'>
                <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
                  Comments
                </h5>
              </div>

              <FOSBSTextAreaInput
                label={'Comments'}
                value={form.comments}
                onChange={(e) => {
                  setFormStateElement('comments', e.target.value);
                }}
              />
            </div>
          </div>

          <hr />
          <div className='row'>
            <div className='col-sm-12' style={{ textAlign: 'center' }}>
              <hr />
              <button
                type='button'
                className='btn btn-secondary btn'
                style={{ marginRight: 10 }}
                onClick={() => {
                  resetForm();
                  props.onRequestClose();
                }}
              >
                Cancel
              </button>

              <input
                type='submit'
                className='btn btn-success btn'
                value='Create'
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
