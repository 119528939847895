import { Button } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { getAPIPath } from '../core';

export const RunJobs = () => {
  const runHousekeepingJobs = async () => {
    try {
      const response = await fetch(
        getAPIPath() + `/api/runJobs/frequentHouseKeeping`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {},
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
        }
      );

      await response.json();
      alert('Success - housekeeping jobs run');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <Button
        onClick={runHousekeepingJobs}
        variant='outlined'
        startIcon={<Warning />}
        endIcon={<Warning />}
      >
        Run Housekeeping Jobs
      </Button>
    </div>
  );
};
