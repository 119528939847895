import { ValuationEditRequest } from '../server-types';
import { EditValuationForm } from '../state/openValuation';
import { mapToDropdown, mapToDate, mapToVesselFinder } from './form-elements';

export const convertDbValuationToEditValuationForm = (
  input: any
): EditValuationForm => {
  return {
    id: input.id,
    vesselDetails: mapToVesselFinder(input.vessel_name, input.imo),
    imo: input.imo,
    effectiveDate: mapToDate(input.effective_date),
    comments: input.comments,
    source: input.source,
    client: input.client,
    issuedTo: input.issued_to,
    priceLocal: {
      from: (input.price_local_from / 1000000).toString(),
      to: (input.price_local_to / 1000000).toString(),
    },
    priceUSD: {
      from: input.price_usd_from,
      to: input.price_usd_to,
    },
    currency: mapToDropdown(input.currency),
    updated: input.updated,
    updatedBy: input.updated_by,
  };
};

export const convertToValuationEditPayload = (
  input: EditValuationForm
): ValuationEditRequest => {
  const responseObj: ValuationEditRequest = {
    id: '',
    imo: '',
    effectiveDate: new Date(),
    priceLocal: {
      from: '',
      to: '',
    },
    priceUSD: {
      from: '',
      to: '',
    },
    currency: '',
    source: '',
    comments: '',
    client: '',
    issuedTo: '',
  };

  // Map inputs to the create request
  if (input.vesselDetails) {
    responseObj.imo = input.vesselDetails.value.imo;
  }

  if (input.id) responseObj.id = input.id;
  if (input.effectiveDate) responseObj.effectiveDate = input.effectiveDate;
  if (input.priceUSD) responseObj.priceUSD = input.priceUSD;
  if (input.priceLocal !== undefined) responseObj.priceLocal = input.priceLocal;

  responseObj.priceLocal = {
    from: !isNaN(Number(responseObj.priceLocal.from))
      ? (Number(responseObj.priceLocal.from) * 1000000).toFixed(0)
      : responseObj.priceLocal.from,
    to: !isNaN(Number(responseObj.priceLocal.to))
      ? (Number(responseObj.priceLocal.to) * 1000000).toFixed(0)
      : responseObj.priceLocal.to,
  };
  if (input.currency) responseObj.currency = input.currency.value;
  if (input.source) responseObj.source = input.source;
  if (input.comments) responseObj.comments = input.comments;
  if (input.client) responseObj.client = input.client;
  if (input.issuedTo) responseObj.issuedTo = input.issuedTo;

  return responseObj;
};
