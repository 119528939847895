import moment from 'moment';

export const FOSTableCurrentChartererCell = (props: any) => {
  const { currentCharterer, dateAvailable } = props;
  const date = moment(dateAvailable);
  const now = moment();

  if (!currentCharterer || date.isBefore(now, 'day')) return <div></div>;

  return <div>{currentCharterer}</div>;
};
