import FOSBSVesselFinder from '../../common/FOSBSVesselFinder';
import { ModalHeader } from '../ModalHeader';
import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  CreateAvailability,
  availabilityGlobalIdLookupState,
  createAvailabilityForm,
} from '../../state/createAvailability';
import { useEffect, useRef } from 'react';
import FOSBSTextAreaInput from '../../common/FOSBSTextAreaInput';
import FOSBSDatePicker from '../../common/FOSBSDatePicker';
import { regions } from '../../datas';
import FOSBSMultiSelect from '../../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../../common/FOSBSMultiSelectAsync';
import { toast } from 'react-toastify';
import {
  createAvailability,
  deleteAvailabilityById,
  getAvailabilityById,
  updateAvailability,
} from '../../services/fosService';
import { mapToDropdown } from '../../mapper/form-elements';

export const CreateAvailabilityModal = ({
  onRequestClose,
}: {
  onRequestClose: () => void;
}) => {
  const [form, setForm] = useRecoilState(createAvailabilityForm);
  const latestFormState = useRef(form);

  const [lookupGlobalId, setAvailabilityGlobalId] = useRecoilState(
    availabilityGlobalIdLookupState
  );

  console.log('lookupGlobalId', lookupGlobalId);

  const resetForm = useResetRecoilState(createAvailabilityForm);

  // On var update (ref updating)
  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current;
    const newForm = {
      ...latest,
      [field]: value,
    };

    setForm(newForm);
  };

  // On mount!
  useEffect(() => {
    const loadData = async () => {
      console.log('loading data', lookupGlobalId);
      const lookupResp = await getAvailabilityById(lookupGlobalId);

      const existingAvailability: CreateAvailability = {
        global_id: lookupResp.global_id,
        vesselDetails: mapToDropdown(lookupResp.imo),
        from_date: new Date(lookupResp.from_date),
        to_date: lookupResp.to_date ? new Date(lookupResp.to_date) : undefined,
        region: mapToDropdown(lookupResp.region),
        port: mapToDropdown(lookupResp.port),
        workScope: lookupResp.workscope,
        comment: lookupResp.comment,
      };

      setForm(existingAvailability);
    };

    if (lookupGlobalId) loadData();
  }, []);

  const doDelete = async () => {
    try {
      const r = window.confirm('Are you sure you want to delete Availability?');
      if (!r) return;

      await deleteAvailabilityById(lookupGlobalId);
      toast.success('Availability has been deleted');
      onClose();
    } catch (e: any) {
      console.log(e);
      toast.error(e.message);
    }
  };

  const doSubmit = async () => {
    let imo = latestFormState.current.vesselDetails?.value.imo;

    if (!imo) {
      imo = latestFormState.current.vesselDetails?.value;
    }
    const finalRequestBody = {
      global_id: lookupGlobalId,
      imo,
      from_date: latestFormState.current.from_date,
      to_date: latestFormState.current.to_date,
      region: latestFormState.current.region?.value,
      port: latestFormState.current.port?.value,
      workScope: latestFormState.current.workScope,
      comment: latestFormState.current.comment,
    };

    try {
      if (lookupGlobalId) {
        await updateAvailability(finalRequestBody);
        toast.success('Availability has been updated');
      } else {
        await createAvailability(finalRequestBody);
        toast.success('Availability has been created');
      }
      onClose();
    } catch (e: any) {
      console.log(e);
      toast.error(e.message);
    }
  };

  const onClose = () => {
    resetForm();
    setAvailabilityGlobalId('');
    onRequestClose();
  };

  return (
    <div>
      <ModalHeader onRequestClose={onClose} header={'Create Availability'} />
      <div className='row'>
        <div className='col-12 bg-light pt-2'>
          <div className='row'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Manual Availability Info
            </h5>
          </div>

          <div className='row pb-1 pt-2'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Vessel Name
            </label>
            <div className='col'>
              <FOSBSVesselFinder
                name={'vessel_name'}
                stateSetter={(name, value) => {
                  setFormStateElement('vesselDetails', value);
                }}
                value={form.vesselDetails}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row align-items-center pt-2'>
            <label className='col-sm-4 col-form-label'>Availability Date</label>
            <div className='col'>
              <FOSBSDatePicker
                placeholder={'From'}
                value={form.from_date}
                stateSetter={(name, value) => {
                  setFormStateElement('from_date', value);
                }}
                name={'builtFrom'}
              />
            </div>
            <div className='col'>
              <FOSBSDatePicker
                placeholder={'To'}
                value={form.to_date}
                stateSetter={(name, value) => {
                  setFormStateElement('to_date', value);
                }}
                name={'builtTo'}
              />
            </div>
          </div>

          <div className='row pb-1 pt-2'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Region
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='region'
                value={form?.region}
                options={regions}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1 pt-2'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Port Available
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='delivery_port'
                dbTableName='fixtures'
                name='port'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value?.[0]);
                }}
                value={form?.port}
                isCreatable={true}
              />
            </div>
          </div>

          <div className='row pb-1 pt-2'>
            <div className='col'>
              <FOSBSTextAreaInput
                label={'Workscope'}
                value={form.workScope}
                onChange={(e) => {
                  setFormStateElement('workScope', e.target.value);
                }}
              />
            </div>
          </div>

          <div className='row pb-2 pt-2'>
            <div className='col'>
              <FOSBSTextAreaInput
                label={'Comments'}
                value={form.comment}
                onChange={(e) => {
                  setFormStateElement('comment', e.target.value);
                }}
              />
            </div>
          </div>

          <div className='row py-3'>
            <div className='col-sm-12' style={{ textAlign: 'center' }}>
              <hr />
              <button
                type='button'
                className='btn btn-secondary btn'
                style={{ marginRight: 10 }}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </button>
              <button
                type='button'
                className='btn btn-success btn'
                disabled={!form.vesselDetails || !form.from_date}
                onClick={() => {
                  doSubmit();
                }}
              >
                {lookupGlobalId ? 'Update' : 'Create'}
              </button>

              {lookupGlobalId && (
                <button
                  type='button'
                  className='btn btn-danger btn'
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    doDelete();
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
