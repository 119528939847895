import { useSetRecoilState } from 'recoil';
import {
  modalEditorOpen,
  activeModalType,
  ModalType,
} from '../../state/modals';
import { imoLookupState } from '../../state/openVessel';

interface VesselLinkProps {
  imo: string | undefined;
  vesselName: string | undefined;
}

export const FOSTableVesselLinkCell = (props: VesselLinkProps) => {
  const { imo, vesselName } = props;

  // open modal and set lookup mode
  const setModalOpen = useSetRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);
  const setImo = useSetRecoilState(imoLookupState);

  if (!imo || !vesselName) return <div></div>;

  const openVesselModal = (imo: string) => {
    setImo(imo);
    setModalType(ModalType.OpenVessel);
    setModalOpen(true);
    console.log('opening as', imo);
  };

  return (
    <div>
      <a
        href='#'
        className='link-dark'
        onClick={() => {
          openVesselModal(imo);
        }}
      >
        {vesselName}
      </a>
    </div>
  );
};
