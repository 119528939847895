import { NumericFormat } from 'react-number-format';
export const FOSTableCurrencyCell = (props: any) => {
  const { value, currency = 'USD' } = props;

  if (!value || !Number(value)) return <div>RNR</div>;

  return (
    <div>
      {currency}
      <span style={{ paddingLeft: '5px' }}>
        <NumericFormat
          value={value}
          displayType={'text'}
          thousandSeparator={true}
          decimalScale={2}
        />
      </span>
    </div>
  );
};
