import moment from 'moment';
import Map, {
  Layer,
  LayerProps,
  MapboxEvent,
  Source,
  Popup,
  MapLayerMouseEvent,
} from 'react-map-gl';
import { FeatureCollection, Geometry } from 'geojson';

import 'mapbox-gl/dist/mapbox-gl.css';

import { accessToken } from './config';
import { useState } from 'react';

//@ts-ignore

const layerOpenSea: LayerProps = {
  id: 'open-sea',

  type: 'raster',
  paint: {
    'raster-opacity': 0.8,
  },
};
const mapboxStyledMap = 'mapbox://styles/larssbr/clujvww9400i701nt2x8p8lpu';
const vesselSolidMarker = 'vessel-solid';
const vesselMarkerLayerId = 'vessels-position-markers';

interface Vessel {
  coordinates: [number, number];
  vessel_name: string;
  speed: string;
  destination: string;
  eta: string;
  heading: string;
  vessel_type: string;
  signal_received: string;
  MoveStatus: string;
}

interface Props {
  height: string | number;
  width: string | number;
  zoom: [number];
  center?: [number, number];
  vessels: Vessel[];
}

const VesselInfoPopup = (vessel: Vessel) =>
  `<div>
    <h4
      style={{
        fontSize: '16px',
        padding: '5px 0 5px 0',
        borderBottom: '1px solid gray',
      }}
    >
      ${vessel.vessel_name}
    </h4>
    <div>
      Speed: ${vessel.speed}
      <br />
      Destination: ${vessel.destination}
      <br />
      ETA: ${moment(vessel.eta).format('DD.MM.yyyy HH:mm')}
      <br />
      Course: ${vessel.heading}
      <br />
      Ship type: ${vessel.vessel_type}
      <br />
      Last seen: ${moment(vessel.signal_received).format('DD.MM.yyyy HH:mm')}
      <br />
      Last known status: ${vessel.MoveStatus}
    </div>
  </div>`;

const yellowColorHex = '#ffff00';

const getGeoJSON = (vessels: Vessel[]): FeatureCollection<Geometry, any> => ({
  type: 'FeatureCollection',
  features: vessels.map((vessel: Vessel) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: vessel.coordinates,
    },
    properties: {
      description: VesselInfoPopup(vessel),
      icon: vesselSolidMarker,
      title: vessel.vessel_name,
      color: yellowColorHex,
      rotation: vessel.heading,
    },
  })),
});

const handleOnLoad = (e: MapboxEvent) => {
  const map = e.target;
  if (!map.hasImage(vesselSolidMarker)) {
    map.loadImage('/img/map/vessel-solid.png', (error, image) => {
      if (error) throw error;
      if (!map.hasImage(vesselSolidMarker) && image) {
        map.addImage(vesselSolidMarker, image, { sdf: true });
      }
    });
  }
};

export const VesselsPositionMap = ({
  width,
  height,
  center,
  vessels,
}: Props) => {
  const [popupInfo, setPopupInfo] = useState<{
    longitude: number;
    latitude: number;
    description: string;
  } | null>(null);

  const vesselGeoJson = getGeoJSON(vessels);

  function handleMouseEnter(e: MapLayerMouseEvent) {
    const map = e.target;
    if (e.features?.length && e.features?.length > 0) {
      map.getCanvas().style.cursor = 'pointer';
      const feature = e.features[0];
      if (feature.geometry.type === 'Point') {
        const coordinates = feature.geometry.coordinates as [number, number];
        setPopupInfo({
          longitude: coordinates[0],
          latitude: coordinates[1],
          description: feature.properties?.description,
        });
      }
    }
  }

  function handleMouseLeave(e: MapLayerMouseEvent) {
    const map = e.target;
    map.getCanvas().style.cursor = '';
    setPopupInfo(null);
  }

  return (
    <Map
      mapboxAccessToken={accessToken}
      initialViewState={{
        longitude: center?.[0] ?? 10.757933,
        latitude: center?.[1] ?? 59.911491,
        zoom: 5,
      }}
      style={{ width, height }}
      mapStyle={mapboxStyledMap}
      onLoad={handleOnLoad}
      onMouseMove={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      projection={{ name: 'mercator' }}
      interactiveLayerIds={[vesselMarkerLayerId]}
    >
      <Source
        type='raster'
        tiles={['https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png']}
        tileSize={256}
        minzoom={0}
        maxzoom={22}
      >
        <Layer beforeId='waterway-label' {...layerOpenSea} />
      </Source>

      <Source type='geojson' data={vesselGeoJson}>
        <Layer
          id={vesselMarkerLayerId}
          type='symbol'
          paint={{
            'icon-color': ['get', 'color'],
            'text-color': '#000000',
            'text-halo-color': '#ffffff',
            'text-halo-width': 5,
          }}
          layout={{
            'text-field': ['get', 'title'],
            'text-offset': [0, -1.25],
            'text-size': 12,
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-optional': true,
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-rotate': ['get', 'rotation'],
            'icon-image': ['get', 'icon'],
            'icon-size': 1,
          }}
        />
      </Source>
      {popupInfo && (
        <Popup
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeButton={false}
          closeOnClick={false}
          anchor='bottom'
        >
          <div dangerouslySetInnerHTML={{ __html: popupInfo.description }} />
        </Popup>
      )}
    </Map>
  );
};
