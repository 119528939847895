import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { activeModalType, ModalType } from '../../state/modals';
import { fixtureGlobalIdLookupState } from '../../state/openFixture';
import { vesselLookupState, EditVesselForm } from '../../state/openVessel';
import { FOSTable } from '../../common/table/FOSTable';

import { FOSTableTooltipCell } from '../../common/table/FOSTableTooltipCell';
import { FOSTableYesNoCell } from '../../common/table/FOSTableYesNoCell';
import { getAvailabiliesByImo } from '../../services/fosService';
import { availabilityGlobalIdLookupState } from '../../state/createAvailability';

interface Props {
  setFormStateElement(field: string, value: any): void;
  form: EditVesselForm | undefined;
  type?: string;
}

export const ManualAvailability = (props: Props) => {
  const { form } = props;

  const setModalType = useSetRecoilState(activeModalType);
  const setAvailabilityGlobalId = useSetRecoilState(
    availabilityGlobalIdLookupState
  );
  const dbVessel = useRecoilValue(vesselLookupState);

  const [data, setData] = useState<any>([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState({ id: 'created_at', desc: true });

  const openAvailability = (id: string) => {
    setAvailabilityGlobalId(id);
    setModalType(ModalType.createAvailability);
  };

  // On mount!
  useEffect(() => {
    if (form === undefined || dbVessel === undefined) return;
    getAvailabiliesByImo(dbVessel.imo, page, perPage, sortBy).then(
      (res) => {
        setData(res.data);
        setTotalPages(res.pagination.lastPage);
      },
      (reason) => {
        console.log('ERROR');
      }
    );
  }, [form, page, perPage, sortBy]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Vessel Name',
        accessor: 'vessel_name',
      },
      {
        Header: 'IMO',
        accessor: 'imo',
      },
      {
        Header: 'Available From',
        accessor: 'from_date',
      },
      {
        Header: 'Available To',
        accessor: 'to_date',
      },

      {
        Header: 'Region',
        accessor: 'region',
      },
      {
        Header: 'Port',
        accessor: 'port',
      },
      {
        Header: 'Workscope',
        accessor: 'workscope',
      },
      {
        Header: 'Comments',
        accessor: 'comment',
      },

      {
        Header: 'Updated By',
        accessor: 'updated_by',
      },
      {
        Header: 'Edit',
        Cell: (original: any) => {
          return (
            <button
              className='btn btn-sm btn-dark'
              onClick={(e) => {
                openAvailability(original.row.original.global_id);
                console.log('opening', original.row.original.global_id);
              }}
            >
              Edit
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <FOSTable
      columns={columns}
      data={data}
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={page}
      perPage={perPage}
      totalPage={totalPages}
      initialSortBy={sortBy}
      onChangeSort={setSortBy}
    ></FOSTable>
  );
};
