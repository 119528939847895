export const brokerOptions = [
  { label: 'Uno', value: 'Uno' },
  { label: 'TBN', value: 'TBN' },
  { label: 'Direct', value: 'Direct' },
  { label: 'Westshore', value: 'Westshore' },
  { label: 'Seabrokers', value: 'Seabrokers' },
  { label: 'Fearnleys', value: 'Fearnleys' },
  { label: 'Pareto', value: 'Pareto' },
  { label: 'Clarksons Platou', value: 'Clarksons Platou' },
  { label: 'Braemar', value: 'Braemar' },
  { label: 'Hagland', value: 'Hagland' },
];
