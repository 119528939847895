import '../main.css';
import { MainThemeNavBar } from './MainThemeNavBar';

export const MainThemeBootstrap = (props: any) => {
  return (
    <div>
      <div className='container-fluid kingkong'>
        <div className='py-2 pt-3'>
          <div className='row'>
            <div className='col-12 mx-auto'>
              <div className='d-flex bd-highlight mb-0'>
                <div className='bd-highlight'>
                  <a href={`${props.isHome ? '/' : '/dashboard'}`}>
                    <img src='/img/fos_logo.svg' alt='logo' height='22' />
                  </a>
                </div>
                <div className='ms-auto bd-highlight d-none d-sm-block'></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MainThemeNavBar isAdmin={props.isAdmin} isHome={props.isHome} />

      <div className='container-fluid'>{props.children}</div>
      <div className='container-fluid'></div>

      <div className='mt-5 pt-5 pb-5 footer '>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5 col-xs-12 about-company'>
              <h4 className='fw-light'>Fearnley Offshore Supply</h4>
              <p className='pr-5 text-white-50'>
                Servicing the shipping industry since 1869
              </p>
            </div>
            <div className='col-lg-3 col-xs-12 links'>
              <h4 className='mt-lg-0 mt-sm-3 fw-light'>Links</h4>
              <ul className='m-0 p-0'>
                <li>
                  -{' '}
                  <a href='https://fearnleyoffshoresupply.com/story/'>About</a>
                </li>
                <li>
                  -{' '}
                  <a href='https://fearnleyoffshoresupply.com/services/'>
                    Our Business Areas
                  </a>
                </li>
                <li>
                  -{' '}
                  <a href='https://fearnleyoffshoresupply.com/contact-oslo-office/'>
                    Contact
                  </a>
                </li>
                <li>
                  - <a href='https://astrupfearnley.com/'>Astrup Fearnley</a>
                </li>
              </ul>
            </div>
            <div className='col-lg-4 col-xs-12 location'>
              <h4 className='mt-lg-0 mt-sm-4 fw-light'>Location</h4>
              <p className='mb-0'>Dronning Eufemias Gate 8</p>
              <p className='mb-0'>Oslo | Norway</p>
              <p className='mb-0'>
                <i className='fa fa-phone mr-3'></i> +47 22 93 60 00
              </p>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='col copyright'>
              <p className=''>
                <small className='text-white-50'>
                  © 2023. All Rights Reserved.
                </small>
              </p>

              <img src='/img/af-1.svg' alt='afcode' height='30' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
