import { useHistory } from 'react-router';
import { NavLink, Link, useLocation } from 'react-router-dom';
import moment from 'moment';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { activeModalType, modalEditorOpen, ModalType } from '../state/modals';
import { isNewFixture } from '../state/createFixture';
import { loginState, userState } from '../state/state';
import { getAPIPath } from '../core';
import { Regions, OSVRegions } from '../common/types';
import { getSpotRatesExcel } from '../services/fosService';
import { exportNorthSeaAvailabilitiesPDF } from '../services/exportService';

const menuStyle: React.CSSProperties = {
  color: '#FFF',
  textDecoration: 'underline',
  textDecorationColor: '#6187AC',
  textDecorationThickness: '.165em',
  textUnderlineOffset: '3.5px',
};

const NavbarDropdonwMenuLink = ({ path, name }: any) => {
  const location = useLocation();

  const active = location.pathname === path;
  return (
    <a
      style={menuStyle}
      id='navbarDarkDropdownMenuLink'
      className={`nav-link dropdown-toggle ${
        active ? 'navbar-menuitem-active' : ''
      }`}
      role='button'
      data-bs-toggle='dropdown'
      aria-expanded='false'
    >
      {name}
    </a>
  );
};

const AdminNavBar = () => (
  <ul className='navbar-nav me-auto ps-2'>
    <li className='nav-item dropdown'>
      <Link
        to='/admin/fieldcleaner'
        className='nav-item nav-link'
        style={menuStyle}
        aria-current='page'
      >
        Clean similar valued fields
      </Link>
    </li>

    <li className='nav-item dropdown'>
      <Link
        to='/admin/useradmin'
        className='nav-item nav-link'
        style={menuStyle}
        aria-current='page'
      >
        User administration
      </Link>
    </li>
  </ul>
);

const HomeNavBar = () => (
  <ul className='navbar-nav me-auto ps-2'>
    <li className='nav-item dropdown'>
      <NavbarDropdonwMenuLink
        path='/'
        name='North Sea Spot'
      ></NavbarDropdonwMenuLink>
    </li>
    <li className='nav-item dropdown'>
      <Link
        to={{ pathname: '/dashboard' }}
        className='nav-item nav-link'
        style={menuStyle}
        aria-current='page'
      >
        Supply Vessel Worldwide
      </Link>
    </li>
    <li className='nav-item dropdown'>
      <Link
        to={{ pathname: '/subsea', search: `region=All` }}
        className='nav-item nav-link'
        style={menuStyle}
        aria-current='page'
      >
        Subsea
      </Link>
    </li>
  </ul>
);

const UserNavBar = () => {
  const setModalOpen = useSetRecoilState(modalEditorOpen);
  const setModalType = useSetRecoilState(activeModalType);
  const setIsNewFixture = useSetRecoilState(isNewFixture);

  const openModal = (modal: ModalType, isNewFixture?: boolean) => {
    setModalType(modal);
    setModalOpen(true);

    if (isNewFixture) {
      setIsNewFixture(isNewFixture);
    }
  };

  return (
    <ul className='navbar-nav me-auto ps-2'>
      <li className='nav-item dropdown'>
        <NavbarDropdonwMenuLink path='/osv' name='OSV'></NavbarDropdonwMenuLink>
        <ul
          className='dropdown-menu dropdown-menu-dark'
          aria-labelledby='navbarDarkDropdownMenuLink'
        >
          {Object.keys(OSVRegions).map((key, idx) => {
            return (
              <li key={key}>
                <Link
                  to={{
                    pathname: '/osv',
                    search: `?region=${key}`,
                  }}
                  className='dropdown-item'
                  aria-current='page'
                >
                  {(OSVRegions as any)[key]}
                </Link>
              </li>
            );
          })}
        </ul>
      </li>

      <li className='nav-item dropdown'>
        <NavbarDropdonwMenuLink
          path='/subsea'
          name='Subsea'
        ></NavbarDropdonwMenuLink>
        <ul
          className='dropdown-menu dropdown-menu-dark'
          aria-labelledby='navbarDarkDropdownMenuLink'
        >
          {Object.keys(Regions).map((key, idx) => {
            return (
              <li key={key}>
                <Link
                  to={{
                    pathname: '/subsea',
                    search: `?region=${key}`,
                  }}
                  className='dropdown-item'
                  aria-current='page'
                >
                  {(Regions as any)[key]}
                </Link>
              </li>
            );
          })}
        </ul>
      </li>

      <li className='nav-item dropdown'>
        <NavbarDropdonwMenuLink path='/snp' name='SNP'></NavbarDropdonwMenuLink>
        <ul
          className='dropdown-menu dropdown-menu-dark'
          aria-labelledby='navbarDarkDropdownMenuLink'
        >
          <li>
            <Link to='/snp' className='dropdown-item' aria-current='page'>
              View Sales
            </Link>
          </li>
          <li>
            <a
              className='dropdown-item'
              href='#recentsale'
              onClick={() => {
                openModal(ModalType.CreateRecentSale);
              }}
            >
              Register Sale
            </a>
          </li>
          <li>
            <a
              className='dropdown-item'
              href='#salescandidate'
              onClick={() => {
                openModal(ModalType.CreateSalesCandidate);
              }}
            >
              Register Sales Candidate
            </a>
          </li>
        </ul>
      </li>

      <li className='nav-item dropdown'>
        <NavbarDropdonwMenuLink
          path='/valuation'
          name='Valuation'
        ></NavbarDropdonwMenuLink>
        <ul
          className='dropdown-menu dropdown-menu-dark'
          aria-labelledby='navbarDarkDropdownMenuLink'
        >
          <Link
            to={{
              pathname: '/valuation',
            }}
            className='dropdown-item'
            aria-current='page'
          >
            View Valuations
          </Link>
          <li>
            <a
              className='dropdown-item'
              href='#valuation'
              onClick={() => {
                openModal(ModalType.CreateValuation);
              }}
            >
              New Valuation
            </a>
          </li>
          <Link
            to={{
              pathname: '/valuation/bulk-upload',
            }}
            className='dropdown-item'
            aria-current='page'
          >
            Bulk Upload Valuation
          </Link>
        </ul>
      </li>

      <li className='nav-item'>
        <NavLink
          to='/new-building?country=All'
          activeClassName='navbar-menuitem-active'
          className='nav-link'
          aria-current='page'
          style={menuStyle}
        >
          New Building
        </NavLink>
      </li>

      <li className='nav-item'>
        <NavLink
          to='/north-sea-spot'
          activeClassName='navbar-menuitem-active'
          className='nav-link'
          aria-current='page'
          style={menuStyle}
        >
          North Sea
        </NavLink>
      </li>

      <li className='nav-item dropdown'>
        <a
          className='nav-link dropdown-toggle'
          href='#'
          style={menuStyle}
          id='navbarDarkDropdownMenuLink2'
          role='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        >
          Create
        </a>
        <ul
          className='dropdown-menu dropdown-menu-dark'
          aria-labelledby='navbarDarkDropdownMenuLink2'
        >
          <li>
            <a
              className='dropdown-item'
              href='#vessel'
              onClick={() => {
                openModal(ModalType.CreateVessel);
              }}
            >
              Vessel
            </a>
          </li>
          <li>
            <a
              className='dropdown-item'
              href='#valuation'
              onClick={() => {
                openModal(ModalType.CreateValuation);
              }}
            >
              Valuation
            </a>
          </li>
          <li>
            <a
              className='dropdown-item'
              href='#fixture'
              onClick={() => {
                openModal(ModalType.CreateFixture, true);
              }}
            >
              Fixture
            </a>
          </li>

          <li>
            <a
              className='dropdown-item'
              href='#availability'
              onClick={() => {
                openModal(ModalType.createAvailability, true);
              }}
            >
              Availability
            </a>
          </li>

          <li>
            <a
              className='dropdown-item'
              href='#fixture'
              onClick={() => {
                openModal(ModalType.CreateRequirement, true);
              }}
            >
              Requirement
            </a>
          </li>
          <li>
            <a
              className='dropdown-item'
              href='#fixture'
              onClick={() => {
                openModal(ModalType.CreateBudgetIndication, true);
              }}
            >
              Budget Indication
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

const NavBar = (props: any) => {
  if (props.isAdmin) return <AdminNavBar />;
  if (props.isHome) return <HomeNavBar />;
  return <UserNavBar />;
};

export const MainThemeNavBar = (props: any) => {
  const history = useHistory();

  const user = useRecoilValue(userState);
  const [loginStateVal, setLoginStateVal] = useRecoilState(loginState);

  const logout = async () => {
    const response = await fetch(getAPIPath() + '/logout', {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      credentials: 'include', // include, *same-origin, omit
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });

    console.log('bye!');
    setLoginStateVal(false);
    history.push('/login');
  };

  const openSpotRates = () => {
    const year = Number(
      prompt('Please enter wanted spot rate year: ', moment().year().toString())
    );

    if (!!year) getSpotRatesExcel(year);
  };

  return (
    <nav
      className='navbar navbar-expand-lg navbar-dark mt-0'
      style={{
        backgroundImage: 'linear-gradient(to bottom, #142E3D, #142E3D)',
      }}
    >
      <div className='container-fluid'>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <NavBar isHome={props.isHome} isAdmin={props.isAdmin} />

          <form className='d-flex'>
            {!loginStateVal ? (
              <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                {/* <li className="nav-item">
                      <a href="#" className="nav-item nav-link" style={menuStyle}> <span> Unit Converter</span></a>
                    </li> */}
                <li className='nav-item'>
                  <Link
                    to='/login'
                    className='nav-item nav-link'
                    style={menuStyle}
                    aria-current='page'
                  >
                    Login
                  </Link>
                </li>
                <li className='nav-item'>
                  <a
                    href='https://fearnleyoffshoresupply.com/story/'
                    className='nav-item nav-link'
                    style={menuStyle}
                  >
                    <span>About</span>
                  </a>
                </li>
              </ul>
            ) : (
              <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                {/* <li className="nav-item">
             <a href="#" className="nav-item nav-link" style={menuStyle}> <span> Unit Converter</span></a>
           </li> */}
                <li className='nav-item'>
                  <Link
                    to='/admin'
                    className='nav-item nav-link'
                    style={menuStyle}
                    aria-current='page'
                  >
                    Admin
                  </Link>
                </li>
                <li className='nav-item'>
                  <a href='#' className='nav-item nav-link' style={menuStyle}>
                    <span> Portal admin</span>
                  </a>
                </li>
                <li className='nav-item dropdown'>
                  <a
                    className='nav-link dropdown-toggle '
                    style={menuStyle}
                    id='navbarDarkDropdownMenuLink'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    User {user.userId}
                  </a>
                  <ul
                    className='dropdown-menu dropdown-menu-dark'
                    aria-labelledby='navbarDarkDropdownMenuLink'
                  >
                    <li>
                      <a className='dropdown-item' href='#'>
                        Outdated vessels
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item'
                        href='#'
                        onClick={openSpotRates}
                      >
                        Spot rates
                      </a>
                    </li>
                    <li>
                      <a className='dropdown-item' href='#'>
                        Valuation
                      </a>
                    </li>
                    <li>
                      <a
                        className='dropdown-item'
                        href='#'
                        onClick={exportNorthSeaAvailabilitiesPDF}
                      >
                        Export Availabilities
                      </a>
                    </li>
                  </ul>
                </li>

                <li className='nav-item'>
                  <a
                    href='#'
                    className='nav-item nav-link'
                    style={menuStyle}
                    onClick={logout}
                  >
                    {' '}
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            )}
          </form>
        </div>
        <div className='ms-auto  bd-highlight'></div>
      </div>
    </nav>
  );
};
