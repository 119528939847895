import { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { currencies } from '../datas';
import {
  saleGlobalId,
  snpSaleState,
  snpSaleEditFormState,
  EditSnPSaleForm,
} from '../state/openSale';
import FOSBSBoolean from '../common/FOSBSBoolean';
import FOSBSDatePicker from '../common/FOSBSDatePicker';
import FOSBSMultiSelect from '../common/FOSBSMultiSelect';
import FOSBSMultiSelectAsync from '../common/FOSBSMultiSelectAsync';
import FOSBSTextAreaInput from '../common/FOSBSTextAreaInput';
import FOSBSTextInput from '../common/FOSBSTextInput';
import FOSBSPriceInput from '../common/FOSBSPriceInput';
import FOSBSVesselFinder from '../common/FOSBSVesselFinder';
import { MultiSelectOptions } from '../common/form-elements';
import {
  convertDbSaleToEditSaleForm,
  convertToSaleEditPayload,
} from '../mapper/sale';
import { toast } from 'react-toastify';
import { modalEditorOpen } from '../state/modals';
import { shallowFormDiff } from '../util';
import { getRecentSale, updateSale, deleteSale } from '../services/fosService';

const saleTypes = [
  { label: 'Reported sold', value: 'Reported sold' },
  { label: 'Sold', value: 'Sold' },
  { label: 'Failed sale', value: 'Failed sale' },
  { label: 'Sold for Demolition', value: 'Sold for Demolition' },
  { label: 'Demolition', value: 'Demolition' },
  { label: 'Offers Invited', value: 'Offers Invited' },
  { label: 'Requested Price', value: 'Requested Price' },
  { label: 'Contract Price', value: 'Contract Price' },
  { label: 'NewBuilding', value: 'NewBuilding' },
];

interface Props {
  onRequestClose(): void;
}

const generateSaleEditState = (oldSale: any, newSale: any) => {
  if (oldSale === undefined || newSale === undefined) {
    return null;
  }

  const oldPayload = convertToSaleEditPayload(oldSale);
  const newPayload = convertToSaleEditPayload(newSale);

  const stateChange = shallowFormDiff(oldPayload, newPayload);

  return stateChange;
};

export const EditSalesCandidateForm = (props: Props) => {
  const [currenciesMapped, setCurrenciesMapped] = useState<MultiSelectOptions>(
    []
  );
  const [saleId, setSaleGlobalId] = useRecoilState(saleGlobalId);

  const setModalOpen = useSetRecoilState(modalEditorOpen);

  const [sale, setSale] = useRecoilState(snpSaleState);
  const [form, setForm] = useRecoilState(snpSaleEditFormState);
  const latestFormState = useRef(form);

  const resetSale = useResetRecoilState(snpSaleState);
  const resetForm = useResetRecoilState(snpSaleEditFormState);

  let stateChange = generateSaleEditState(sale, form);
  const untouched = stateChange === undefined || stateChange === null;

  useEffect(() => {
    latestFormState.current = form;
  }, [form]);

  useEffect(() => {
    const loadData = async () => {
      const lookupResp = await getRecentSale(saleId);
      const sale = convertDbSaleToEditSaleForm(lookupResp[0]);

      setForm(sale);
      setSale(sale);

      // map regions
      setCurrenciesMapped(
        currencies.map((el) => {
          return {
            label: el,
            value: el,
          };
        })
      );
    };

    loadData();
  }, [saleId]);

  const setFormStateElement = async (field: string, value: any) => {
    const latest = latestFormState.current as EditSnPSaleForm;
    const newForm = {
      ...latest,
      [field]: value,
    };
    setForm(newForm);
    latestFormState.current = newForm;
  };

  const doUpdate = async () => {
    stateChange = generateSaleEditState(sale, latestFormState.current);
    if (stateChange === undefined) return;

    await updateSale(saleId, stateChange);

    toast.success('Sale has been updated');
    resetForm();
    resetSale();
    setModalOpen(false);
  };

  const doDelete = async () => {
    const r = window.confirm('Are you sure you want to delete recent sale?');
    if (!r) return;

    const response = await deleteSale(saleId);

    if (response.status) {
      toast.success('Sale has been deleted');
      resetForm();
      resetSale();
      setModalOpen(false);
    } else {
      toast.error(response.message);
    }
  };

  if (form === undefined) return <div></div>;

  return (
    <>
      <div className='row'>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              General Info
            </h5>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Connected Imo
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {form.imo}
              </label>
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Vessel Name
            </label>
            <div className='col'>
              <FOSBSVesselFinder
                name={'vessel_name'}
                stateSetter={(name, value) => {
                  setFormStateElement('vesselDetails', value);
                }}
                value={form.vesselDetails}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSDatePicker
            label={'Effective Date'}
            value={form.effectiveDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'effectiveDate'}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Owner
            </label>
            <div className='col'>
              <FOSBSMultiSelectAsync
                dbFieldName='owner'
                name='owner'
                stateSetter={(name: string, value: string) => {
                  setFormStateElement('owner', value);
                }}
                value={form.owner}
                isCreatable={true}
                isMulti={false}
              />
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Type
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='salesType'
                value={form.salesType}
                options={saleTypes}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <FOSBSPriceInput
            label={'Asking Price amount'}
            value={form.askingPrice}
            onChange={(val) => {
              setFormStateElement('askingPrice', val);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Currency
            </label>
            <div className='col'>
              <FOSBSMultiSelect
                name='askingPriceCurrency'
                value={form.askingPriceCurrency}
                options={currenciesMapped}
                stateSetter={(name: string, value: string) => {
                  setFormStateElement(name, value);
                }}
                isMulti={false}
              />
            </div>
          </div>

          <div style={{ paddingTop: '1rem' }}>
            <FOSBSTextAreaInput
              label={'Comments'}
              value={form.comments}
              onChange={(e) => {
                setFormStateElement('comments', e.target.value);
              }}
            />
          </div>
        </div>
        <div className='col-sm-12 col-md-6 col-lg-6 bg-light pt-2'>
          <div className='col-md-12'>
            <h5 className='fw-light pt-3 border-bottom border-5 border-secondary pb-1'>
              Details
            </h5>
          </div>

          <FOSBSTextInput
            label={'Source'}
            value={form.source}
            onChange={(e) => {
              setFormStateElement('source', e.target.value);
            }}
          />

          <FOSBSDatePicker
            label={'Delivery Date'}
            value={form.deliveryDate}
            stateSetter={(name, value) => {
              setFormStateElement(name, value);
            }}
            name={'deliveryDate'}
          />

          <FOSBSBoolean
            label={'P&C'}
            name={'privateAndConfidential'}
            value={form.privateAndConfidential}
            stateSetter={(name: string, value: string) => {
              setFormStateElement(name, value);
            }}
          />

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Updated
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {moment(form.updated).format('DD.MM.yyyy HH:mm:ss')}
              </label>
            </div>
          </div>

          <div className='row pb-1'>
            <label htmlFor='inputEmail3' className='col-sm-4 col-form-label'>
              Updated by
            </label>
            <div className='col'>
              <label htmlFor='inputEmail3' className='col-form-label'>
                {form.updatedBy}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-secondary btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              resetForm();
              props.onRequestClose();
            }}
          >
            Cancel
          </button>

          <button
            type='button'
            className='btn btn-success btn'
            disabled={untouched}
            onClick={() => {
              doUpdate();
            }}
          >
            Update
          </button>
        </div>
        <div className='col-sm-6' style={{ textAlign: 'right' }}>
          <hr />
          <button
            type='button'
            className='btn btn-danger btn'
            style={{ marginRight: 10 }}
            onClick={() => {
              doDelete();
            }}
          >
            Delete candidate
          </button>
        </div>
      </div>
    </>
  );
};
