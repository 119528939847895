import React, { useState } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import { ZodSchema, ZodError } from 'zod';

interface RowObject {
  [key: string]: string;
}

function excelToJson(tsvText: string): RowObject[] {
  const allTextLines: string[] = tsvText.split(/\r\n|\n/);

  // Detect delimiter (assumes the same delimiter is used throughout the file)
  const delimiter = /\t/.test(allTextLines[0]) ? /\t/ : /,/;

  const headers: string[] = allTextLines[0].split(delimiter);
  const lines: RowObject[] = [];

  for (let i = 1; i < allTextLines.length; i++) {
    // Remove any formatting commas within numbers for correct parsing
    const data: string[] = allTextLines[i]
      .split(delimiter)
      .map((field) => field.replace(/(\d),(\d)/g, '$1$2'));

    if (data.length === headers.length) {
      const row: RowObject = {};
      headers.forEach((header, j) => {
        row[header] = data[j];
      });
      lines.push(row);
    }
  }
  return lines;
}

interface TextToJsonProps {
  validationSchema: ZodSchema<any>;
  onChange: (json: any) => void;
  placeholder?: string;
}

export const TextToJson: React.FC<TextToJsonProps> = ({
  validationSchema,
  onChange,
  placeholder,
}) => {
  const [inputData, setInputData] = useState(
    'imo	client	vessel_name	effective_date	currency	price_local_from	price_local_to	source	comments \n'
  );
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (text: string) => {
    setInputData(text);

    const jsonResult = excelToJson(text);

    try {
      const parsedData = validationSchema.parse(jsonResult);
      console.log(parsedData);
      onChange(parsedData);
      setError(null);
    } catch (error) {
      if (error instanceof ZodError) {
        const errorMessages = error.errors
          .map((err) => {
            const line = (err.path[0] as number) + 2; // +2 because line index starts at 0 and headers are on the first line
            const fieldName = err.path[1] ? `${err.path[1]}: ` : ''; // Include field name if available
            return `Line ${line} ${fieldName}${err.message}`;
          })
          .join('\n');
        setError(errorMessages);
      }
    }
  };

  return (
    <div>
      <TextareaAutosize
        style={{ width: '100%' }}
        value={inputData}
        onChange={(e) => handleInputChange(e.target.value)}
        minRows={15}
        placeholder={placeholder}
      />
      {error && (
        <div
          style={{ color: 'red', marginTop: '10px', whiteSpace: 'pre-wrap' }}
        >
          {error}
        </div>
      )}
    </div>
  );
};
