import { atom, RecoilState, RecoilValueReadOnly, selector } from 'recoil';

export const loginState = atom({
  key: 'loginState',
  default: false,
});

export const justLoggedInState = atom({
  key: 'justLoggedInState',
  default: false,
});

interface LoggedInUser {
  userId: string;
  isActiveBroker: boolean;
  isAdmin: boolean;
}

export const userState = atom<LoggedInUser>({
  key: 'userState',
  default: {
    userId: 'n/a',
    isActiveBroker: false,
    isAdmin: false,
  },
});
