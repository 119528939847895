export const siteName = 'FOSLive';

export const AssetStorageUrl =
  'https://foslive.blob.core.windows.net/vessel-assets';

export const isProd = () => {
  console.log(process.env.NODE_ENV);
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  return !isDev;
};

export const getAPIPath = () => {
  return process.env.REACT_APP_API_URL;
};

export const getAssetsPath = () => {
  return process.env.REACT_APP_ASSET_STORAGE_VARIABLE;
};
